import { Menu, MenuItem, MenuItemList } from "@menufy/models";
import { cloneDeep } from "lodash";
import { MenuPreferencesFilters } from "./interfaces/prefs-filters";
import { applyDishPreferencesFilters, filterPreferenceMatchedItems } from "./dish-prefs-filters";

const setSelectedAllergensAndDietsOnDishes = (
  menusItems: MenuItemList,
  filters: MenuPreferencesFilters
) => {
  menusItems.forEach((item, idx) => {
    if (item.type === MenuItem.DISH) {
      // set allergen or diet labels
      menusItems[idx] = applyDishPreferencesFilters(item, filters, {
        filterOptionsItems: false,
      });
    } else {
      const menu = item as Menu;
      setSelectedAllergensAndDietsOnDishes(menu.items, filters);
    }
  });
};

const filterMatchedAllergensAndDietsDishes = (
  menusItems: MenuItemList,
  filters: MenuPreferencesFilters,
  filteredMenuItems: MenuItemList = []
): MenuItemList => {
  menusItems.forEach((item) => {
    if (item.type === MenuItem.DISH) {
      filterPreferenceMatchedItems(item, filters, filteredMenuItems);
    } else if (item.type === MenuItem.MENU) {
      const menu = item as Menu;

      const subMenuFilteredItems: MenuItemList = [];

      menu.items = filterMatchedAllergensAndDietsDishes(menu.items, filters, subMenuFilteredItems);

      filteredMenuItems.push(menu);
    }
  });

  return filteredMenuItems;
};

export function applyMenuPreferencesFilters(
  menus: Menu[],
  filters: MenuPreferencesFilters
): Menu[] {
  const { isFilterOn } = filters;

  const clonedMenus = cloneDeep(menus);

  // set allergen or diet labels on dishes
  setSelectedAllergensAndDietsOnDishes(clonedMenus, filters);

  // if allergens or diet is selected with filter on
  if (isFilterOn) {
    // then filter dishes with matching labels
    return filterMatchedAllergensAndDietsDishes(clonedMenus, filters) as Menu[];
  }

  return clonedMenus;
}
