import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "nuts-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a48990e8-1c5a-423c-b2d7-487535979d54"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84Z"
      transform="translate(-3.41 -16.46)"
      fill="#7f6a5b"
    />
    <path
      d="M72.39,51c5.14-2.31,5.09-5.38,4.81-6.88-.74-4.07-4.88-6.41-9.38-4-5.89,3.09-9.91-.88-7.1-4.81-3.95,2.78-7.91-1.22-4.82-7.1,2.38-4.5,0-8.65-4-9.4-1.49-.26-4.57-.32-6.89,4.82-1,2.26-4-3.71-7.71-1.1-2.42,1.7-1.38,4.84-2.51,5.89-.94.88-4.89-2.19-8.45,1.37-2.71,2.69-1.18,6.63-1.91,7.8s-3.54,2-3.9,4.3c-.54,3.6,1.5,5.83.83,6.81-1.95,2.67-5.71,10.5,2.3,12.79,6.57,1.88,8.48-4.22,11.82-.89s-2.77,5.25-.89,11.82c2.3,8,10.11,4.24,12.8,2.31,1-.7,3.2,1.36,6.79.81,2.3-.36,3.13-3.18,4.31-3.89s5.1.78,7.8-1.91c3.56-3.59.49-7.53,1.37-8.46,1-1.12,4.2-.1,5.89-2.51C76.12,55.05,70.13,52,72.4,51Zm-36.54.5a5.25,5.25,0,0,1-1.58-1.1,5.59,5.59,0,0,1,1.41-8.66,6.64,6.64,0,0,1,2.17-6.87,5.83,5.83,0,0,1,3.69-1.39,5.17,5.17,0,0,1,3.85,1.75A2,2,0,0,1,45.31,38a2,2,0,0,1-1.37.54A2,2,0,0,1,42.5,38a1.4,1.4,0,0,0-1-.45,1.92,1.92,0,0,0-1.15.46c-1,.81-1.42,2.43-.12,4.24a2,2,0,0,1,0,2.29,2,2,0,0,1-1.65.84,1.81,1.81,0,0,1-.52-.07,1.11,1.11,0,0,0-.28,0c-.51,0-.8.6-.9.86a1.36,1.36,0,0,0,.62,1.81,2,2,0,0,1-1.65,3.62Zm3.42,7.18a2,2,0,0,1-2-2,2,2,0,0,1,.58-1.4L52.94,40.24A2,2,0,1,1,55.75,43L40.68,58.11a2,2,0,0,1-1.4.58Zm21.82-.56A6.51,6.51,0,0,1,56,60.55a7,7,0,0,1-1.8-.24,5.24,5.24,0,0,1-3,2.52,5.83,5.83,0,0,1-2,.36,5.18,5.18,0,0,1-3.69-1.48,6.17,6.17,0,0,1-1.08-1.56,2,2,0,0,1,3.61-1.67v0a1.12,1.12,0,0,0,1.12.72,2.14,2.14,0,0,0,.7-.12c.18-.06,1-.4.81-1.17a1.94,1.94,0,0,1,.77-2.17,1.87,1.87,0,0,1,1.14-.37,1.94,1.94,0,0,1,1.16.38,3.85,3.85,0,0,0,2.21.79,2.56,2.56,0,0,0,2-.94,1.48,1.48,0,0,0,0-2.13A1.93,1.93,0,0,1,58,50.69a1.93,1.93,0,0,1,1.43-.62,2,2,0,0,1,1.35.53,5.38,5.38,0,0,1,.36,7.54"
      transform="translate(-3.41 -16.46)"
      fill="#7f6a5b"
    />
  </svg>`,
})
export class NutsOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
