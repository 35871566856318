import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { GetSurveyResponse } from "../../interfaces/get-survey-response";
import { SurveyAnswer, SurveyAnswerOptionKind } from "../../interfaces/survey";

export type InitializeSurveyActionProps = GetSurveyResponse;

export interface UpdateSurveyAnswerActionProps {
  questionId: string;
  answer: SurveyAnswer<SurveyAnswerOptionKind>;
  isRemoved?: boolean;
}

export const SurveyActions = createActionGroup({
  source: "Survey",
  events: {
    initializeSurvey: props<InitializeSurveyActionProps>(),
    surveyStarted: emptyProps(),
    updateSurveyAnswer: props<UpdateSurveyAnswerActionProps>(),
  },
});
