import { SurveyVM } from "../interfaces/survey-vm";

export interface SurveyStepper {
  steps: number;
  selectedStepIdx: number;
}

export interface SurveyState {
  survey: SurveyVM | null;
  isStarted: boolean;
  isCompleted: boolean;
  canParticipate: boolean;
  stepper: SurveyStepper;
  error: unknown;
  isInitialized: boolean;
}

export const surveyInitialState: Readonly<SurveyState> = {
  survey: {
    id: "",
    name: "",
    description: "",
    questions: [],
    allergens: [],
    diets: [],
    initializedAt: new Date().toISOString(),
    isCompleted: false,
  },
  isInitialized: false,
  isStarted: false,
  isCompleted: false,
  canParticipate: false,
  stepper: {
    steps: 0,
    selectedStepIdx: -1,
  },
  error: null,
};
