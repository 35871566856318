import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "sulphites-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="faa3e9e2-7aaf-4939-9efa-4ced061bbcfc"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M32.57,53.88a6,6,0,0,0-2.35.46,6.19,6.19,0,1,0,8,8.18,6.28,6.28,0,0,0,.47-1.81,6.2,6.2,0,0,0-5.54-6.8c-.21,0-.41,0-.62,0"
      transform="translate(-3.41 -16.46)"
      fill="#aa5d7c"
    />
    <path
      d="M56.25,59.83a16.77,16.77,0,0,0,6.4-9.4A15.14,15.14,0,0,0,63,48.8a16.9,16.9,0,0,0,.18-2.44,16.62,16.62,0,1,0-32.56,4.73.15.15,0,0,0,.09,0l.6-.1.32,0a9.18,9.18,0,0,1,6.28,1.66l3-3L43,51.69l-3,3a9.13,9.13,0,0,1,1.74,5.36,8.43,8.43,0,0,1-.06,1c0,.11,0,.22,0,.33s-.07.45-.12.67a.5.5,0,0,1,0,.12,16.66,16.66,0,0,0,14.74-2.37Zm9.38-9a.84.84,0,0,1,0,.17c0,.17-.09.33-.14.5-.11.41-.24.82-.37,1.22l-.21.58c-.15.39-.31.76-.48,1.14l-.23.51-.15.31,2.27,1.39L65.49,58l-.78,1.28-2.28-1.39c0,.06-.09.11-.13.16s-.22.29-.34.43c-.26.33-.53.66-.81,1l-.41.45c-.3.31-.61.61-.92.91l-.38.35-.14.13a6.19,6.19,0,1,0,6.33-10.39M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84Z"
      transform="translate(-3.41 -16.46)"
      fill="#aa5d7c"
    />
  </svg>`,
})
export class SulphitesOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
