import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "lupins-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a3c36ffb-4fc3-4806-9840-3df7b223d2c8"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="orange" />
    <path
      d="M58.74,60.31c0-.11,0-.18,0-.26a7.42,7.42,0,0,0-3.49-6.35l-.2-.13-.15-.19a12.53,12.53,0,0,0-9.13-4.61h-.33L44,49l0-.2h-.16l-.36,0c-.31,0-.62.07-.92.13a.7.7,0,0,0-.14,0A12.37,12.37,0,0,0,38,50.77l0,0c-.32.21-.63.44-.93.68a11.28,11.28,0,0,0-.89.8A9.64,9.64,0,0,0,35,53.7l-.13.17-.17.13a7.21,7.21,0,0,0-2.92,5.43A7.33,7.33,0,0,0,35,65.59l.17.12.13.17a12.54,12.54,0,0,0,9.19,4.63,12.41,12.41,0,0,0,10.36-4.36L55,66l.2-.13a7.37,7.37,0,0,0,3.51-5.55Zm-9.44-5a4.35,4.35,0,0,0-1.23-1.06A5.44,5.44,0,0,0,43.16,54a4.66,4.66,0,0,0-1.44.94l-1-1.07-1.05-1.07a8.19,8.19,0,0,1,5.71-2.25,8.07,8.07,0,0,1,6.32,2.93L49.3,55.37Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
    <path
      d="M69.61,50.35l0-.23,0-.23a12.57,12.57,0,0,0-3.22-9.76A12.43,12.43,0,0,0,55.24,36L55,36l-.22,0a7.62,7.62,0,0,0-3.84.48,6.61,6.61,0,0,0-2.05,1.3,7.11,7.11,0,0,0-2.14,5.7c0,.32.06.65.11,1l0,.21,0,.22c0,.32-.06.64-.07,1l.42.07L48,46q.42.07.84.18t.69.18l.82.26c.22.08.44.15.66.24l.79.34.62.29c.26.13.51.28.76.43l.57.34c.26.17.5.35.75.53s.34.24.5.37.51.44.76.67.26.22.38.35c.36.36.71.74,1,1.14a11.61,11.61,0,0,1,1,.8,4.11,4.11,0,0,1,.3.29c.21.2.41.39.6.6s.21.24.31.37.33.39.47.6a11.23,11.23,0,0,1,.66,1c.08.15.16.3.23.45s.2.43.29.65l.18.46q.12.34.21.69c0,.16.09.31.12.47s.09.49.13.73.05.3.06.45a.59.59,0,0,0,0,.13,7.16,7.16,0,0,0,5.89-1.78,7.37,7.37,0,0,0,2-6.92Zm-2.41-2.7-3-.35a4.46,4.46,0,0,0-.12-1.62A5.44,5.44,0,0,0,60.82,42a4.51,4.51,0,0,0-1.68-.35l0-1.5,0-1.5a8.19,8.19,0,0,1,5.63,2.45,8.08,8.08,0,0,1,2.4,6.54Zm-37.63.77a7,7,0,0,0,4.86,1.38c.16-.15.33-.28.49-.42l.38-.32a14.44,14.44,0,0,1,2.44-1.56l.08,0c.42-.21.85-.4,1.29-.58l.12-.05q.66-.26,1.35-.45l.09,0A15.09,15.09,0,0,1,42.15,46a15.39,15.39,0,0,1,1.66-.22c0-.35,0-.7.07-1a10.2,10.2,0,0,1,3-9.16c2.44-2.29,0,0,0,0a9.86,9.86,0,0,1,.79-.66l.25-.18a6.42,6.42,0,0,1,.65-.41l.16-.1c0-.21-.09-.4-.14-.6l-.07-.24v-.24a7.21,7.21,0,0,0-2.67-6.27,6.88,6.88,0,0,0-4.25-1.41,8.26,8.26,0,0,0-2.9.54l-.22.08h-.24a12.56,12.56,0,0,0-9.11,4.74,12.36,12.36,0,0,0-2.31,11.65l.07.21v.22a7.13,7.13,0,0,0,2.68,5.52Zm.77-16.21a8.07,8.07,0,0,1,6.08-3.41l.13,3a4.39,4.39,0,0,0-1.58.37A5.44,5.44,0,0,0,31.88,36a4.5,4.5,0,0,0-.08,1.71l-3,.45a8.22,8.22,0,0,1,1.52-5.95Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class LupinsIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
