import { createFeature, createReducer, createSelector } from "@ngrx/store";
import { immerOn as on } from "ngrx-immer/store";
import { appPopupInitialState } from "./app-popup.state";
import { AppPopupActions } from "./actions/app-popup.actions";
import popupReducers from "./reducers/popup.reducers";

const APP_POPUP_FEATURE_NAME = "AppPopup";

export const appPopupFeature = createFeature({
  name: APP_POPUP_FEATURE_NAME,
  reducer: createReducer(
    appPopupInitialState,
    on(AppPopupActions.openPopup, popupReducers.openPopupReducer),
    on(AppPopupActions.clearPopups, popupReducers.clearPopupsReducer)
  ),
  extraSelectors: ({ selectPopup }) => ({
    selectPrevPopup: createSelector(selectPopup, (popup) => popup?.prev),
    selectNextPopup: createSelector(selectPopup, (popup) => popup?.next),
    selectPopupData: createSelector(selectPopup, (popup) => popup?.data),
  }),
});

export const appPopupSlice = {
  name: appPopupFeature.name,
  reducer: appPopupFeature.reducer,
} as const;
