import { OpenPopupProps } from "../actions/app-popup.actions";
import { AppPopupState } from "../app-popup.state";

const openPopupReducer = (state: AppPopupState, action: OpenPopupProps) => {
  const { current, next, data } = action;

  Object.assign(state.popup, { prev: current, next, data });
};

const clearPopupsReducer = (state: AppPopupState) => {
  Object.assign(state.popup, { prev: null, next: null, data: null });
};

export default {
  openPopupReducer,
  clearPopupsReducer,
};
