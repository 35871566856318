import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "super-spicy-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f57ebfb7-5682-455d-9137-4ce5ada92de0"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 434.52 157.9"
  >
    <path
      d="M177.79,310.09a138.83,138.83,0,0,1-25.6,1.38c-7.78-.34-15.46-1.39-22.76-4.31-6.66-2.66-12.41-6.57-15.55-13.28-4.81-10.28-8.2-21-8.59-32.47a64.7,64.7,0,0,0-4.37-21.59c-1.69-4.29-3.43-8.56-6.63-12-3.77,1.52-7.5.77-11.22-.2a30,30,0,0,1-5.72-2.13,1.06,1.06,0,0,0-1.6.37c-3.86,4.9-8.09,5.65-13.47,2.49a4.26,4.26,0,0,1-1.16-.74c-2.14-2.58-4.25-2-6.7-.32a23.2,23.2,0,0,1-7.21,3.12,5.14,5.14,0,0,1-3.66-.19l.1-.2h-.12c-1.87,1.36-2.16,3.56-2.74,5.53-2.53,8.6-2.49,17.48-1,26.11,3.56,20.77,12.88,38.63,28.89,52.56,12,10.46,26.19,16.57,41.71,19.71,3.29.67,6.65.58,10,1h14.24a.79.79,0,0,1,.53-.2,63.16,63.16,0,0,0,18-2.93c8.57-2.52,16.77-5.89,24-11.26,2.67-2,5.54-3.87,6.45-7.43v-2c-1.73-1.46-3.76-1.34-5.79-1.05ZM99,261.81c-.9,2.09-2.49,2.67-4.48,1.61A9.05,9.05,0,0,1,91.7,261a29.25,29.25,0,0,1-6.54-18.26c0-2.17.09-4.7,2.53-5.56s4.07,1.05,5.45,2.71c4.23,5.1,6.16,11.1,6.63,17.64a10.66,10.66,0,0,1-.74,4.27"
      transform="translate(-38.76 -177.06)"
      fill="#fd0000"
    />
    <path
      d="M47.2,230.38a23,23,0,0,0,7.21-3.12c2.46-1.65,4.56-2.26,6.7.32a4,4,0,0,0,1.16.74c5.38,3.16,9.61,2.41,13.47-2.49a1.06,1.06,0,0,1,1.6-.37,29.15,29.15,0,0,0,5.72,2.13c3.72,1,7.45,1.72,11.22.2a16.48,16.48,0,0,0-3.55-3.67c-4.58-3.86-10-6.32-15.3-8.89a5.13,5.13,0,0,1-3.09-4.2,37.64,37.64,0,0,1-.27-7.11,31.83,31.83,0,0,0-6.71-20.44c-1.64-2.2-3.43-4.29-5.15-6.42H58.63l-.63.45c-2.48,1.71-2.63,3.17-.6,5.39a46.57,46.57,0,0,1,5.26,6.92,28,28,0,0,1,4.28,16.39c-.26,5.53-1.75,7.53-6.94,9.32A26.94,26.94,0,0,0,43.65,230l-.1.2a5.19,5.19,0,0,0,3.66.19Z"
      transform="translate(-38.76 -177.06)"
      fill="#098c00"
    />
    <path
      d="M87.69,237.19c-2.44.86-2.55,3.39-2.53,5.56A29.25,29.25,0,0,0,91.7,261a8.94,8.94,0,0,0,2.85,2.41c2,1.05,3.58.48,4.48-1.61a10.32,10.32,0,0,0,.74-4.27C99.3,251,97.37,245,93.14,239.9c-1.38-1.67-3-3.57-5.45-2.71"
      transform="translate(-38.76 -177.06)"
      fill="#fefefe"
    />
    <path
      d="M322.63,310.09a138.83,138.83,0,0,1-25.6,1.38c-7.78-.34-15.46-1.39-22.76-4.31-6.66-2.66-12.41-6.57-15.55-13.28-4.81-10.28-8.2-21-8.59-32.47a64.7,64.7,0,0,0-4.37-21.59c-1.69-4.29-3.43-8.56-6.63-12-3.77,1.52-7.5.77-11.22-.2a30,30,0,0,1-5.72-2.13,1.06,1.06,0,0,0-1.6.37c-3.86,4.9-8.09,5.65-13.47,2.49a4.26,4.26,0,0,1-1.16-.74c-2.14-2.58-4.25-2-6.7-.32a23.2,23.2,0,0,1-7.21,3.12,5.14,5.14,0,0,1-3.66-.19c0-.07.07-.13.1-.2h-.12c-1.87,1.36-2.16,3.56-2.74,5.53-2.53,8.6-2.49,17.48-1,26.11,3.56,20.77,12.88,38.63,28.89,52.56,12,10.46,26.19,16.57,41.71,19.71,3.29.67,6.65.58,10,1h14.24a.79.79,0,0,1,.53-.2,63.16,63.16,0,0,0,18-2.93c8.57-2.52,16.77-5.89,24-11.26,2.67-2,5.54-3.87,6.45-7.43v-2c-1.73-1.46-3.76-1.34-5.79-1.05Zm-78.76-48.28c-.9,2.09-2.49,2.67-4.48,1.61a9.05,9.05,0,0,1-2.85-2.41A29.25,29.25,0,0,1,230,242.75c0-2.17.09-4.7,2.53-5.56s4.07,1.05,5.45,2.71c4.23,5.1,6.16,11.1,6.63,17.64a10.66,10.66,0,0,1-.74,4.27"
      transform="translate(-38.76 -177.06)"
      fill="#fd0000"
    />
    <path
      d="M192,230.38a23,23,0,0,0,7.21-3.12c2.46-1.65,4.56-2.26,6.7.32a4,4,0,0,0,1.16.74c5.38,3.16,9.61,2.41,13.47-2.49a1.06,1.06,0,0,1,1.6-.37,29.15,29.15,0,0,0,5.72,2.13c3.72,1,7.45,1.72,11.22.2a16.48,16.48,0,0,0-3.55-3.67c-4.58-3.86-10-6.32-15.3-8.89a5.13,5.13,0,0,1-3.09-4.2,37.64,37.64,0,0,1-.27-7.11,31.83,31.83,0,0,0-6.71-20.44c-1.64-2.2-3.43-4.29-5.15-6.42h-1.58l-.63.45c-2.48,1.71-2.63,3.17-.6,5.39a46.57,46.57,0,0,1,5.26,6.92,28,28,0,0,1,4.28,16.39c-.26,5.53-1.75,7.53-6.94,9.32A26.94,26.94,0,0,0,188.49,230c0,.07-.07.13-.1.2a5.19,5.19,0,0,0,3.66.19Z"
      transform="translate(-38.76 -177.06)"
      fill="#098c00"
    />
    <path
      d="M232.53,237.19c-2.44.86-2.55,3.39-2.53,5.56A29.25,29.25,0,0,0,236.54,261a8.94,8.94,0,0,0,2.85,2.41c2,1.05,3.58.48,4.48-1.61a10.32,10.32,0,0,0,.74-4.27c-.47-6.53-2.4-12.54-6.63-17.64-1.38-1.67-3-3.57-5.45-2.71"
      transform="translate(-38.76 -177.06)"
      fill="#fefefe"
    />
    <path
      d="M467.47,310.09a138.83,138.83,0,0,1-25.6,1.38c-7.78-.34-15.46-1.39-22.76-4.31-6.66-2.66-12.41-6.57-15.55-13.28-4.81-10.28-8.2-21-8.59-32.47a64.7,64.7,0,0,0-4.37-21.59c-1.69-4.29-3.43-8.56-6.63-12-3.77,1.52-7.5.77-11.22-.2a30,30,0,0,1-5.72-2.13,1.06,1.06,0,0,0-1.6.37c-3.86,4.9-8.09,5.65-13.47,2.49a4.26,4.26,0,0,1-1.16-.74c-2.14-2.58-4.25-2-6.7-.32a23.2,23.2,0,0,1-7.21,3.12,5.14,5.14,0,0,1-3.66-.19c0-.07.07-.13.1-.2h-.12c-1.87,1.36-2.16,3.56-2.74,5.53-2.53,8.6-2.49,17.48-1,26.11,3.56,20.77,12.88,38.63,28.89,52.56,12,10.46,26.19,16.57,41.71,19.71,3.29.67,6.65.58,9.95,1h14.24a.79.79,0,0,1,.53-.2,63.16,63.16,0,0,0,18-2.93c8.57-2.52,16.77-5.89,24-11.26,2.67-2,5.54-3.87,6.45-7.43v-2c-1.73-1.46-3.76-1.34-5.79-1.05Zm-78.76-48.28c-.9,2.09-2.49,2.67-4.48,1.61a9.05,9.05,0,0,1-2.85-2.41,29.25,29.25,0,0,1-6.54-18.26c0-2.17.09-4.7,2.53-5.56s4.07,1.05,5.45,2.71c4.23,5.1,6.16,11.1,6.63,17.64a10.66,10.66,0,0,1-.74,4.27"
      transform="translate(-38.76 -177.06)"
      fill="#fd0000"
    />
    <path
      d="M336.88,230.38a23,23,0,0,0,7.21-3.12c2.46-1.65,4.56-2.26,6.7.32a4,4,0,0,0,1.16.74c5.38,3.16,9.61,2.41,13.47-2.49a1.06,1.06,0,0,1,1.6-.37,29.15,29.15,0,0,0,5.72,2.13c3.72,1,7.45,1.72,11.22.2a16.48,16.48,0,0,0-3.55-3.67c-4.58-3.86-10-6.32-15.3-8.89A5.13,5.13,0,0,1,362,211a37.64,37.64,0,0,1-.27-7.11A31.83,31.83,0,0,0,355,183.48c-1.64-2.2-3.43-4.29-5.15-6.42h-1.58l-.63.45c-2.48,1.71-2.63,3.17-.6,5.39a46.57,46.57,0,0,1,5.26,6.92,28,28,0,0,1,4.28,16.39c-.26,5.53-1.75,7.53-6.94,9.32A26.94,26.94,0,0,0,333.33,230c0,.07-.07.13-.1.2a5.19,5.19,0,0,0,3.66.19Z"
      transform="translate(-38.76 -177.06)"
      fill="#098c00"
    />
    <path
      d="M377.37,237.19c-2.44.86-2.55,3.39-2.53,5.56A29.25,29.25,0,0,0,381.38,261a8.94,8.94,0,0,0,2.85,2.41c2,1.05,3.58.48,4.48-1.61a10.32,10.32,0,0,0,.74-4.27c-.47-6.53-2.4-12.54-6.63-17.64-1.38-1.67-3-3.57-5.45-2.71"
      transform="translate(-38.76 -177.06)"
      fill="#fefefe"
    />
  </svg>`,
})
export class SuperSpicyIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
