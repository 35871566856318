import { DestroyRef, inject, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Nullable } from "@eqn/data-types";
import { PreferenceLabel } from "@menufy/models";

@Pipe({
  name: "prefLabelTranslate",
  standalone: true,
  pure: false,
})
export class PreferenceLabelTranslatePipe implements PipeTransform {
  private _memoizedValue = "";

  private _memoizedLabel?: string;

  private readonly _destroyContext = inject(DestroyRef);

  constructor(private readonly _translate: TranslateService) {}

  transform(label?: string, hasOptionalSuffix = false): string {
    if (this._memoizedLabel || !label) {
      return this._memoizedValue;
    }

    this._memoizedLabel = label;

    const labelName = hasOptionalSuffix ? `${label}_optional` : label;

    this._translate
      .get(labelName.toLowerCase())
      .pipe(takeUntilDestroyed(this._destroyContext))
      .subscribe((translation) => {
        this._memoizedValue = translation;
      });

    return this._memoizedValue;
  }
}

/**
 * @deprecated
 */
@Pipe({
  name: "prefLabelIcon",
  standalone: true,
})
export class PreferenceLabelIconPipe implements PipeTransform {
  transform(label?: Omit<PreferenceLabel, "type">): Nullable<string> {
    if (!label) {
      return;
    }

    const labelName = label?.name?.trim()?.replace(/\s+/g, "-"); // transform to kebab-case for fallsafe

    let iconSrc = `/assets/icons/${labelName?.toLowerCase()}`;

    if (label?.isOptional) {
      iconSrc = iconSrc.concat("-optional");
    }

    return iconSrc.concat(".svg");
  }
}
