<section class="preferences-popup-container pb-4">
  <div class="container px-3">
    <div class="row title-container-shadow" swipeDownToClose>
      <div class="col-12 col-md-12 text-center mt-4">
        <h3 [innerHTML]="'preferences_title' | translate"></h3>
      </div>
    </div>

    <!-- Allergens -->
    <div class="row">
      <div class="col-12 col-md-12 mt-2 mb-1">
        <div class="d-flex flex-row align-items-center justify-content-center gap-2">
          <h2 class="m-0">{{ "allergens" | translate }}</h2>
        </div>
      </div>

      <!-- List -->
      @for (allergen of allergens; track $index) {
      <div class="col-6 col-md-6">
        <preferences-checkbox
          (labelCheckboxChange)="onLabelSelectionToggle($event)"
          [label]="allergen"
          [type]="LabelType.ALLERGEN"
        />
      </div>
      }
    </div>

    <!-- Diets -->
    <div class="row mt-3">
      <div class="col-12 col-md-12">
        <div class="line-separator"></div>
      </div>

      <div class="col-12 col-md-12 mt-2 mb-1">
        <div class="d-flex flex-row align-items-center justify-content-center gap-2">
          <h2 class="m-0">{{ "diets" | translate }}</h2>
        </div>
      </div>

      <!-- List -->
      @for (diet of diets; track $index) {
      <div class="col-6 col-md-6">
        <preferences-checkbox
          (labelCheckboxChange)="onLabelSelectionToggle($event)"
          [label]="diet"
          [type]="LabelType.DIET"
        />
      </div>
      }
    </div>

    <div class="row px-4">
      <div class="col-12 col-md-12 mt-4 text-center">
        <button
          (click)="submit()"
          class="fs-1 p-4 fw-bold w-75 mb-2 primary-btn"
          mat-flat-button
          type="submit"
        >
          {{ "done_btn" | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- Close icon -->
  <close-icon class="close-icon" (click)="dismissPopup()" swipeDownToClose />

  <!-- border -->
  <div class="popup-top-border-wrapper" swipeDownToClose>
    <span></span>
  </div>
</section>
