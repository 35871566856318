import { PopupEvent } from "@diners/common";

export interface AppPopupState {
  popup: {
    data: unknown | null;
    next: PopupEvent | null;
    prev: PopupEvent | null;
  };
}

export const appPopupInitialState: Readonly<AppPopupState> = {
  popup: {
    data: null,
    prev: null,
    next: null,
  },
};
