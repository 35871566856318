import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "dots-icon-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f78062db-0ac1-4741-ba50-11637a7856f8"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 78 78"
  >
    <circle cx="39" cy="39" r="39" fill="#fff" />
    <path
      d="M25,35a4,4,0,1,1-4,4,4,4,0,0,1,4-4m14,0a4,4,0,1,1-4,4,4,4,0,0,1,4-4m14,0a4,4,0,1,1-4,4,4,4,0,0,1,4-4"
      fill="#494b4d"
      fill-rule="evenodd"
    />
  </svg>`,
})
export class DotsIconIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
