import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PreferenceLabelTranslatePipe } from "@diners/common";
import { PreferenceLabel } from "@menufy/models";
import { MenufyIconComponent } from "@menufy/icons";
import { NgComponentOutlet, NgIf } from "@angular/common";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "preferences-image",
  standalone: true,
  imports: [PreferenceLabelTranslatePipe, NgComponentOutlet, NgIf, MenufyIconComponent],
  template: `
    <div class="d-flex flex-row flex-wrap align-items-center gap-2 mt-1">
      @if (prefLabel) {
      <men-icon [icon]="prefLabel.name" width="26" height="26" [isOptional]="isOptional" #menIcon />

      <span class="title" [class.d-none]="!menIcon.hasIcon">{{
        prefLabel.name | prefLabelTranslate : prefLabel.isOptional
      }}</span>

      }
    </div>
  `,
  styleUrls: ["./preferences-image.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PreferencesImage {
  public prefLabel?: Omit<PreferenceLabel, "type">;

  @Input({ transform: booleanAttribute }) public isOptional!: boolean;

  @Input()
  public set label(value: string) {
    this.prefLabel = {
      name: value,
      isOptional: this.isOptional,
    };
  }
}
