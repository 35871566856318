import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "gluten-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="b13d4739-a44a-42ee-8543-a08c1d704c2a"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#fbdc63" />
    <circle cx="42.91" cy="42.91" r="42.91" fill="#e3b46b" />
    <path
      d="M44.94,57a12.08,12.08,0,0,1,1.63,2.69V57c-.28,0-.85.07-1.63.07M49.58,57V59.4A12.24,12.24,0,0,1,51.06,57c-.66,0-1.17,0-1.48-.06"
      transform="translate(-5.09 -5.09)"
      fill="none"
    />
    <path
      d="M48,18c.24,5.25,7.89,9.8,6.79,16a8.36,8.36,0,0,1-6.79,7,8.35,8.35,0,0,1-6.79-7C40.12,27.75,47.76,23.2,48,18M29.11,33.73c1.3,4.73,19,6.43,17.2,17.47,0,0-13.2-4.7-16.08-8.19-1.61-1.95-1.58-6.4-1.12-9.28m0,13c1.3,4.73,19,6.43,17.2,17.47,0,0-13.2-4.7-16.08-8.19-1.61-2-1.58-6.4-1.12-9.28m0,13.83c1.3,4.73,19,6.43,17.2,17.47,0,0-13.2-4.7-16.08-8.19-1.61-1.95-1.58-6.4-1.12-9.28M66.89,33.73c-1.3,4.73-19,6.43-17.2,17.47,0,0,13.2-4.7,16.08-8.19,1.61-1.95,1.58-6.4,1.12-9.28m0,13c-1.3,4.73-19,6.43-17.2,17.47,0,0,13.2-4.7,16.08-8.19,1.61-2,1.58-6.4,1.12-9.28m0,13.83C65.59,65.3,47.93,67,49.69,78c0,0,13.2-4.7,16.08-8.19,1.61-1.95,1.58-6.4,1.12-9.28"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class GlutenIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
