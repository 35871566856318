import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "peanuts-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a8768d82-cae1-432b-a6f2-0eb0716e40ca"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84ZM72.24,27.46A13.9,13.9,0,0,0,52.65,26a14.19,14.19,0,0,0-3.53,4.65c-2.21,4.73-6.63,8.16-11.86,8.26a17.22,17.22,0,1,0,17,21.56c1.39-5.34,5.92-9.09,11.23-10.59a13.64,13.64,0,0,0,5.29-2.84,13.92,13.92,0,0,0,1.45-19.61Zm-35.82,22a5.05,5.05,0,0,0-2.89,1.9,6.65,6.65,0,0,0-1,5.13,2.88,2.88,0,1,1-5.69.84v0a12.18,12.18,0,0,1,2.13-9.4,10.71,10.71,0,0,1,6.69-4.16,2.89,2.89,0,0,1,.8,5.72Zm27-19.24a2.91,2.91,0,0,1-3.17,2.52,1.92,1.92,0,0,0-2,1.35,2.88,2.88,0,0,1-2.69,1.83,3.06,3.06,0,0,1-1.06-.2,2.89,2.89,0,0,1-1.63-3.74,7.76,7.76,0,0,1,8-5,2.9,2.9,0,0,1,2.51,3.22"
      transform="translate(-3.41 -16.46)"
      fill="#c29250"
    />
  </svg>`,
})
export class PeanutsOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
