import { Routes } from "@angular/router";

export default [
  {
    path: "menu",
    loadChildren: () => import("./business/routes"),
  },
  {
    path: "**",
    redirectTo: "menu",
    pathMatch: "full",
  },
] as Routes;
