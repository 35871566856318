import { EMPTY_OBJECT } from "@eqn/data-types";

export * from "./events";

interface EventData {
  readonly props?: { readonly [propName: string]: string | number | boolean };
}

declare const plausible: (event: string, props: EventData) => void;

export function plausibleTrackEvent(event: string, data: EventData = { props: EMPTY_OBJECT }) {
  if (typeof plausible === "undefined") {
    return;
  }

  plausible(event, data);
}
