import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { filter, map, of, tap, timer } from "rxjs";
import { catchError, exhaustMap } from "rxjs/operators";
import { SurveyApiService } from "../../services/survey-api.service";
import { SurveyApiActions } from "../actions/survey-api.actions";
import { SurveyPageActions } from "../actions/survey-page.actions";
import { selectSurveyState } from "../survey.selectors";
import { SurveyState } from "../survey.state";
import { SurveyActions } from "../actions";
import { SubmittedSurveyResponse } from "../../interfaces/submit-survey";
import { LocaleService } from "@eqn/angular-i18n";
import { SurveyPageService } from "../../services/survey-page.service";
import { selectPreferencesState } from "../../../personalized-dining/state";
import { selectBusinessHandle } from "../../../business/state";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { DinersPlausibleEvent, plausibleTrackEvent } from "@menufy/plausible";

@Injectable()
export class SurveyEffects {
  private readonly _getSurveyOnlangChange$ = createEffect(() => {
    return this._translate.onLangChange.pipe(
      concatLatestFrom(() => this._store.select(selectSurveyState)),
      filter(([, survey]) => {
        if (!survey.isInitialized) {
          return true;
        }

        return survey.canParticipate;
      }),
      exhaustMap(() =>
        this._surveyApi.getSurvey().pipe(
          filter((apiResponse) => {
            const completedSurveyId = window.localStorage.getItem(environment.survey.storageName);

            return completedSurveyId !== apiResponse.survey.id;
          }),
          map((apiResponse) =>
            SurveyActions.initializeSurvey({
              ...apiResponse,
            })
          ),
          catchError((error: unknown) => of(SurveyApiActions.getSurveyFailed({ error })))
        )
      )
    );
  });

  private readonly _showSurveyBanner$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SurveyActions.initializeSurvey),
        filter((survey) => survey.canParticipate),
        exhaustMap(() => timer(environment.survey.appearanceDelay)),
        tap(() => this._surveyPage.showSurveyBanner())
      ),
    {
      dispatch: false,
    }
  );

  private readonly _surveyStarted$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveyPageActions.startSurvey),
      map(() => SurveyActions.surveyStarted())
    )
  );

  private readonly _showSurveyPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SurveyPageActions.startSurvey),
        tap(() => {
          // send plausible event when survey is started
          plausibleTrackEvent(DinersPlausibleEvent.START_SURVEY);

          this._surveyPage.showSurveyPage();
        })
      ),
    {
      dispatch: false,
    }
  );

  private readonly _closeSurveyPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SurveyPageActions.closeSurvey),
        tap(() => this._surveyPage.closeSurveyPage())
      ),
    {
      dispatch: false,
    }
  );

  private readonly _submitSurvey$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveyPageActions.submitSurvey),
      tap((action) => {
        if (!action.closeSurveyPage) {
          return;
        }
        this._surveyPage.closeSurveyPage();
      }),
      concatLatestFrom(() => [
        this._store.select(selectSurveyState),
        this._store.select(selectPreferencesState),
        this._store.select(selectBusinessHandle),
      ]),
      exhaustMap(([, surveyState, prefs, businessHandle]) => {
        const { survey, isStarted } = surveyState;

        const surveyResponse = {
          ...survey,
          isStarted,
          language: this._locale.currentLocale.langId,
          allergens: prefs.selectedAllergens,
          diets: prefs.selectedDiets,
          business: businessHandle,
          isCompleted: !!surveyState?.isCompleted,
          endedAt: surveyState?.isCompleted ? new Date().toISOString() : null,
        } as SubmittedSurveyResponse;

        return this._surveyApi.submitResponse(surveyResponse).pipe(
          map((apiResponse) => SurveyApiActions.submitSurveySuccess(apiResponse)),
          catchError((error: unknown) => of(SurveyApiActions.submitSurveyFailed({ error })))
        );
      })
    )
  );

  private readonly _storeSurveyCompletionStatus$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SurveyApiActions.submitSurveySuccess),
        concatLatestFrom(() => this._store.select(selectSurveyState)),
        tap(([action, surveyState]) => {
          // send plausible event for close button click if user not taken the survey
          if (!surveyState.isStarted) {
            plausibleTrackEvent(DinersPlausibleEvent.CLOSE_SURVEY);
          }

          window.localStorage.setItem(environment.survey.storageName, action.survey);
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<SurveyState>,
    private readonly _surveyApi: SurveyApiService,
    private readonly _surveyPage: SurveyPageService,
    private readonly _locale: LocaleService,
    private readonly _translate: TranslateService
  ) {}
}
