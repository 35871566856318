import { createFeature, createReducer, createSelector } from "@ngrx/store";
import { immerOn as on } from "ngrx-immer/store";
import { dishInitialState } from "./dish.state";
import { DishPageActions } from "./actions/dish-page.actions";
import dishPageReducers from "./reducers/dish-page.reducers";

const DISH_FEATURE_NAME = "dish";

export const dishFeature = createFeature({
  name: DISH_FEATURE_NAME,
  reducer: createReducer(
    dishInitialState,
    on(DishPageActions.setSelectedDish, dishPageReducers.setSelectedDishReducer)
  ),
  extraSelectors: ({ selectDish }) => ({
    selectDishId: createSelector(selectDish, (dish) => dish?.id),
  }),
});

export const dishSlice = {
  name: dishFeature.name,
  reducer: dishFeature.reducer,
} as const;
