import { createActionGroup, props } from "@ngrx/store";
import { Business } from "@menufy/models";

export interface GetBusinessSuccessActionProps {
  business: Business;
}

export interface GetBusinessFailedActionProps {
  error: unknown;
}

export const BusinessApiActions = createActionGroup({
  source: "Business/Api",
  events: {
    getBusinessSuccess: props<GetBusinessSuccessActionProps>(),
    getBusinessFailed: props<GetBusinessFailedActionProps>(),
  },
});
