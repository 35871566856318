import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "vegan-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="ef55697d-0229-4fb5-812e-eded5514e1f1"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#007140" />
    <path
      d="M58.35,64.72s9.92-13.87-7.25-20.87C39.43,39.09,30,34.2,26.18,25.61c-13.67,17.08.95,57.4,27.49,44C52.63,57,40.61,50.93,31,49.8c13.64-2.6,24.28,5.38,27.31,14.91Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
    <path
      d="M51.1,39.4s1.73-12.62,22.6-15.67C73.7,27.81,80,51,65.12,54.51,60.7,48.18,62.09,38,66.51,34c-6.85,1.73-9.62,8.27-9.62,8.27a12,12,0,0,0-5.78-2.83Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class VeganIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
