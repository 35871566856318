import { Component } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import {
  PopupEvent,
  PreferenceLabelTranslatePipe,
  SwipeDownToCloseDirective,
} from "@diners/common";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { PreferencesImage } from "./preferences-image/preferences-image";
import { Store } from "@ngrx/store";
import { PreferencesState } from "../state/prefs.state";
import { selectPreferencesState } from "../state";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppPopupActions } from "../../store/actions/app-popup.actions";
import { MenuPreferencesFilters } from "../../menus/interfaces/prefs-filters";
import { CloseIconComponent } from "@menufy/icons";

@Component({
  selector: "menufy-web-icons-guide",
  standalone: true,
  templateUrl: "./icons-guide.component.html",
  styleUrls: ["./icons-guide.component.scss"],
  imports: [
    MatButtonModule,
    SwipeDownToCloseDirective,
    TranslateModule,
    PreferenceLabelTranslatePipe,
    PreferencesImage,
    CloseIconComponent,
  ],
})
export class IconsGuideComponent {
  public menuPreferences?: MenuPreferencesFilters;

  constructor(
    private readonly _bottomSheetRef: MatBottomSheetRef<IconsGuideComponent>,
    private readonly _store: Store<PreferencesState>
  ) {
    this._store
      .select(selectPreferencesState)
      .pipe(takeUntilDestroyed())
      .subscribe((prefs) => (this.menuPreferences = prefs));
  }

  public submit() {
    this._bottomSheetRef.dismiss();

    this._store.dispatch(AppPopupActions.clearPopups());
  }

  public backToPrefs() {
    this._bottomSheetRef.dismiss();

    this._store.dispatch(
      AppPopupActions.openPopup({
        current: PopupEvent.ICONS_GUIDE,
        next: PopupEvent.PREFERENCES,
      })
    );
  }
}
