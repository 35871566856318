@for (option of question().options; track $index) {
<div class="d-flex justify-content-between flex-row align-items-center mb-1 w-75 m-auto">
  <label [for]="option.label">{{ option.label }}</label>
  <input
    (change)="updateAnswer(option, optionTick.checked, other.value)"
    type="checkbox"
    class="form-check-input"
    [name]="question().id"
    [id]="option.label"
    #optionTick
  />
</div>

<input
  [hidden]="option.kind !== SelectOptionType.OTHER"
  class="form-control w-75 m-auto"
  placeholder="{{ 'survey.question.multi_select.inp_placeholder' | translate }}"
  (change)="updateAnswer(option, optionTick.checked, other.value);"
  type="text"
  #other
/>
}
