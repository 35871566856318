import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox";
import { PreferenceLabelType } from "@menufy/models";
import { PreferenceLabelTranslatePipe } from "@diners/common";
import { MenufyIconComponent } from "@menufy/icons";

export interface PreferenceLabelCheckbox {
  name: string;
  checked: boolean;
}

export interface PreferenceLabelCheckboxEvent extends PreferenceLabelCheckbox {
  type: PreferenceLabelType;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "preferences-checkbox",
  standalone: true,
  imports: [MatCheckboxModule, PreferenceLabelTranslatePipe, MenufyIconComponent],
  template: `
    <div class="checkbox-list d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-row flex-wrap align-items-center gap-1">
        <men-icon [icon]="label.name" width="28" height="28" />
        <span class="title text-black">{{ label.name | prefLabelTranslate }}</span>
      </div>
      <mat-checkbox color="primary" [checked]="label.checked" (change)="onCheckboxChange($event)">
      </mat-checkbox>
    </div>
  `,
  styleUrls: ["./preferences-checkbox.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PreferencesCheckbox {
  @Input({ required: true }) public type!: PreferenceLabelType;

  @Input({ required: true }) public label!: PreferenceLabelCheckbox;

  @Output() public labelCheckboxChange = new EventEmitter<PreferenceLabelCheckboxEvent>();

  public onCheckboxChange(event: MatCheckboxChange) {
    this.labelCheckboxChange.emit({
      type: this.type,
      name: this.label.name,
      checked: event.checked,
    });
  }
}
