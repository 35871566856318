import { Price } from "../price/price";
import { MenuItem } from "../constants/menu.constants";
import { FileRecord } from "../file-record";
import { PreferenceLabelType } from "../constants/dish.constants";
import { EntityIdField } from "../entity";

export interface PreferenceLabel<T = PreferenceLabelType> {
  name: string;
  isOptional?: boolean;
  type: T;
}

export type DishOptionItemLabel = Pick<PreferenceLabel, "name" | "isOptional">;

export interface DishOptionItem extends EntityIdField {
  name: string;
  allergens: PreferenceLabel<PreferenceLabelType.ALLERGEN>[];
  diets: PreferenceLabel<PreferenceLabelType.DIET>[];
  price?: Price;
}

export interface DishOption extends EntityIdField {
  category: string;
  isFood?: boolean;
  items: DishOptionItem[];
}

export interface Dish extends EntityIdField {
  formattedDish: PreferenceLabel<PreferenceLabelType.ALLERGEN>;
  type: MenuItem.DISH;
  menu: string; // menu id
  business: string; // business id
  name: string;
  description?: string;
  images: FileRecord[];
  prices: Price[];
  allergens: PreferenceLabel<PreferenceLabelType.ALLERGEN>[];
  additionalAllergensInfo?: string;
  diets: PreferenceLabel<PreferenceLabelType.DIET>[];
  specialTags: PreferenceLabel<PreferenceLabelType.SPECIAL_TAG>[];
  ingredients?: string;
  options: DishOption[];
  position?: number;
  hidden?: boolean;
}
