import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "celery-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="fb7f6cae-18f5-4954-9552-cf149754fdea"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#acd089" />
    <path
      d="M70.29,54.67a5,5,0,0,0,2.43-4.39,12.49,12.49,0,0,1-13-.81A13.63,13.63,0,0,0,72.25,40a24.21,24.21,0,0,1-4.43-3s2.81-3.49,1.28-10.14C62.45,25.36,59,28.17,59,28.17a24.21,24.21,0,0,1-3-4.43,13.66,13.66,0,0,0-9.46,12.52,12.49,12.49,0,0,1-.81-13,5,5,0,0,0-4.39,2.43C40.17,23,40.38,18,40.38,18,35,19.27,29.26,30,35,36.48L47.24,48.75,59.51,61C66,66.73,76.72,61,78,55.61c0,0-5.07.21-7.75-.94M27.4,54a9.35,9.35,0,0,0-1.76,14.6l1.72,1.72A9.35,9.35,0,0,0,42,68.59l6-9.86L37.25,48l-9.86,6Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class CeleryIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
