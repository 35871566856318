import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  OnInit,
  Output,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  SurveyAnswerOptionKind,
  SurveyMultiSelectOption,
  SurveyMultiSelectQuestion,
} from "../../interfaces/survey";
import { SurveyActions } from "../../state";
import { SurveyState } from "../../state/survey.state";
import { TranslateModule } from "@ngx-translate/core";
import { isEmpty } from "lodash-es";

@Component({
  selector: "menufy-web-survey-select",
  standalone: true,
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./multi-select.html",
  styles: `
    label {
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
    }

    .form-check-input {
      box-shadow: none;
      width: 1.3rem;
      height: 1.3rem;
      border-color: rgba(0, 0, 0, 0.5);

      &:focus {
        border-color: #000;
      }

      &:checked {
        border-color: #000;
        background-color: #000;
      }
    }

    input.form-control {
      margin-top: 2rem;
      outline: none;
      box-shadow: none;
      font-style: italic;
      font-size: 16px;
      border-radius: 6px;

      &:hover {
        border-color: #000;
      }
    }
  `,
})
export class SurveyMultiSelectInputComponent implements OnInit {
  @Output() isValidEvent = new EventEmitter<boolean>();

  public readonly SelectOptionType = SurveyAnswerOptionKind;

  public question = input.required<SurveyMultiSelectQuestion>();

  constructor(private readonly _store: Store<SurveyState>) {}

  ngOnInit() {
    const hasAnswer = !!this.question()?.answer?.length;
    this.isValidEvent.emit(hasAnswer);
  }

  public updateAnswer(option: SurveyMultiSelectOption, isSelected: boolean, otherText?: string) {
    const questionId = this.question().id;
    const hasText = !isEmpty(otherText);

    const kind =
      option.kind === SurveyAnswerOptionKind.OTHER
        ? SurveyAnswerOptionKind.OTHER
        : SurveyAnswerOptionKind.OPTION;

    const answer = {
      kind,
      value:
        option.kind === SurveyAnswerOptionKind.OTHER
          ? hasText
            ? otherText
            : "empty"
          : option.value,
    };

    this.isValidEvent.emit(true);

    this._store.dispatch(
      SurveyActions.updateSurveyAnswer({
        questionId,
        answer,
        isRemoved: hasText ? false : !isSelected,
      })
    );
  }
}
