import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "vegan-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="bfda6061-36e9-4f3f-b39a-2f84a8f93c72"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84ZM58.35,64.72s9.92-13.87-7.25-20.87C39.43,39.09,30,34.2,26.18,25.61c-13.67,17.08.95,57.4,27.49,44C52.63,57,40.61,50.93,31,49.8c13.64-2.6,24.28,5.38,27.31,14.91Z"
      transform="translate(-3.41 -16.46)"
      fill="#007140"
    />
    <path
      d="M51.1,39.4s1.73-12.62,22.6-15.67C73.7,27.81,80,51,65.12,54.51,60.7,48.18,62.09,38,66.51,34c-6.85,1.73-9.62,8.27-9.62,8.27a12,12,0,0,0-5.78-2.83Z"
      transform="translate(-3.41 -16.46)"
      fill="#007140"
    />
  </svg>`,
})
export class VeganOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
