import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "oatly-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f97184b9-15bd-4216-97dc-7c261334ad7e"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 124.9 50"
  >
    <path d="M0,25A25,25,0,0,1,25,0H99.9a25,25,0,0,1,0,50H25A25,25,0,0,1,0,25" fill="#9fcad6" />
    <path
      d="M90.86,18.93c-2.58,0-3.8,1-3.93,2.55s1.11,1.7.38,3.64-1.72,1.7-2.58-.36c-.37-.84,1.11-1.58,1.22-3.15s-1.11-2.43-4.17-2.55-4.28,1-4.41,3,1.47,2.06,2.08,3.28c1.59,2.91,2.81,4.74,4.17,7.16.86,1.57-.37,4.12-2.2,3.39-1-.36-2-.13-2.45,1.45-1.11,3.52,2.93,4.13,5.14,4,2.7-.12,4.17-2.19,5-5.09,1.11-3.39,2.45-7.52,3.44-10.32S95,24.39,94.9,22s-1.47-2.9-4-3M76.28,15.29c0-2.19-.86-2.79-3.19-2.9-2,0-3.92.61-4.28,2.9-.38,1.94,2,2.07,2,3.89,0,2.42,0,7.64-.12,10.19,0,2.19-1.72,1.1-1.72,3.52,0,2.19,2,2.19,4.4,2.31,2.71.13,4.53-.36,4.53-2.19,0-2.42-1.46-1.58-1.59-4,0-1.83,0-11.53,0-13.72M56.06,23.67c0-2.68-.86-4.86-5.14-4.86-3.8,0-5.52,1.32-5.77,3.64-.12,1.94.48,2.9,2,2.9,2.82-.12,1.6-2.31,3.32-2.31a1,1,0,0,1,1.11,1.1c-.13,2.06-6.38.35-6.38,6.18,0,2.91.73,5,3.67,5s2.58-1.22,3.55-1.22,1,.85,2.08.85a2.47,2.47,0,0,0,2.45-2.55c.13-1.94-.61-2.19-.86-3.16-.09-1.06,0-2.87,0-5.54m-5.39,7.15c-1.11,0-1.11-1.22-.13-1.7.49-.25,1-.12,1,.73s-.36,1-.86,1m15.2-6.92c1.47,0,2.33-.61,2.33-2.31s-.61-2.32-2.08-2.32-1.34-.73-1.34-2.42c0-2.18-.13-3.64-2.21-3.64s-2.7,1.22-2.81,3.52c0,1.33.13,2.31-.73,2.42-1.47,0-2.21.61-2.21,2.42s.61,2.42,1.47,2.55,1.22.12,1.22,1.09,0,2.68.13,5.71S62,35,64.29,35s3.92-1.33,4-4.12c.12-3.16-.48-3.64-1.47-3.64s-1.22.35-1.22,1.09c0,.36-.12,1-.61,1s-.48-.49-.61-2.67c-.28-2,0-2.77,1.44-2.77M37.32,12.74c-2.81,0-6,1.1-6.73,4.49-1,4-.61,12.5.12,15.05s3.67,3.28,6.61,3.28,5.14-1.33,5.88-3.28,1.34-10.8.25-15-3.32-4.49-6.13-4.49m.74,15.54c-.13.48-.49,1-.86,1a1,1,0,0,1-.86-.85,26.39,26.39,0,0,1-.13-8.25c.13-.84.49-1.58,1.11-1.58s1,.74,1.11,1.71a62.27,62.27,0,0,1-.37,8"
      fill="#fff"
    />
  </svg>`,
})
export class OatlyIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
