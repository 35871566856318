import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "vacka-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a40aa88d-80b0-4e71-b2e1-68f2059d6611"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 124.9 50"
  >
    <path d="M99.9,50H25A25,25,0,0,1,25,0H99.9a25,25,0,0,1,0,50" fill="#f4b1cb" />
    <path
      d="M38,31.31,35.58,13H32l3.87,24H40l3.87-24H40.54ZM50.19,13c-3.46,0-5.09.08-5.09,5V37h3.25V28.31H52V37h3.25V18c0-5-1.63-5-5.1-5M52,25.25H48.35V18c0-2.12.31-2.21,1.85-2.21S52,15.92,52,18ZM87.77,13c-3.47,0-5.09.08-5.09,5V37h3.25V28.31h3.68V37h3.25V18c0-5-1.62-5-5.09-5M85.93,25.25V18c0-2.12.31-2.21,1.84-2.21s1.84.09,1.84,2.21v7.21ZM62.9,13c-3.47,0-4.85.08-4.85,5V32c0,5,1.38,5,4.85,5s4.84-.08,4.84-5v-.48H64.49V32c0,2.12-.2,2.21-1.59,2.21s-1.6-.09-1.6-2.21V18c0-2.12.2-2.21,1.6-2.21s1.59.09,1.59,2.21v1.7h3.25V18c0-5-1.38-5-4.84-5m7.4,0h3.25V37H70.3Zm6.54,11.21L80.08,13H77.46a.5.5,0,0,0-.48.37L74,24.09a3.87,3.87,0,0,0,.08,2.31L77.82,37h3.25L76.9,25.77a2.45,2.45,0,0,1-.06-1.56M46.62,8h2.46v3.42H46.62Zm4.69,0h2.46v3.42H51.31Z"
    />
  </svg>`,
})
export class VackaIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
