import { MenuState } from "../menu.state";
import { GetMenusFailedActionProps, GetMenusSuccessActionProps } from "../actions/menu-api.actions";
import { Dish, Menu, MenuItem } from "@menufy/models";
import { cloneDeep } from "lodash-es";

const getMenusSuccessReducer = (state: MenuState, action: GetMenusSuccessActionProps) => {
  const { result, reset } = action;
  let menuList: Menu[] = [];

  if (reset) {
    menuList = result.data;
  } else {
    menuList = (state.menus || []).concat(result.data);
  }

  function filterHiddenItems(items: (Menu | Dish)[]) {
    const filteredItems: (Menu | Dish)[] = [];

    for (const item of items) {
      if (item.hidden) {
        continue;
      }

      if (item.type === MenuItem.MENU) {
        item.items = filterHiddenItems(item.items);
      }

      filteredItems.push(item);
    }

    return filteredItems;
  }

  state.menus = filterHiddenItems(cloneDeep(menuList)) as Menu[];
  state.cursor = result.cursor;
  state.error = null;
};

const getMenusFailedReducer = (state: MenuState, action: GetMenusFailedActionProps) => {
  state.error = action.error;
};

export default {
  getMenusSuccessReducer,
  getMenusFailedReducer,
};
