import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { SurveyPageActions } from "./state";
import { SurveyState } from "./state/survey.state";

@Component({
  selector: "menufy-web-close-survey",
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <button (click)="closeSurvey()" class="survey-close-btn">
    <svg
      id="bb70fe66-0f7d-4c47-a1af-b801e74842de"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 81 81"
    >
      <circle
        id="a537407c-a059-48bc-a876-dc0e1c0aa166"
        data-name="Menu copy"
        cx="40.5"
        cy="40.5"
        r="40.5"
        fill="transparent"
      />
      <circle
        id="ae5bb438-0764-4d74-8514-ad77d6a8883b"
        data-name="Menu copy 2"
        cx="40.5"
        cy="40.5"
        r="40.5"
        fill="transparent"
        opacity="0"
        style="isolation:isolate"
      />
      <path
        id="fec10ebd-bcbd-4779-843a-1cb1c5e919c6"
        data-name="Rounded Rectangle 7 copy"
        d="M60.23,60.21c-1.24,1.27-3.54,1-5.14-.67L25.42,29.08c-1.6-1.64-1.89-4-.65-5.27s3.54-1,5.14.66L59.58,54.93c1.6,1.64,1.89,4,.65,5.28"
        transform="translate(-2 -2)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="e1c05a76-b5e7-45ef-bd5f-4ca469d31088"
        data-name="Rounded Rectangle 7 copy 2"
        d="M24.77,60.21c-1.24-1.27-.95-3.64.65-5.28L55.09,24.47c1.6-1.65,3.9-1.94,5.14-.67s1,3.64-.65,5.28L29.91,59.54c-1.6,1.65-3.9,1.94-5.14.67"
        transform="translate(-2 -2)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  </button>`,
})
export class CloseSurveyComponent {
  public readonly store = inject(Store<SurveyState>);

  public closeSurvey() {
    this.store.dispatch(SurveyPageActions.submitSurvey({ closeSurveyPage: true }));
  }
}
