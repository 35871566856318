import { createActionGroup, props } from "@ngrx/store";
import { Dish } from "@menufy/models";

export interface SetSelectedDishActionProps {
  dish: Dish | null;
}

export const DishPageActions = createActionGroup({
  source: "Dish/Page",
  events: {
    openDishPopup: props<SetSelectedDishActionProps>(),
    setSelectedDish: props<SetSelectedDishActionProps>(),
  },
});
