import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "vegetarian-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="edb04c39-7d33-4a61-be42-f470e5d67bbd"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#006c47" />
    <path
      d="M32.7,21.69C23.45,45.56,28.22,68.33,55.08,74.3c4-15.71-10.74-28-10.74-28,9.75,4.38,17.11,13,17.3,23.57C65.92,68.73,70,61.17,66,50,60.32,34.33,38.07,31.43,32.7,21.68Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class VegetarianIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
