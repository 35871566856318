import { businessInitialState, BusinessState } from "../business.state";
import {
  GetBusinessFailedActionProps,
  GetBusinessSuccessActionProps,
} from "../actions/business-api.actions";

const getBusinessSuccessReducer = (state: BusinessState, action: GetBusinessSuccessActionProps) => {
  const { business } = action;

  state.business = business;
  state.error = null;
};

const getBusinessFailedReducer = (state: BusinessState, action: GetBusinessFailedActionProps) => {
  Object.assign(state, businessInitialState);
  state.error = action.error;
};

export default {
  getBusinessSuccessReducer,
  getBusinessFailedReducer,
};
