import { EMPTY_OBJECT, ObjectLiteral } from "@eqn/data-types";
import { AccountRole } from "../constants/account.constants";
import { Action } from "../constants/action.constants";
import { EntityIdField } from "../entity";

export interface Permission extends EntityIdField {
  action: Action;
  subject: string;
  conditions?: ObjectLiteral | typeof EMPTY_OBJECT;
}

export interface Role extends EntityIdField {
  name: AccountRole;
  permissions: Permission[];
  id: string;
}
