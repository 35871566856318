import {
  ALLERGEN_LIST,
  DIET_LIST,
  Dish,
  PreferenceLabel,
  PreferenceLabelType,
} from "@menufy/models";
import { cloneDeep, sortBy } from "lodash-es";

export function sortDishLabelsUsingLabelType<T extends PreferenceLabel | string>(
  labels: T[],
  labelType: PreferenceLabelType
) {
  if (!labels) {
    return [];
  }

  const SORTER = labelType === PreferenceLabelType.ALLERGEN ? ALLERGEN_LIST : DIET_LIST;

  return sortBy(labels, (label) => {
    const labelName = typeof label === "string" ? label : label.name;

    return SORTER.indexOf(labelName);
  });
}

export function sortDishLabels(dish: Dish): Dish {
  const clonedDish = cloneDeep(dish);

  // sort dish allergens
  clonedDish.allergens = sortDishLabelsUsingLabelType(
    clonedDish.allergens,
    PreferenceLabelType.ALLERGEN
  );

  // sort dish diets
  clonedDish.diets = sortDishLabelsUsingLabelType(clonedDish.diets, PreferenceLabelType.DIET);

  // sort dish option items labels
  clonedDish?.options?.forEach((option) => {
    option.items.forEach((item) => {
      item.allergens = sortDishLabelsUsingLabelType(item.allergens, PreferenceLabelType.ALLERGEN);
      item.diets = sortDishLabelsUsingLabelType(item.diets, PreferenceLabelType.DIET);
    });
  });

  return clonedDish;
}
