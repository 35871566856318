import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { GetSurveyResponse } from "../interfaces/get-survey-response";
import { SubmittedSurveyApiResponse, SubmittedSurveyResponse } from "../interfaces/submit-survey";
import { Survey } from "../interfaces/survey";
import { ObjectUtil } from "@eqn/data-types";

@Injectable({
  providedIn: "root",
})
export class SurveyApiService {
  private readonly surveyApiBaseUrl = `${environment.apiBaseUrl}/surveys`;

  constructor(private readonly _http: HttpClient) {}

  public getSurvey(): Observable<GetSurveyResponse> {
    const url = `${this.surveyApiBaseUrl}/published`;

    return this._http.get<Survey>(url).pipe(
      map((survey) => {
        return {
          survey,
          canParticipate: ObjectUtil.isObject(survey),
        };
      })
    );
  }

  public submitResponse(survey: SubmittedSurveyResponse): Observable<SubmittedSurveyApiResponse> {
    const url = `${this.surveyApiBaseUrl}/${survey.id}/responses`;

    return this._http.post<SubmittedSurveyApiResponse>(url, survey);
  }
}
