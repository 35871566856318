<header class="survey-header-wrapper">
  <menufy-web-close-survey />

  @if (survey() && !isSurveyCompleted()) {

  <h1><span>{{ survey()?.name }}</span> {{ 'survey.name_suffix' | translate }}</h1>

  <p [innerText]="survey()?.description"></p>

  }
</header>
