import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { ApplicationConfig } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import "hammerjs";
import appRoutes from "./routes";

import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { provideEffects } from "@ngrx/effects";
import { provideState, provideStore } from "@ngrx/store";
import { environment } from "../environments/environment";
import { BrandingEffects, BusinessEffects, businessSlice } from "./business/state";
import { MenuEffects, menuSlice } from "./menus/state";
import { dishSlice } from "./dishes/state";
import { PreferencesEffects, preferencesSlice } from "./personalized-dining/state";
import { AppPopupEffects, appPopupSlice } from "./store";
import { SurveyEffects, surveySlice } from "./survey/state";
import { LOCALE, LocaleHeaderInterceptor, provideLocale } from "@eqn/angular-i18n";

export const appConfig: ApplicationConfig = {
  providers: [
    MatBottomSheet,
    provideAnimations(),
    provideHttpClient(withInterceptors([LocaleHeaderInterceptor])),
    provideRouter(appRoutes),
    provideStore(),
    provideState(appPopupSlice),
    provideState(businessSlice),
    provideState(menuSlice),
    provideState(dishSlice),
    provideState(preferencesSlice),
    provideState(surveySlice),
    provideEffects(
      AppPopupEffects,
      BusinessEffects,
      BrandingEffects,
      MenuEffects,
      PreferencesEffects,
      SurveyEffects
    ),
    provideLocale({
      currencyLocale: LOCALE.es.langId,
      availableLanguages: [LOCALE.es],
      automaticTranslations: Object.values(LOCALE).filter(
        (locale) => locale.langId !== LOCALE.es.langId
      ),
    }),
    environment?.providers || [],
  ],
};
