import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { filter, map, of } from "rxjs";
import { catchError, exhaustMap } from "rxjs/operators";
import { BusinessPageActions } from "../actions/business-page.actions";
import { BusinessApiService } from "../../services/business-api.service";
import { BusinessApiActions } from "../actions/business-api.actions";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { selectBusiness } from "../business.selectors";
import { isObject } from "lodash-es";
import { BusinessState } from "../business.state";

@Injectable()
export class BusinessEffects {
  private readonly _langChange$ = createEffect(() => {
    return this._translate.onLangChange.pipe(
      concatLatestFrom(() => this._store.select(selectBusiness)),
      filter(([, business]) => isObject(business)),
      map(([, business]) =>
        BusinessPageActions.getBusiness({ by: "handle", value: business?.handle || "" })
      )
    );
  });

  private readonly _getBusiness$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BusinessPageActions.getBusiness),
      exhaustMap((action) =>
        this._businessApi.getBusiness(action).pipe(
          map((business) =>
            BusinessApiActions.getBusinessSuccess({
              business,
            })
          ),
          catchError((error: unknown) => of(BusinessApiActions.getBusinessFailed({ error })))
        )
      )
    )
  );

  constructor(
    private readonly _store: Store<BusinessState>,
    private readonly _actions$: Actions,
    private readonly _businessApi: BusinessApiService,
    private readonly _translate: TranslateService
  ) {}
}
