@if (menuPreferences) {
<section class="icons-guide-container pb-4" style="overflow-y: hidden">
  <div class="container">
    <div class="row title-container-shadow" swipeDownToClose (popupClosed)="submit()">
      <div class="col-12 col-md-12 text-center mt-4">
        <h3 [innerHTML]="'info_title' | translate"></h3>
      </div>
    </div>

    <div class="row px-4 pb-2" style="max-height: calc(95vh - 60px); overflow-y: auto">
      <div class="col-12 mt-4 mb-1">
        <p [innerHTML]="'info_introduction' | translate" class="mb-0" style="color: #1d1c1a"></p>
      </div>

      <!-- allergens -->
      @if (menuPreferences.selectedAllergens.length) {

      <!-- allergen info -->
      <div class="col-12 col-md-12 mt-2">
        <h2 [innerHTML]="'info_allergens_title' | translate" class="text-center mb-0"></h2>
      </div>

      <!-- allergen list -->
      @for (allergen of menuPreferences.selectedAllergens; track $index) {

      <div class="col-12 col-md-12 pe-0 pe-md-1">
        <preferences-image [label]="allergen" />
        <preferences-image [label]="allergen" isOptional />
      </div>
      } }

      <!-- Diet -->
      @if (menuPreferences.selectedDiets.length) {
      <!-- diet information -->
      <div class="col-12 col-md-12 mt-3">
        <h2 [innerHTML]="'info_diets_title' | translate" class="text-center mb-0"></h2>
      </div>

      <!-- diet list -->
      @for (diet of menuPreferences.selectedDiets; track $index) {
      <div class="col-12 col-md-12 pe-0 pe-md-1">
        <preferences-image [label]="diet" />
        <preferences-image [label]="diet" isOptional />
      </div>
      } }

      <!-- inform staff -->
      <div class="col-12 mt-3 mb-1">
        <p [innerHTML]="'info_inform_staff' | translate"></p>
      </div>

      <!-- action buttons -->
      <div class="col-12 col-md-12 mt-3">
        <section class="d-flex flex-column align-items-center">
          <button
            (click)="backToPrefs()"
            class="fs-1 p-4 fw-bold w-75 mb-3 secondary-btn"
            mat-flat-button
            type="button"
          >
            {{ "info_back_btn" | translate }}
          </button>
          <button
            (click)="submit()"
            class="fs-1 p-4 fw-bold w-75 mb-2 primary-btn"
            mat-flat-button
            type="submit"
          >
            {{ "info_confirm_btn" | translate }}
          </button>
        </section>
      </div>
    </div>
  </div>

  <!-- Close icon -->
  <close-icon class="close-icon" (click)="submit()" swipeDownToClose />

  <!-- border -->
  <div class="popup-top-border-wrapper" swipeDownToClose>
    <span></span>
  </div>
</section>
}
