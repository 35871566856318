import { DishState } from "../dish.state";
import { SetSelectedDishActionProps } from "../actions/dish-page.actions";

const setSelectedDishReducer = (state: DishState, action: SetSelectedDishActionProps) => {
  const { dish } = action;

  state.dish = dish;
  state.error = null;
};

export default {
  setSelectedDishReducer,
};
