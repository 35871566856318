import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "info-icon-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="b130bbee-f005-4744-bead-8edd2b1f5c69"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 501.77 501.73"
  >
    <path
      d="M256.86,5.14C118.32,4.89,5.25,117.31,5.11,255.43,5,393.92,117.45,506.76,255.73,506.86c138.12.1,251-112.4,251.15-250.31C507,118.31,394.73,5.38,256.86,5.13Zm-1.43,463.13c-116.56-.14-211.74-95.63-211.72-212.39,0-116.94,95.47-212.21,212.55-212.15s212.12,95.54,212,212.67c-.1,116.86-95.69,212-212.85,211.87Z"
      transform="translate(-5.11 -5.13)"
      fill="#989898"
    />
    <path
      d="M256,211.22c-11.43,0-19.22,8.36-19.24,20.78q0,31.32,0,62.63t0,62.63c0,12.33,7.9,20.72,19.32,20.71s19.25-8.39,19.26-20.78q0-62.64,0-125.27c0-12.33-7.9-20.71-19.33-20.71Zm-.29-77.87c-10.81.29-20.38,9.8-20.16,20s10.14,19.48,20.82,19.32,20.36-9.72,20.18-20-10.32-19.68-20.83-19.39Z"
      transform="translate(-5.11 -5.13)"
      fill="#989898"
    />
  </svg>`,
})
export class InfoIconIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
