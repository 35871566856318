import {
  GetSurveyFailedActionProps,
  SubmitSurveyFailedActionProps,
} from "../actions/survey-api.actions";
import { SurveyState } from "../survey.state";

const getSurveyFailedReducer = (state: SurveyState, action: GetSurveyFailedActionProps) => {
  state.error = action.error;
};

const submitSurveySuccessReducer = (state: SurveyState) => {
  state.survey = null;
  state.isCompleted = true;
  state.canParticipate = false;
};

const submitSurveyFailedReducer = (state: SurveyState, action: SubmitSurveyFailedActionProps) => {
  state.error = action.error;
};

export default {
  getSurveyFailedReducer,
  submitSurveySuccessReducer,
  submitSurveyFailedReducer,
};
