import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "spicy-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="e1069894-234c-4e2a-96ee-c03f6b245f2e"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 457.51 498.76"
  >
    <path
      d="M314.87,454.9c6.06-2.11,12.49-1.85,18.73-2.91,28.62-4.85,55.71-13.86,80.45-29.32,9.56-6,18.45-12.79,24.36-22.75,0,17.16-.09,34.32.09,51.47,0,3-.6,3.59-3.57,3.58q-60-.18-120-.08Z"
      transform="translate(-27.24 -6.62)"
      fill="#fefefe"
    />
    <path
      d="M466.47,426.86a437.75,437.75,0,0,1-80.87,4.35c-24.58-1.08-48.84-4.39-71.91-13.61-21-8.41-39.19-20.75-49.11-41.94-15.21-32.47-25.91-66.38-27.14-102.56a203.76,203.76,0,0,0-13.82-68.21c-5.32-13.56-10.85-27-21-38-11.9,4.81-23.69,2.43-35.43-.63a93.81,93.81,0,0,1-18.05-6.74c-2.17-1.08-3.34-1-5.05,1.16-12.2,15.48-25.55,17.86-42.55,7.88-1.25-.74-2.77-1.28-3.66-2.35-6.77-8.14-13.42-6.21-21.18-1A72.92,72.92,0,0,1,54,175.09c-3.92,1-7.79,1.23-11.57-.59.1-.22.21-.42.31-.64l-.38,0c-5.92,4.3-6.83,11.25-8.67,17.48-8,27.18-7.86,55.21-3.19,82.48,11.23,65.59,40.69,122,91.24,166,38,33,82.73,52.35,131.75,62.26,10.4,2.1,21,1.85,31.44,3.3h45a2.53,2.53,0,0,1,1.68-.64c19.5.06,38.38-3.8,57-9.26,27.09-8,53-18.62,75.84-35.57,8.44-6.26,17.51-12.22,20.39-23.45v-6.27c-5.46-4.61-11.88-4.24-18.29-3.33ZM217.69,274.36c-2.85,6.6-7.88,8.43-14.15,5.09a28.33,28.33,0,0,1-9-7.63c-13.36-16.89-20.45-36.13-20.67-57.68-.07-6.84.28-14.84,8-17.56s12.86,3.3,17.23,8.57c13.35,16.11,19.45,35.08,20.95,55.71a33,33,0,0,1-2.34,13.5Z"
      transform="translate(-27.24 -6.62)"
      fill="#fd0000"
    />
    <path
      d="M54,175.08a72.92,72.92,0,0,0,22.78-9.84c7.76-5.21,14.41-7.14,21.18,1,.88,1.06,2.4,1.61,3.66,2.35,17,10,30.35,7.6,42.55-7.88,1.71-2.17,2.88-2.24,5.05-1.16a93.81,93.81,0,0,0,18,6.74c11.74,3.07,23.53,5.44,35.43.63-2.92-4.66-7.09-8.11-11.21-11.59-14.46-12.21-31.53-20-48.33-28.1-5.92-2.86-8.77-7-9.77-13.28-1.2-7.49-1.07-15-.85-22.47.72-24.15-7-45.45-21.21-64.57C106.12,20,100.47,13.37,95,6.62H90L88.05,8c-7.83,5.39-8.32,10-1.9,17a146.56,146.56,0,0,1,16.61,21.86,88.24,88.24,0,0,1,13.51,51.77c-.82,17.48-5.54,23.78-21.93,29.45-23.48,8.13-41.07,23-51.65,45.71-.1.22-.21.42-.31.64,3.77,1.82,7.64,1.58,11.57.59Z"
      transform="translate(-27.24 -6.62)"
      fill="#098c00"
    />
    <path
      d="M181.86,196.59c-7.69,2.72-8,10.71-8,17.56.21,21.55,7.31,40.79,20.67,57.68a28.81,28.81,0,0,0,9,7.63c6.27,3.33,11.29,1.51,14.15-5.09a32.94,32.94,0,0,0,2.34-13.5c-1.5-20.63-7.59-39.6-20.95-55.71-4.37-5.27-9.58-11.27-17.23-8.57Z"
      transform="translate(-27.24 -6.62)"
      fill="#fefefe"
    />
  </svg>`,
})
export class SpicyIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
