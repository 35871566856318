import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "rate-us-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="fe907acd-4357-4b5a-8e2e-7c0249b9acc5"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 446.98 427.43"
  >
    <path
      d="M476.79,197.18a29.18,29.18,0,0,1-.2,24.95c-4.91,10-12.66,17.67-21.62,24.71-23.58,18.53-46.48,37.93-70,56.51C379,308,377.4,312,380,319.43c11.17,32.27,21.72,64.77,32.36,97.22,6.24,19,2.43,34.28-10.78,44.61-13.57,10.61-29.94,10.58-46.69-.29-30.58-19.82-61.19-39.59-91.56-59.72-5.35-3.54-8.93-3.79-14.44,0-29.81,20.4-59.92,40.34-90,60.32-16.11,10.7-33.21,10.82-46.76.67-13.32-10-17.41-25.36-11.51-44.4,10.39-33.55,20.79-67.1,31.53-100.54,1.86-5.78.91-9.13-3.92-13q-39.79-31.57-79-63.82c-15.27-12.47-20-27.67-14.24-43.59s19-24.24,38.93-24.27c32.91,0,65.81-.19,98.72.14,6.52.07,9.62-1.81,11.89-8.12q17-47.34,35.14-94.27c2.6-6.7,6.53-13.64,11.73-18.44,19.39-17.89,49.28-10,59.16,15.58,12.45,32.18,24.37,64.56,36.11,97,2.24,6.18,5.18,8.33,11.82,8.25,31-.37,62,.62,92.9-.42,21.43-.72,36.94,6,45.51,24.81Z"
      transform="translate(-32.52 -42.28)"
    />
  </svg>`,
})
export class RateUsIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
