import { DestroyRef, inject, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Pipe({
  name: "socialNetworkTranslate",
  standalone: true,
  pure: false,
})
export class SocialNetworkTranslatePipe implements PipeTransform {
  private _memoizedValue = "";

  private _memoizedType?: string;

  private readonly _destroyContext = inject(DestroyRef);

  constructor(private readonly _translate: TranslateService) {}

  transform(name: string, type: "ratingPlatform" | "socialNetwork"): string {
    if (this._memoizedType || !name) {
      return this._memoizedValue;
    }

    this._memoizedType = type;

    const translationKeyPrefix = type === "socialNetwork" ? "followus_" : "rateus_";
    const translationKey = `${translationKeyPrefix}${name}`;

    this._translate
      .get(translationKey.toLowerCase())
      .pipe(takeUntilDestroyed(this._destroyContext))
      .subscribe((translation) => {
        this._memoizedValue = translation;
      });

    return this._memoizedValue;
  }
}
