import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "excerpt",
  standalone: true,
})
export class ExcerptPipe implements PipeTransform {
  transform(value: string, limit: number, completeWords = true, suffix = "..."): string {
    if (value.length <= limit) {
      return value;
    }

    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(" ");
    }

    return `${value.substring(0, limit)}${suffix}`;
  }
}
