import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tripadvisor-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f05b9a5d-c6e4-4fdc-993c-1b0d90d7a66a"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 507.13 507.07"
  >
    <path
      d="M255.88,2.47c-140,0-253.52,113.61-253.44,253.64S115.93,509.54,256.08,509.54,509.56,396,509.57,255.92,396,2.45,255.89,2.47Zm161,336.9c-32.11,33.47-89.86,41.29-131.29,4.37L256,375.83l-29.72-32.19c-41.44,36.82-99.89,30-132.58-5.85-32.4-35.57-34.14-93.66,5.88-132L70.13,173.63c2-1.5,3.69-.92,5.26-.93,19.17,0,38.34-.08,57.51,0a14.43,14.43,0,0,0,7.9-2.34q115.23-69.49,230.53,0a14.52,14.52,0,0,0,7.9,2.35c20.64-.1,41.27,0,63.51,0l-30.19,32.95c42.24,39.68,36.17,100.59,4.36,133.74Z"
      transform="translate(-2.44 -2.47)"
      fill="#33de9f"
    />
    <path
      d="M412.56,205.63l30.19-32.95c-22.24,0-42.88-.05-63.51,0a14.44,14.44,0,0,1-7.9-2.35q-115.24-69.37-230.53,0a14.48,14.48,0,0,1-7.9,2.34c-19.17-.1-38.34-.07-57.51,0-1.57,0-3.29-.58-5.26.93l29.44,32.19c-40,38.31-38.28,96.4-5.88,132,32.69,35.88,91.14,42.67,132.58,5.85L256,375.83l29.64-32.09c41.43,36.92,99.17,29.1,131.29-4.37,31.81-33.15,37.88-94.05-4.36-133.74ZM162.45,338.09a63.15,63.15,0,1,1,63.28-63.16,63.25,63.25,0,0,1-63.28,63.16ZM278.89,213.58a94.62,94.62,0,0,0-22.71,52.33c-6.68-42.74-32-68.62-69.92-85.45,46.81-18.25,92.43-18.3,139.72.1-19.05,7.89-34.61,18.36-47.09,33m70.95,124.51c-34.48.5-63.28-27.72-63.61-62.34A63.06,63.06,0,0,1,348.7,211.8c34.49-.46,63.34,27.76,63.69,62.31a63.28,63.28,0,0,1-62.55,64"
      transform="translate(-2.44 -2.47)"
    />
    <path
      d="M348.7,211.79a63.06,63.06,0,0,0-62.47,64c.34,34.62,29.13,62.84,63.61,62.34a63.29,63.29,0,0,0,62.55-64c-.35-34.54-29.2-62.77-63.69-62.31m.57,96.29a33.15,33.15,0,1,1,33.12-33.53,33.05,33.05,0,0,1-33.12,33.53ZM162.94,211.79a63.15,63.15,0,1,0,62.79,63.12,63.19,63.19,0,0,0-62.79-63.12Zm0,96.29a33.16,33.16,0,1,1,32.76-33.31A33.23,33.23,0,0,1,163,308.08m23.3-127.63c38,16.83,63.24,42.71,69.92,85.45a94.69,94.69,0,0,1,22.71-52.33c12.48-14.66,28-25.12,47.09-33-47.29-18.4-92.91-18.36-139.72-.1"
      transform="translate(-2.44 -2.47)"
      fill="#33de9f"
    />
    <path
      d="M349,241.78a33.15,33.15,0,1,0,33.34,32.77A33,33,0,0,0,349,241.78Zm-186.35,0a33.16,33.16,0,1,0,33,33,33.18,33.18,0,0,0-33-33"
      transform="translate(-2.44 -2.47)"
    />
  </svg>`,
})
export class TripadvisorIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
