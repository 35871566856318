import { menuFeature } from "./menu.slice";
import { createSelector } from "@ngrx/store";
import { selectPreferencesState } from "../../personalized-dining/state";
import { applyMenuPreferencesFilters } from "@diners/common";

export const selectFilteredMenu = createSelector(
  menuFeature.selectMenus,
  selectPreferencesState,
  (menus, prefs) => applyMenuPreferencesFilters(menus, prefs)
);

export const { selectMenus, selectCursor, selectMenuHasImages, selectMenuState } = menuFeature;
