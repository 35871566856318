import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Business } from "@menufy/models";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { FindBusinessParams } from "../interfaces/find-business";

@Injectable({
  providedIn: "root",
})
export class BusinessApiService {
  private readonly _businessApiBaseUrl = `${environment.apiBaseUrl}/businesses`;

  constructor(private readonly _http: HttpClient) {}

  public getBusiness(params: FindBusinessParams): Observable<Business> {
    switch (params.by) {
      case "handle":
        return this.getBusinessByHandle(params.value);
    }
  }

  public getBusinessByHandle(handle: string): Observable<Business> {
    const url = `${this._businessApiBaseUrl}/@${handle}`;

    return this._http.get<Business>(url);
  }
}
