export type LOCALE_LANG_ID = "es" | "en" | "fr" | "it";

export interface TranslationLanguage {
  name: string;
  langId: LOCALE_LANG_ID | string;
  localeId?: string;
}

export const LOCALE: Record<LOCALE_LANG_ID, TranslationLanguage> = {
  es: { name: "Español", langId: "es", localeId: "es-ES" },
  en: { name: "English", langId: "en", localeId: "en-US" },
  fr: { name: "Français", langId: "fr", localeId: "fr-FR" },
  it: { name: "Italiano", langId: "it", localeId: "it-IT" },
};

export const LOCALES_LANG_IDS = Object.keys(LOCALE) as LOCALE_LANG_ID[];

export const FALLBACK_LOCALE = LOCALE.en;

export const FALLBACK_LANG_ID = FALLBACK_LOCALE.langId;
