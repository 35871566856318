import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "facebook-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="eec99cb6-a3a0-41ed-b272-55679a9a58b4"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 480.56 477.51"
  >
    <path
      d="M293.54,326.06h55.54c3.55-23.1,7.06-46,10.66-69.43H294.42c-1.76-1.79-1.24-3.45-1.24-4.95,0-13.52-.09-27,0-40.56.14-22.56,12.22-35.61,34.66-37,9.71-.59,19.48-.29,29.22-.44,1.69,0,3.45.4,5.34-.61V114.44c-7.79-1-15.36-2.12-23-2.82-17.38-1.6-34.79-3-52.16.19-37.3,6.89-61.7,32.17-67.6,69.73-2.7,17.21-1.39,34.51-1.69,51.78-.13,7.5,0,15,0,22.92H157.31v69.48h60.28V493.4c-99.22-13-200.72-103.11-202.28-233.62C13.7,127.4,117.9,19.27,248.83,16.08c132-3.22,242,99.37,246.85,230.83,4.93,133-94.66,231.51-202,246.61-.68-3.53-.91-160.33-.17-167.45Z"
      transform="translate(-15.29 -16.01)"
      fill="#1876f1"
    />
  </svg>`,
})
export class FacebookIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
