import { HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export function LocaleHeaderInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
  if (request.url.startsWith("/assets")) {
    return next(request);
  }

  const locale = inject(TranslateService).currentLang;

  const requestWithLocaleHeader = request.clone({
    setHeaders: {
      "Accept-Language": locale,
    },
  });

  return next(requestWithLocaleHeader);
}
