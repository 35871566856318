import { Component } from "@angular/core";

import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { PopupEvent, SwipeDownToCloseDirective } from "@diners/common";
import { DIET_LIST, PreferenceLabelType } from "@menufy/models";
import { SlideToggleComponent } from "@menufy-web/design-library";
import { CloseIconComponent } from "@menufy/icons";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MenuPreferencesFilters } from "../../menus/interfaces/prefs-filters";
import { AppPopupActions } from "../../store/actions/app-popup.actions";
import { PrefsActions, selectPreferencesState } from "../state";
import { PreferencesState } from "../state/prefs.state";
import {
  PreferenceLabelCheckbox,
  PreferenceLabelCheckboxEvent,
  PreferencesCheckbox,
} from "./preferences-checkbox/preferences-checkbox";

const ALLERGEN_CHECKBOX_LIST = [
  { name: "gluten", checked: false },
  { name: "milk", checked: false },
  { name: "soy", checked: false },
  { name: "eggs", checked: false },
  { name: "nuts", checked: false },
  { name: "fish", checked: false },
  { name: "peanuts", checked: false },
  { name: "crustaceans", checked: false },
  { name: "sesame", checked: false },
  { name: "molluscs", checked: false },
  { name: "celery", checked: false },
  { name: "mustard", checked: false },
  { name: "lupins", checked: false },
  { name: "sulphites", checked: false },
];

const DIET_CHECKBOX_LIST = DIET_LIST.map((diet) => ({ name: diet, checked: false }));

@Component({
  selector: "menufy-web-preferences",
  standalone: true,
  imports: [
    MatButtonModule,
    MatDividerModule,
    SwipeDownToCloseDirective,
    PreferencesCheckbox,
    FormsModule,
    TranslateModule,
    SlideToggleComponent,
    CloseIconComponent,
  ],
  templateUrl: "./preferences.component.html",
  styleUrls: ["./preferences.component.scss"],
})
export class PreferencesComponent {
  public allergens: PreferenceLabelCheckbox[] = ALLERGEN_CHECKBOX_LIST;
  public diets: PreferenceLabelCheckbox[] = DIET_CHECKBOX_LIST;

  public isFilterOn = true;

  public LabelType = PreferenceLabelType;

  constructor(
    private readonly _bottomSheetRef: MatBottomSheetRef<PreferencesComponent>,
    private readonly _store: Store<PreferencesState>
  ) {
    this._store
      .select(selectPreferencesState)
      .pipe(takeUntilDestroyed())
      .subscribe((preferences) => this._setPreferences(preferences));
  }

  public submit() {
    const preferences = {
      selectedAllergens: this._getSelectedLabels(PreferenceLabelType.ALLERGEN),
      selectedDiets: this._getSelectedLabels(PreferenceLabelType.DIET),
      isFilterOn: this.isFilterOn,
    };

    this._store.dispatch(PrefsActions.setPreferences(preferences));

    const hasPreferences = preferences.selectedAllergens.length || preferences.selectedDiets.length;

    if (hasPreferences) {
      this._store.dispatch(
        AppPopupActions.openPopup({
          current: PopupEvent.PREFERENCES,
          next: PopupEvent.ALLERGENS_ALERT,
        })
      );
    }

    this.dismissPopup();
  }

  public dismissPopup() {
    this._bottomSheetRef.dismiss();
  }

  public onLabelSelectionToggle(event: PreferenceLabelCheckboxEvent) {
    const { name, checked, type } = event;

    const labelList = type === PreferenceLabelType.ALLERGEN ? this.allergens : this.diets;

    for (const label of labelList) {
      if (label.name === name) {
        label.checked = checked;
        return;
      }
    }
  }

  private _getSelectedLabels(type: PreferenceLabelType) {
    const labelsList = type === PreferenceLabelType.ALLERGEN ? this.allergens : this.diets;

    return labelsList.filter((label) => label.checked).map((label) => label.name);
  }

  private _setPreferences(preferences: MenuPreferencesFilters) {
    this.allergens.forEach((allergen) => {
      allergen.checked = !!preferences.selectedAllergens.find((a) => a === allergen.name);
    });

    this.diets.forEach((diet) => {
      diet.checked = !!preferences.selectedDiets.find((d) => d === diet.name);
    });

    this.isFilterOn = preferences.isFilterOn;
  }
}
