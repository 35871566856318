import { Nullable } from "@eqn/data-types";
import { Business } from "@menufy/models";

export interface BusinessState {
  business: Nullable<Business>;
  error: unknown;
}

export const businessInitialState: Readonly<BusinessState> = {
  business: null,
  error: null,
};
