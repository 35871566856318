import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Menu, PaginationResult } from "@menufy/models";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ListMenusParams } from "../interfaces/find-menus";
import { MENUS_DEFAULT_PAGE_LIMIT } from "../state/menu.state";

@Injectable({
  providedIn: "root",
})
export class MenusApiService {
  private readonly _businessApiBaseUrl = `${environment.apiBaseUrl}/businesses`;

  constructor(private readonly _http: HttpClient) {}

  public getMenusByBusiness(params: ListMenusParams): Observable<PaginationResult<Menu>> {
    const urlParams = new URLSearchParams();

    const limit = `${params.limit || MENUS_DEFAULT_PAGE_LIMIT}`;

    urlParams.append("limit", limit);

    if (params.before) {
      urlParams.append("before", params.before);
    } else if (params.after) {
      urlParams.append("after", params.after);
    }

    const url = `${this._businessApiBaseUrl}/${params.business}/menus?${urlParams.toString()}`;

    return this._http.get<PaginationResult<Menu>>(url);
  }
}
