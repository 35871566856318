import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "molluscs-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="e8285d56-b774-474e-837c-9f7e10f197be"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84Z"
      transform="translate(-3.41 -16.46)"
      fill="#0092bc"
    />
    <path
      d="M68.6,36.8s2.52-5.58-2.53-8.81c-5.39-3.44-9.7,2-9.7,2s-.66-6.58-8.37-6.58S39.63,30,39.63,30s-4.32-5.42-9.7-2c-5.05,3.23-2.53,8.81-2.53,8.81-2.65-3.75-10.86-.79-8.35,6.61,1.34,4,8.46,13.2,29,22.19,20.49-9,27.61-18.23,29-22.19,2.51-7.4-5.7-10.36-8.35-6.61"
      transform="translate(-3.41 -16.46)"
      fill="#0092bc"
    />
    <path
      d="M62.93,63v6.12a3.48,3.48,0,0,1-3.48,3.48H36.56a3.48,3.48,0,0,1-3.48-3.48V63a111.44,111.44,0,0,0,13.1,6.74l1.83.8,1.83-.8A111.44,111.44,0,0,0,62.94,63Z"
      transform="translate(-3.41 -16.46)"
      fill="#0092bc"
    />
  </svg>`,
})
export class MolluscsOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
