import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs";
import { PrefsActions } from "../actions";
import { sortDishLabelsUsingLabelType } from "@diners/common";
import { PreferenceLabelType } from "@menufy/models";
import { DinersPlausibleEvent, plausibleTrackEvent } from "@menufy/plausible";

@Injectable()
export class PreferencesEffects {
  private readonly _sendPlausibleEvents$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(PrefsActions.setPreferences),
        tap((action) => {
          // send data to plausible for analytics

          const sortedAllergens = sortDishLabelsUsingLabelType(
            action.selectedAllergens,
            PreferenceLabelType.ALLERGEN
          );

          const sortedDiets = sortDishLabelsUsingLabelType(
            action.selectedDiets,
            PreferenceLabelType.DIET
          );

          const labels = sortedAllergens.concat(sortedDiets).join(",");

          plausibleTrackEvent(DinersPlausibleEvent.COMBINED_PREFERENCES, {
            props: { preferences: labels },
          });

          action.selectedAllergens.forEach((allergen) => {
            plausibleTrackEvent(DinersPlausibleEvent.ALLERGEN, { props: { allergen } });
          });

          action.selectedDiets.forEach((diet) => {
            plausibleTrackEvent(DinersPlausibleEvent.DIET, { props: { diet } });
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(private readonly _actions$: Actions) {}
}
