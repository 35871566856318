import { NULL } from "@eqn/data-types";
import { isEmpty, uniq } from "lodash-es";
import {
  SurveyAnswer,
  SurveyAnswerOptionKind,
  SurveyMultiSelectQuestion,
  SurveyQuestionType,
  SurveyYesNoKind,
  SurveyYesNoQuestion,
} from "../../interfaces/survey";
import { SurveyVM } from "../../interfaces/survey-vm";
import { InitializeSurveyActionProps, UpdateSurveyAnswerActionProps } from "../actions";
import { SurveyState } from "../survey.state";

const initializeSurveyReducer = (state: SurveyState, action: InitializeSurveyActionProps) => {
  const { survey, canParticipate } = action;

  state.canParticipate = canParticipate;
  state.error = null;
  state.isStarted = false;
  state.isCompleted = false;

  if (!state.isInitialized) {
    state.isInitialized = true;
  }

  if (!canParticipate) {
    return;
  }

  state.survey = {
    ...survey,
    startedAt: NULL,
    endedAt: NULL,
    business: NULL,
    allergens: [],
    diets: [],
    isCompleted: false,
    initializedAt: new Date().toISOString(),
  };

  state.stepper = {
    steps: survey.questions.length ? survey.questions.length - 1 : 0,
    selectedStepIdx: 0,
  };
};

const surveyStartedReducer = (state: SurveyState) => {
  state.isStarted = true;
  state.survey = {
    ...((state.survey || {}) as SurveyVM),
    startedAt: new Date().toISOString(),
  };
};

const updateSurveyAnswerReducer = (state: SurveyState, action: UpdateSurveyAnswerActionProps) => {
  const { questionId, answer, isRemoved } = action;

  if (!state.survey?.questions || !answer.kind) {
    return;
  }

  const questionIdx = state.survey.questions.findIndex(({ id }) => questionId === id);

  if (questionIdx < 0 || isEmpty(answer)) {
    return;
  }

  const question = state.survey.questions[questionIdx];

  switch (question.type) {
    case SurveyQuestionType.RATING:
    case SurveyQuestionType.TEXT: {
      Object.assign(question, { answer });
      break;
    }

    case SurveyQuestionType.YES_NO: {
      const yesNoQues = question as SurveyYesNoQuestion;
      let answers = yesNoQues.answer || [];

      if (isRemoved || isEmpty(answer.value)) {
        answers = answers.filter((ans) => ans.kind !== answer.kind);
      } else if (answer.kind === SurveyAnswerOptionKind.OTHER) {
        const otherAns = answers.find((ans) => ans.kind === answer.kind);

        if (otherAns) {
          otherAns.value = answer.value as string;
        } else {
          answers.push(answer as SurveyAnswer<SurveyAnswerOptionKind.OTHER, string>);
        }
      } else {
        const yesNoAns = answers.find((ans) => ans.kind !== SurveyAnswerOptionKind.OTHER);

        if (yesNoAns) {
          yesNoAns.kind = answer.kind as SurveyYesNoKind;
          yesNoAns.value = answer.value as string;
        } else {
          answers.push(answer as SurveyAnswer<SurveyYesNoKind, string>);
        }
      }

      yesNoQues.answer = uniq(answers);

      break;
    }

    case SurveyQuestionType.MULTI_SELECT: {
      const multiSelectQues = question as SurveyMultiSelectQuestion;
      let answers = multiSelectQues.answer || [];

      if (isRemoved || isEmpty(answer.value)) {
        answers = answers.filter((ans) => ans.kind !== answer.kind);
      } else if (answer.kind === SurveyAnswerOptionKind.OTHER) {
        const otherAns = answers.find((ans) => ans.kind === answer.kind);

        if (otherAns) {
          otherAns.value = answer.value as string;
        } else {
          answers.push(answer as SurveyAnswer<SurveyAnswerOptionKind.OTHER, string>);
        }
      } else if (answer.kind === SurveyAnswerOptionKind.OPTION) {
        answers.push(answer as SurveyAnswer<SurveyAnswerOptionKind.OPTION, string>);
      }

      multiSelectQues.answer = uniq(answers);

      break;
    }
  }

  const startedAt = question.startedAt || new Date().toISOString();
  const endedAt = new Date().toISOString();

  Object.assign(question, { startedAt, endedAt });

  state.survey.questions[questionIdx] = question;
};

export default {
  initializeSurveyReducer,
  surveyStartedReducer,
  updateSurveyAnswerReducer,
};
