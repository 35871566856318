import { registerLocaleData } from "@angular/common";
import { importProvidersFrom, LOCALE_ID, Provider } from "@angular/core";
import localeIt from "@angular/common/locales/it";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LOCALE } from "./locale.config";
import { LOCALE_SERVICE_CFG, LocaleService, LocaleServiceOptions } from "./locale.service";

import { provideCurrencyLocale } from "@eqn/angular-pipes";

registerLocaleData(localeEs, LOCALE.es.langId);
registerLocaleData(localeFr, LOCALE.fr.langId);
registerLocaleData(localeIt, LOCALE.it.langId);

const provideTranslateModule = () =>
  importProvidersFrom(
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
    })
  );

const provideDefaultLocale = (): Provider => {
  return {
    provide: LOCALE_ID,
    useFactory: (localeService: LocaleService) => localeService.getDefaultLocale().langId,
    deps: [LocaleService],
  };
};

export const provideLocaleServiceConfig = (config: LocaleServiceOptions): Provider => {
  return {
    provide: LOCALE_SERVICE_CFG,
    useValue: config,
  };
};

export interface LocaleConfig extends LocaleServiceOptions {
  currencyLocale: string;
}

export const provideLocale = (config: LocaleConfig): Provider => [
  provideDefaultLocale(),
  provideCurrencyLocale(config.currencyLocale),
  provideTranslateModule(),
  provideLocaleServiceConfig(config),
];
