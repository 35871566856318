import { createFeature, createReducer } from "@ngrx/store";
import { immerOn as on } from "ngrx-immer/store";
import { SurveyApiActions } from "./actions/survey-api.actions";
import { SurveyPageActions } from "./actions/survey-page.actions";
import surveyApiReducers from "./reducers/survey-api.reducers";
import surveyPageReducers from "./reducers/survey-page.reducers";
import { surveyInitialState } from "./survey.state";
import { SurveyActions } from "./actions";
import surveyReducers from "./reducers/survey.reducers";

const SURVEY_FEATURE_NAME = "survey";

export const surveyFeature = createFeature({
  name: SURVEY_FEATURE_NAME,
  reducer: createReducer(
    surveyInitialState,
    on(SurveyActions.initializeSurvey, surveyReducers.initializeSurveyReducer),
    on(SurveyActions.surveyStarted, surveyReducers.surveyStartedReducer),
    on(SurveyActions.updateSurveyAnswer, surveyReducers.updateSurveyAnswerReducer),
    on(SurveyApiActions.getSurveyFailed, surveyApiReducers.getSurveyFailedReducer),
    on(SurveyApiActions.submitSurveySuccess, surveyApiReducers.submitSurveySuccessReducer),
    on(SurveyApiActions.submitSurveyFailed, surveyApiReducers.submitSurveyFailedReducer),
    on(SurveyPageActions.setStepperStep, surveyPageReducers.setStepperStepReducer)
  ),
});

export const surveySlice = {
  name: surveyFeature.name,
  reducer: surveyFeature.reducer,
} as const;
