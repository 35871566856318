import { createFeature, createReducer, createSelector } from "@ngrx/store";
import { menuInitialState } from "./menu.state";
import { immerOn as on } from "ngrx-immer/store";
import { MenuApiActions } from "./actions/menu-api.actions";
import menuApiReducers from "./reducers/menu-api.reducers";
import { Menu, MenuItem } from "@menufy/models";

const MENU_FEATURE_NAME = "menu";

export const menuFeature = createFeature({
  name: MENU_FEATURE_NAME,
  reducer: createReducer(
    menuInitialState,
    on(MenuApiActions.getMenusSuccess, menuApiReducers.getMenusSuccessReducer),
    on(MenuApiActions.getMenusFailed, menuApiReducers.getMenusFailedReducer)
  ),
  extraSelectors: ({ selectMenus }) => ({
    selectMenuHasImages: createSelector(selectMenus, (menus) => {
      const menuItemHasImages = (menu: Menu): boolean => {
        return menu?.items?.some((menuItem) => {
          return menuItem.type === MenuItem.DISH
            ? menuItem?.images?.length
            : menuItemHasImages(menuItem);
        });
      };

      return menus.some(menuItemHasImages);
    }),
  }),
});

export const menuSlice = {
  name: menuFeature.name,
  reducer: menuFeature.reducer,
} as const;
