import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "spotify-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="b4d7ce5c-9350-4e64-8424-b18db0998acc"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <circle cx="256" cy="256" r="256" fill="#1ed560" />
    <path
      d="M218,144.56c64.39-.2,126.58,11,185.51,37.74,7,3.16,13.81,6.65,20.51,10.34,10.5,5.79,14.2,17.75,8.88,27.73-5.46,10.26-17.57,13.75-28.43,8C341.14,194.8,273,183.09,202.1,184.85c-33.31.83-65.95,6.2-97.52,17.36-11.74,4.15-23.06-1.36-26.76-12.26-3.77-11.12,1.61-21.8,13.49-26.15,31.55-11.54,64.34-17.07,97.74-19.16,9.62-.6,19.32-.09,29-.09ZM390.48,305.62c-3,.12-6.33-1-9.55-2.45C344.57,287,307,274.69,267.69,268c-47.21-8-94.21-7.21-141,3.47-4.06.93-8.09,2-12.17,2.8a20.18,20.18,0,0,1-23.82-14.75,19.93,19.93,0,0,1,13.66-24.39c15.51-4.61,31.43-7.43,47.45-9.61,81.74-11.1,159.49,4,234.7,35.68,4.76,2,9.57,3.93,14.15,6.28a20.09,20.09,0,0,1,10.09,22.86c-2.19,9-10.3,15.3-20.32,15.28ZM209.54,298.1c42.05.45,90,8.44,135.46,28.94a121,121,0,0,1,11.16,5.59,20.3,20.3,0,0,1-18.88,35.91c-53-26.11-109.22-32.92-167.38-28.18a214.59,214.59,0,0,0-34.46,5.82c-12,3-23-3.07-25.87-14.22-3-11.64,3.5-22.22,15.72-25.27,25.2-6.3,50.87-8.59,84.25-8.58Z"
      fill="#000402"
    />
  </svg>`,
})
export class SpotifyIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
