import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "follow-heart-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="b6d519e1-e6b3-42e1-a725-7a6a6a4700c6"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 494.3 449.93"
  >
    <path
      d="M418.76,30.71H93.24A84.39,84.39,0,0,0,8.85,115.1V316a84.38,84.38,0,0,0,84.39,84.38H152.5a41.67,41.67,0,0,1,32.06,15.06l42,50.6a40.51,40.51,0,0,0,62.35,0l42-50.6a41.67,41.67,0,0,1,32.06-15.06h55.84A84.38,84.38,0,0,0,503.15,316V115.1a84.39,84.39,0,0,0-84.39-84.39m-48.4,197.46c-13.93,24.65-36.34,41.36-59.15,57.05C294.4,296.77,276.55,306.83,260,317c-39.83-19.41-75.21-40.82-102.25-73.2-7.49-9-13.11-20.27-16.85-31.41-9.77-29.14-1-54,23.17-70.8,23.07-16,53.4-15.21,76.35,2.34,5.87,4.49,11.2,9.67,18.1,15.68,4.79-4.41,8.83-8.25,13-12,31-27.7,79.59-21.16,100.51,14,13.23,22.23,10.49,45-1.66,66.52"
      transform="translate(-8.85 -30.71)"
      fill="#1d1c1b"
    />
  </svg>`,
})
export class FollowHeartIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
