import { Nullable } from "@eqn/data-types";

export type PaginationQueryParams = Partial<{
  after: string;
  before: string;
  limit: number;
}>;

export interface PaginationCursor {
  next: Nullable<string>;
  previous: Nullable<string>;
}

export interface PaginationResult<T> {
  cursor: PaginationCursor;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  data: T[] | [];
  totalRecords: number;
}
