import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { CloseSurveyComponent } from "../close-survey.component";
import { selectIsCompleted, selectSurvey } from "../state";
import { SurveyState } from "../state/survey.state";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "menufy-web-survey-header",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./survey-header.html",
  imports: [CloseSurveyComponent, TranslateModule],
})
export class SurveyHeaderComponent {
  public readonly survey = inject(Store<SurveyState>).selectSignal(selectSurvey);

  public readonly isSurveyCompleted = inject(Store<SurveyState>).selectSignal(selectIsCompleted);
}
