import { Pipe, PipeTransform } from "@angular/core";

/**
 * @deprecated
 */
@Pipe({
  name: "socialLinkImage",
  standalone: true,
})
export class SocialLinkImagePipe implements PipeTransform {
  private readonly _assetsPath: string = "/assets/icons/??.png";

  transform(value?: string) {
    const key = value?.toLowerCase();

    return this._assetsPath.replace("??", key || "");
  }
}
