import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "beyond-meat-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="bb8a1fd6-e46c-4021-a229-b7873b0dd0eb"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 218.33 50"
  >
    <path d="M0,25A25,25,0,0,1,25,0H193.33a25,25,0,0,1,0,50H25A25,25,0,0,1,0,25" fill="#6bb335" />
    <path
      d="M30,34.25h6.26c4.1,0,6.66-1.83,6.66-5.5a4.35,4.35,0,0,0-2.66-4.23,4,4,0,0,0,2-3.57c0-3.37-2.38-5.2-6.48-5.2H30Zm6.08-7.86c2.58,0,3.29.79,3.29,2.28S38.31,31,36.26,31H33.52V26.39ZM35.8,19c2.08,0,2.91.6,2.91,1.95S38,23.1,35.78,23.1H33.52V19Zm8.87,15.25h11V30.88H48.19V26.65h7.45V23.28H48.19V19.12h7.45V15.75h-11Zm17.66,0h3.52V25.56l5.93-9.81H67.9l-3.8,6.36-3.77-6.36H56.45l5.88,9.73Zm16.45.25A9.5,9.5,0,1,0,69.33,25a9.45,9.45,0,0,0,9.45,9.5m0-3.47a6,6,0,1,1,6-6,6,6,0,0,1-6,6m11,3.22h3.52V21.4l8.11,12.85h3.75V15.75h-3.52V28.19L93.82,15.75h-4Zm17.76,0h5.2c5.64,0,9.62-4,9.62-9.25s-3.52-9.25-9.78-9.25h-5ZM112.22,19c4.53,0,6.66,2.48,6.66,6s-2.51,6-6.51,6H111.1V19Zm16,15.23h3.52V21.5L137.25,29l5.58-7.52V34.25h3.52V15.75H143l-5.75,7.7-5.55-7.7h-3.5v18.5Zm20.51,0h11V30.88h-7.45V26.65h7.45V23.28h-7.45V19.12h7.45V15.75h-11Zm12.2,0h3.67L165.73,31h7.1l1.11,3.22h3.68l-6.57-18.5h-3.54Zm8.33-13.48,2.38,6.89H166.9Zm10.61,13.48h3.52V19.12h4.92V15.75H175v3.37h4.91Zm11.18-15.81a1.47,1.47,0,0,0,.08-2.94h-.08a1.47,1.47,0,0,0,0,2.94m0-.27a1.2,1.2,0,1,1,1.18-1.2,1.18,1.18,0,0,1-1.18,1.2m-.52-.5h.28V16.45h.25c.17,0,.31.07.31.25a.29.29,0,0,1-.32.29h-.13l.41.68h.33l-.32-.5a.47.47,0,0,0,.33-.48.51.51,0,0,0-.57-.48h-.57Z"
      fill="#fdffff"
    />
  </svg>`,
})
export class BeyondMeatIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
