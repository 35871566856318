import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { CloseSurveyComponent } from "../close-survey.component";
import { SurveyPageActions } from "../state";
import { SurveyState } from "../state/survey.state";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "menufy-web-survey-banner",
  standalone: true,
  imports: [CloseSurveyComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./survey-banner.html",
  styles: `:host {
    z-index: 5000;
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
  }`,
})
export class SurveyBannerComponent {
  constructor(private readonly _store: Store<SurveyState>) {}

  public startSurvey() {
    this._store.dispatch(SurveyPageActions.startSurvey());
  }
}
