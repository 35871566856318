<section [formGroup]="form" class="d-flex flex-row justify-content-evenly flex-wrap">
  @for (option of question().options; track $index) {
  <div class="rating-btn-wrapper">
    <input
      type="radio"
      class="form-check-input"
      formControlName="answer"
      [value]="option.value"
      [id]="option.value"
    />
    <label [for]="option.value">{{ option.value }}</label>
    <span>{{ option.label ?? '' | titlecase }}</span>
  </div>
  }
</section>
