import { TitleCasePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Nullable } from "@eqn/data-types";
import { Store } from "@ngrx/store";
import {
  SurveyAnswer,
  SurveyAnswerOptionKind,
  SurveyRatingQuestion,
} from "../../interfaces/survey";
import { SurveyActions } from "../../state";
import { SurveyState } from "../../state/survey.state";

interface RatingFormGroup {
  answer: FormControl<Nullable<number>>;
}

@Component({
  selector: "menufy-web-survey-rating-input",
  standalone: true,
  imports: [TitleCasePipe, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./rating-input.html",
  styles: `
    .rating-btn-wrapper {
      margin: 0px 6px;
      max-height: 5rem;
      min-height: 5rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      flex-wrap: wrap;
      position: relative;

      span {
        position: absolute;
        top: 3rem;
      }
    }

    label {
      font-style: italic;
      font-weight: bold;
      font-size: 14px;
    }

    span {
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
    }

    .form-check-input {
      box-shadow: none;
      width: 1.3rem;
      height: 1.3rem;
      border-color: rgba(0, 0, 0, 0.5);

      &:focus {
        border-color: #000;
      }

      &:checked {
        border-color: #000;
        background-color: #000;
        background-size: 28px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000' stroke='%23fff' stroke-width='0.4px' /%3e%3c/svg%3e");
      }
    }

    input.form-control {
      margin-top: 2rem;
      outline: none;
      box-shadow: none;
      font-style: italic;
      font-size: 14px;
      border-radius: 6px;

      &:hover {
        border-color: #000;
      }
    }
  `,
})
export class SurveyRatingInputComponent implements OnInit {
  @Output() isValidEvent = new EventEmitter<boolean>();

  public question = input.required<SurveyRatingQuestion>();

  public form!: FormGroup<RatingFormGroup>;

  constructor(private readonly _store: Store<SurveyState>) {}

  ngOnInit() {
    const defaultValue = this.question()?.default?.value;

    this.form = new FormGroup<RatingFormGroup>({
      answer: new FormControl(defaultValue, Validators.required),
    });

    this.form.statusChanges.subscribe(() => this.isValidEvent.emit(this.form.valid));

    this.form.valueChanges.subscribe((value) => this._updateAnswer(value.answer));

    this.form.updateValueAndValidity();
  }

  private _updateAnswer(value: Nullable<number>) {
    if (!value) {
      return;
    }

    const questionId = this.question().id;

    const answer: SurveyAnswer<SurveyAnswerOptionKind.OPTION, number> = {
      kind: SurveyAnswerOptionKind.OPTION,
      value,
    };

    this._store.dispatch(SurveyActions.updateSurveyAnswer({ questionId, answer }));
  }
}
