@for (question of questions(); track question.id; let idx = $index) {
<section class="survey-stepper-wrapper {{ idx !== stepper().selectedStepIdx ? 'd-none' : '' }}">
  <p class="question-title">{{ stepper().selectedStepIdx + 1 }}. {{ question.description }}</p>

  <section style="min-height: 12rem; margin-top: 2rem">
    @switch (question.type) { @case (QuestionType.RATING) {
    <menufy-web-survey-rating-input
      [question]="question"
      (isValidEvent)="onInputStatus($event, question.id)"
    />
    } @case (QuestionType.MULTI_SELECT) {
    <menufy-web-survey-select
      [question]="question"
      (isValidEvent)="onInputStatus($event, question.id)"
    />
    } @case (QuestionType.TEXT) {
    <menufy-web-survey-text-input
      [question]="question"
      (isValidEvent)="onInputStatus($event, question.id)"
    />
    } @case (QuestionType.YES_NO) {
    <menufy-web-survey-yn-input
      [question]="question"
      (isValidEvent)="onInputStatus($event, question.id)"
    />
    } }
  </section>

  <div
    class="w-100 d-flex flex-row align-items-center mt-5"
    [ngClass]="!isFirstStep() ? 'justify-content-between' : 'justify-content-end' "
  >
    @if (!isFirstStep()) {
    <button mat-flat-button type="button" class="back-btn fs-1 p-4 fw-bold" (click)="goToPrev()">
      <div class="d-flex flex-row align-items-center justify-content-center w-100">
        <mat-icon>chevron_left</mat-icon>
        <span>{{ "survey.stepper.back_btn" | translate }}</span>
      </div>
    </button>
    }

    <button mat-flat-button type="button" class="next-btn fs-1 p-4 fw-bold" (click)="goToNext()">
      <div class="d-flex flex-row align-items-center justify-content-center w-100">
        <span>{{ "survey.stepper.next_btn" | translate }}</span>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </button>
  </div>
</section>
}
