import { provideStoreDevtools } from "@ngrx/store-devtools";
import { AppEnvironment } from "./env.interface";

export const environment: AppEnvironment = {
  production: false,
  survey: {
    appearanceDelay: 1000 * 1,
    get storageName() {
      return `${environment.localStorageStoreName}::survey:completed:id`;
    },
  },
  localStorageStoreName: window.location.host,
  apiBaseUrl: "https://dev-api.eateasy.io",
  providers: [
    provideStoreDevtools({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
      trace: false,
      traceLimit: 75,
      connectInZone: true,
    }),
  ],
};
