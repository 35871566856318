import { Menu, PaginationCursor } from "@menufy/models";

export const MENUS_DEFAULT_PAGE_LIMIT = 100;

export interface MenuState {
  menus: Menu[];
  cursor: PaginationCursor;
  error: unknown;
}

export const menuInitialState: Readonly<MenuState> = {
  menus: [],
  cursor: {
    next: null,
    previous: null,
  },
  error: null,
};
