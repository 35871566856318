import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, tap } from "rxjs";
import { Store } from "@ngrx/store";
import { MatBottomSheet, MatBottomSheetConfig } from "@angular/material/bottom-sheet";
import { RestrictionsComponent } from "../../personalized-dining/restrictions/restrictions.component";
import { PopupEvent } from "@diners/common";
import { PreferencesComponent } from "../../personalized-dining/preferences/preferences.component";
import { AppPopupActions, OpenPopupProps } from "../actions/app-popup.actions";
import { AppPopupState } from "../app-popup.state";
import { TranslateComponent } from "../../engagement-dropdown/translate/translate.component";
import { IconsGuideComponent } from "../../personalized-dining/icons-guide/icons-guide.component";
import { LocaleService } from "@eqn/angular-i18n";
import { AllergensAlertComponent } from "../../personalized-dining/allergens-alert/allergens-alert";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Injectable()
export class AppPopupEffects {
  private readonly _openInitialPopup = createEffect((locale = inject(LocaleService)) =>
    locale.i18Initialized$.pipe(
      map(() => AppPopupActions.openPopup({ next: PopupEvent.RESTRICTION }))
    )
  );

  private readonly _openPopup = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppPopupActions.openPopup),
        tap((action) => this._dispatchNextPopup(action))
      ),
    {
      dispatch: false,
    }
  );

  private readonly _openRestrictionPopup = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppPopupActions.openRestrictionPopup),
        tap(() => {
          const config: MatBottomSheetConfig = {
            hasBackdrop: true,
            panelClass: "restriction-popup",
          };

          this._bottomSheet.open(RestrictionsComponent, config);
        })
      ),
    {
      dispatch: false,
    }
  );

  private _openPreferencesPopup = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppPopupActions.openPreferencesPopup),
        tap(() => {
          const config: MatBottomSheetConfig = {
            hasBackdrop: true,
            panelClass: "preferences-popup",
          };

          this._bottomSheet.open(PreferencesComponent, config);
        })
      ),
    {
      dispatch: false,
    }
  );

  private _openTranslatePopup = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppPopupActions.openTranslatePopup),
        tap(() => {
          const config: MatBottomSheetConfig = {
            hasBackdrop: true,
            panelClass: "translate-popup",
          };

          this._bottomSheet.open(TranslateComponent, config);
        })
      ),
    {
      dispatch: false,
    }
  );

  private _openIconsGuidePopup = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppPopupActions.openIconsGuidePopup),
        tap(() => {
          const config: MatBottomSheetConfig = {
            hasBackdrop: true,
            panelClass: "icons-guide-popup",
            closeOnNavigation: true,
          };

          this._bottomSheet.open(IconsGuideComponent, config);
        })
      ),
    {
      dispatch: false,
    }
  );

  private openAllergensAlertPopup = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppPopupActions.openAllergensAlertPopup),
        tap(() => {
          const config: MatDialogConfig = {
            hasBackdrop: true,
            closeOnNavigation: true,
            width: "100%",
            maxWidth: "480px",
            panelClass: "allergens-alert-popup",
            enterAnimationDuration: "0ms",
            exitAnimationDuration: "0ms",
            backdropClass: "allergens-alert-backdrop",
          };

          this._dialog
            .open(AllergensAlertComponent, config)
            .afterClosed()
            ?.subscribe(() => this._store.dispatch(AppPopupActions.clearPopups()));
        })
      ),
    {
      dispatch: false,
    }
  );

  private _dispatchNextPopup(action?: OpenPopupProps) {
    if (!action?.next) {
      return;
    }

    switch (action.next) {
      case PopupEvent.RESTRICTION: {
        return this._store.dispatch(AppPopupActions.openRestrictionPopup());
      }

      case PopupEvent.PREFERENCES: {
        return this._store.dispatch(AppPopupActions.openPreferencesPopup());
      }

      case PopupEvent.ICONS_GUIDE: {
        return this._store.dispatch(AppPopupActions.openIconsGuidePopup());
      }

      case PopupEvent.ALLERGENS_ALERT: {
        return this._store.dispatch(AppPopupActions.openAllergensAlertPopup());
      }

      case PopupEvent.TRANSLATE: {
        return this._store.dispatch(AppPopupActions.openTranslatePopup());
      }
    }
  }

  constructor(
    private readonly _store: Store<AppPopupState>,
    private readonly _actions$: Actions,
    private readonly _bottomSheet: MatBottomSheet,
    private readonly _dialog: MatDialog
  ) {}
}
