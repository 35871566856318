import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "new-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a90253db-731e-48aa-ae7a-f21b32f3bd6f"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 480.85 480.9"
  >
    <path
      d="M496.42,256.8l-63.14-51.08,40.42-70.25-85.5-7.58c-2.53-27.52-5-54.56-7.57-82.32L308.56,79.91,259,15.54c-15.73,22.23-31.09,44-46.62,65.9l-75-38.81c-1.57,28.92-3.09,56.86-4.63,85.24l-84.13,4.61,31.49,80.86-64.48,39,66,49.42-33,70.36,82.61,9.05c1.53,28,3,55.5,4.57,83.79l76.54-35.84,51.12,67.35,45.1-67.37,70.52,34.33c2.08-28.52,4.09-56,6.12-83.86L467.76,378l-29.9-68.79c19.66-17.61,38.92-34.86,58.57-52.44Zm-287.78,47-52.8-48.35c7,19.45,14.1,38.94,21.46,59.25l-18.86,6.65-30.17-86.14L152,226.85l49.09,44.77-20.26-54.7,19-6.8,30.17,86.15-21.3,7.54m51.84-19.21c-8.79.35-16.18-3-22.27-9.38-13.3-13.93-13.42-39-.19-50.74,18.83-16.77,43-10.15,51.18,14.07.89,2.62,1.39,5.36,2.17,8.46l-38.8,13.72c2.82,5.66,8.19,7.93,15.43,5.86,5.36-1.53,10.45-4.09,15.59-6.33a42.9,42.9,0,0,0,4.9-2.94,19.87,19.87,0,0,1,2,2.35c1.93,3.21,3.78,6.47,5.94,10.2-10.66,8.75-22.32,14.19-35.95,14.73m99.81-33.94-22.11-33.91c1,13,2.08,26.53,3.18,40.51L322.24,264l-40.17-56.37,20.72-7.28,23,39.89c-1.4-15.3-2.8-30.48-4.25-46.33l16.19-5.85,25.05,38.77c-2.13-15.16-4.19-29.74-6.36-45.16l20.22-7.21c.91,23.77,1.76,46.31,2.65,69.52l-19,6.7Z"
      transform="translate(-15.58 -15.54)"
    />
    <path
      d="M180.82,216.92l20.26,54.7L152,226.85l-23.72,8.37,30.17,86.14,18.86-6.65c-7.36-20.31-14.42-39.81-21.46-59.25l52.8,48.35,21.3-7.54-30.17-86.15-19,6.8m175.65-35.26c2.17,15.42,4.22,30,6.36,45.16l-25.05-38.77-16.19,5.85c1.45,15.85,2.85,31,4.25,46.33l-23-39.89-20.72,7.28L322.25,264l19.12-6.73c-1.1-14-2.16-27.56-3.18-40.51l22.11,33.91,19-6.7c-.88-23.21-1.74-45.74-2.65-69.52l-20.22,7.21Zm-68,75.68a42.05,42.05,0,0,1-4.9,2.94c-5.15,2.24-10.23,4.8-15.59,6.33-7.24,2.07-12.61-.2-15.43-5.86L291.37,247c-.78-3.09-1.29-5.84-2.17-8.46C281,214.35,256.85,207.73,238,224.5c-13.23,11.78-13.11,36.81.19,50.74,6.09,6.38,13.48,9.73,22.27,9.38,13.63-.54,25.29-6,35.95-14.73-2.16-3.73-4-7-5.94-10.2a19.87,19.87,0,0,0-2-2.35m-35.22-25.55c6.25-2.68,11.41-.2,14.59,7.5l-20,7c-2.59-6.3-.35-12.07,5.37-14.52"
      transform="translate(-15.58 -15.54)"
      fill="#fff"
    />
    <path
      d="M253.27,231.79c-5.72,2.46-8,8.22-5.37,14.52l20-7c-3.18-7.7-8.34-10.18-14.59-7.5"
      transform="translate(-15.58 -15.54)"
    />
  </svg>`,
})
export class NewIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
