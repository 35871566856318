import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "sulphites-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="e414d76f-39ae-4381-bb40-87f9da575699"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#aa5d7c" />
    <path
      d="M32.57,53.88a4.09,4.09,0,0,0-.61,0,6.08,6.08,0,0,0-1.74.43,6.19,6.19,0,1,0,8,8.18,6.28,6.28,0,0,0,.47-1.81,6.2,6.2,0,0,0-5.54-6.8c-.21,0-.41,0-.62,0"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
    <path
      d="M56.25,59.83a16.66,16.66,0,0,0,5.84-7.68,16.41,16.41,0,0,0,.55-1.72A14.67,14.67,0,0,0,63,48.8a16.9,16.9,0,0,0,.18-2.44,16.62,16.62,0,1,0-32.56,4.73h.09l.62-.1.32,0a9.18,9.18,0,0,1,6.28,1.66l3-3L43,51.7l-3,3a9.13,9.13,0,0,1,1.74,5.36,8.43,8.43,0,0,1-.06,1c0,.11,0,.22-.05.33s-.07.45-.12.66a.5.5,0,0,1,0,.12,16.66,16.66,0,0,0,14.74-2.37Zm9.38-9a1.09,1.09,0,0,1,0,.17c0,.17-.1.34-.14.52a16.29,16.29,0,0,1-.59,1.79c-.15.38-.31.76-.47,1.13-.08.17-.15.35-.24.52l-.15.3,2.27,1.39L65.48,58l-.78,1.28-2.28-1.39c0,.06-.09.11-.13.16l-.34.44c-.26.33-.52.65-.8,1l-.41.45c-.3.31-.6.61-.92.9l-.38.35-.14.13a6.19,6.19,0,1,0,6.33-10.39Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class SulphitesIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
