export const ALLERGEN_LIST = [
  "gluten",
  "milk",
  "soy",
  "nuts",
  "peanuts",
  "eggs",
  "fish",
  "crustaceans",
  "molluscs",
  "sesame",
  "celery",
  "mustard",
  "lupins",
  "sulphites",
];

export const DIET_LIST = ["vegetarian", "vegan", "pescetarian", "paleo", "keto", "flexitarian"];
