import { createFeature, createReducer, createSelector } from "@ngrx/store";
import { immerOn as on } from "ngrx-immer/store";
import { businessInitialState } from "./business.state";
import { BusinessApiActions } from "./actions/business-api.actions";
import businessApiReducers from "./reducers/business-api.reducers";

const BUSINESS_FEATURE_NAME = "business";

export const businessFeature = createFeature({
  name: BUSINESS_FEATURE_NAME,
  reducer: createReducer(
    businessInitialState,
    on(BusinessApiActions.getBusinessSuccess, businessApiReducers.getBusinessSuccessReducer),
    on(BusinessApiActions.getBusinessFailed, businessApiReducers.getBusinessFailedReducer)
  ),
  extraSelectors: ({ selectBusiness }) => ({
    selectBusinessId: createSelector(selectBusiness, (business) => business?.id),
    selectBusinessHandle: createSelector(selectBusiness, (business) => business?.handle),
    selectSocialNetwork: createSelector(selectBusiness, (business) => business?.socialNetwork),
    selectRatingPlatforms: createSelector(selectBusiness, (business) => business?.ratingPlatforms),
    selectBusinessConfig: createSelector(selectBusiness, (business) => business?.config),
  }),
});

export const businessSlice = {
  name: businessFeature.name,
  reducer: businessFeature.reducer,
} as const;
