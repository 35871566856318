import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "lupins-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="bd271e0d-2524-4de8-a8df-6b740276875c"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M58.74,60.3c0-.11,0-.18,0-.26a7.42,7.42,0,0,0-3.49-6.35l-.2-.13-.15-.19a12.53,12.53,0,0,0-9.13-4.61h-.33L44,49l0-.23h-.1l-.65.08c-.2,0-.41.05-.61.09l-.57.13-.66.16-.49.16-.69.24-.43.19c-.23.1-.46.2-.69.32s-.27.16-.41.24-.43.24-.63.37l-.48.34c-.17.12-.34.23-.5.36a11.34,11.34,0,0,0-.9.81,9.64,9.64,0,0,0-1.22,1.43l-.12.17-.17.13a7.16,7.16,0,0,0-2.91,5.43A7.33,7.33,0,0,0,35,65.6l.17.12.13.17a12.54,12.54,0,0,0,9.19,4.63,12.33,12.33,0,0,0,10.36-4.36L55,66l.2-.13a7.37,7.37,0,0,0,3.51-5.55m-8.26-5.88-1.18.93a4.35,4.35,0,0,0-1.23-1.06A5.44,5.44,0,0,0,43.16,54a4.66,4.66,0,0,0-1.44.94l-1-1.07-1.05-1.07a8.19,8.19,0,0,1,5.71-2.25,8,8,0,0,1,6.32,2.93l-1.18.93Z"
      transform="translate(-3.41 -16.46)"
      fill="orange"
    />
    <path
      d="M69.61,50.35l0-.23,0-.23a12.57,12.57,0,0,0-3.22-9.76A12.42,12.42,0,0,0,55.24,36L55,36l-.22,0a7.6,7.6,0,0,0-3.84.48,6.49,6.49,0,0,0-2.05,1.3,6.94,6.94,0,0,0-2.14,4.76,8.4,8.4,0,0,0,.12,1.92l0,.21,0,.22c0,.32-.06.64-.07,1l.42.07L48,46c.28,0,.57.11.85.18s.46.11.69.18l.82.26c.22.08.44.15.65.24l.8.35.61.29.77.44.56.33q.39.26.75.54c.16.12.33.23.49.36s.52.45.77.68.26.22.38.34c.36.36.71.74,1,1.14a11.61,11.61,0,0,1,1,.8l.3.28c.21.2.41.39.6.6s.21.25.31.37.32.39.47.59l.28.42c.13.2.26.4.37.61l.24.46c.1.21.2.42.29.64a4.68,4.68,0,0,1,.18.48c.08.22.14.44.21.67s.09.32.13.48.09.48.12.72.05.31.06.47a.69.69,0,0,0,0,.13,7.12,7.12,0,0,0,5.89-1.78,7.37,7.37,0,0,0,2-6.92Zm-2.41-2.7-3-.35a4.46,4.46,0,0,0-.12-1.62A5.44,5.44,0,0,0,60.82,42a4.51,4.51,0,0,0-1.68-.35l0-1.5,0-1.5a8.33,8.33,0,0,1,8,9Zm-37.63.77a7,7,0,0,0,4.86,1.38l.43-.37c.15-.13.3-.27.46-.39l.64-.47q.49-.34,1-.66l.61-.36c.22-.12.45-.22.68-.33l.6-.27.69-.26.67-.22.68-.19.78-.18L42.3,46c.34-.06.69-.1,1-.13l.4-.05h.06c0-.35,0-.7.07-1a10.2,10.2,0,0,1,3-9.16c2.44-2.29,0,0,0,0a9.73,9.73,0,0,1,.79-.65l.27-.19c.21-.15.42-.28.64-.41l.16-.1c0-.21-.09-.41-.14-.6l-.07-.24v-.24a7.21,7.21,0,0,0-2.67-6.27,6.87,6.87,0,0,0-4.24-1.41,8.36,8.36,0,0,0-2.91.54l-.22.08h-.24a12.56,12.56,0,0,0-9.11,4.74,12.36,12.36,0,0,0-2.31,11.65l.07.21v.22a7.13,7.13,0,0,0,2.68,5.52Zm.77-16.21a8.07,8.07,0,0,1,6.08-3.41l.06,1.5.06,1.5a4.39,4.39,0,0,0-1.58.37A5.46,5.46,0,0,0,31.87,36a4.5,4.5,0,0,0-.08,1.71l-1.48.22-1.48.22a8.22,8.22,0,0,1,1.52-6Zm49.2,47.33-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84Z"
      transform="translate(-3.41 -16.46)"
      fill="orange"
    />
  </svg>`,
})
export class LupinsOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
