import { SetStepperStepActionProps, StepperStepAction } from "../actions/survey-page.actions";
import { SurveyState } from "../survey.state";

const setStepperStepReducer = (state: SurveyState, action: SetStepperStepActionProps) => {
  const { action: direction } = action;

  let nextIdx = -1;
  const { selectedStepIdx, steps } = state.stepper;

  if (direction === StepperStepAction.NEXT) {
    nextIdx = selectedStepIdx + 1;

    if (nextIdx > steps) {
      state.isCompleted = true;
      nextIdx = selectedStepIdx;
    }
  }

  if (direction === StepperStepAction.PREV) {
    nextIdx = selectedStepIdx - 1;

    if (nextIdx < 0) {
      nextIdx = 0;
    }
  }

  state.stepper = {
    ...state.stepper,
    selectedStepIdx: nextIdx,
  };
};

export default {
  setStepperStepReducer,
};
