import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";
import * as Hammer from "hammerjs";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[swipeHorizontal]",
  standalone: true,
})
export class SwipeHorizontalDirective {
  @Output() swipeLeft = new EventEmitter<void>();
  @Output() swipeRight = new EventEmitter<void>();

  private hammerManager!: HammerManager;

  constructor(private readonly _el: ElementRef) {}

  ngOnInit(): void {
    this._registerHammer();
  }

  ngOnDestroy(): void {
    this.hammerManager.destroy();
  }

  private _registerHammer() {
    this.hammerManager = new Hammer.Manager(this._el.nativeElement);
    const swipe = new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL });
    this.hammerManager.add(swipe);
    this.hammerManager.on("swipe", (event) => {
      if (event.offsetDirection === 4) {
        this.swipeRight.emit();
      }

      if (event.offsetDirection === 2) {
        this.swipeLeft.emit();
      }
    });
  }
}
