import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "soy-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="badc8900-83a2-441e-a39d-1b9590d9e08c"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84ZM65.12,23.41c-2,6.25-5.67,3.45-8.81,7.73-2.27,3.08-.5,5.11-2.53,8s-4.89,1.9-7.24,5.2S44,51.23,41,52.22s-8.29.31-10.53,3.58c-1.23,1.79-2.58,8-7.46,9,2.49,6.93,13.51,9,19.27,7.11,4.65-1.5,5.29-3.38,9.06-5.91,4-2.68,8.62-1.51,12.14-6.18,3.88-5.12,2.12-6.35,3.66-10.57s13.63-12.13-2-25.87ZM40,66.14c-3.15,1.64-6.59,1.26-7.7-.84s.56-5.15,3.7-6.8,6.59-1.26,7.69.84-.55,5.15-3.69,6.8m15.92-11c-2.59,2.43-6,3-7.64,1.24s-.83-5.11,1.76-7.54,6-3,7.63-1.24.84,5.11-1.75,7.54M66.46,38.39c-1.23,2.47-3.88,3.66-5.91,2.65s-2.68-3.84-1.45-6.29S63,31.08,65,32.1s2.68,3.82,1.47,6.29"
      transform="translate(-3.41 -16.46)"
      fill="#95c800"
    />
  </svg>`,
})
export class SoyOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
