import {
  booleanAttribute,
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { ICON_COMPONENT_MAP } from "../icons/icon-map";
import { ObjectLiteral, RawRecord, StringUtil } from "@eqn/data-types";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "men-icon",
  standalone: true,
  template: ` <ng-template #iconTemplate />`,
  encapsulation: ViewEncapsulation.None,
})
export class MenufyIconComponent implements OnInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  @Input() icon?: string;

  @Input({ transform: booleanAttribute }) isOptional?: boolean;

  @ViewChild("iconTemplate", { read: ViewContainerRef, static: true })
  private readonly _templateRef!: ViewContainerRef;

  private _iconCmpRef?: ComponentRef<never>;

  get hasIcon(): boolean {
    return !!this._iconCmpRef;
  }

  ngOnInit() {
    if (!this.icon) {
      return;
    }

    const iconName = StringUtil.toPropertyName(
      this.isOptional ? `${this.icon}-optional` : this.icon
    );

    const iconComponentRef = (ICON_COMPONENT_MAP as ObjectLiteral)[iconName];

    if (!iconComponentRef) {
      return;
    }

    this._iconCmpRef = this._templateRef?.createComponent(iconComponentRef);

    const cmpInstance = this._iconCmpRef.instance as RawRecord;

    if (this.width) {
      cmpInstance["width"] = this.width;
    }

    if (this.height) {
      cmpInstance["height"] = this.height;
    }

    if (this.class) {
      cmpInstance["class"] = this.class;
    }
  }
}
