import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "pink-albatross-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f6487781-0d9b-4d36-b29d-853e8e5e89dd"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 196.51 50"
  >
    <path d="M0,25A25,25,0,0,1,25,0H171.51a25,25,0,0,1,0,50H25A25,25,0,0,1,0,25" fill="#e08b8e" />
    <path
      d="M37.71,13.81H30v.61s1.95,0,1.95,4V30.89c0,3.67-1.51,3.64-1.95,3.64v.66h7.07v-.66c-.44,0-2,0-2-3.64V24.5H37.8c2.25,0,6.36-1.11,6.36-5.48,0-4.86-3.74-5.21-6.45-5.21m-.06,9.36H35.12V14.71h2.5c1.72,0,3.16.72,3.16,4.33s-1.71,4.13-3.13,4.13"
    />
    <path
      d="M42.33,30.51V25.15h-.54l-3.68,1.71c1.51.55,1.51,1.59,1.51,3.65,0,1.53-.12,4.16-1.46,4.16v.52h5.63v-.52c-1.34,0-1.46-2.63-1.46-4.16"
    />
    <path
      d="M53.6,31.13V28.05c0-2.83-.84-3.59-2.11-3.59a4,4,0,0,0-3.66,2.11v-1.9h-.54L43.7,26.38c1.5.55,1.42,2.07,1.42,4.13,0,1.53-.12,4.16-1.47,4.16v.52h5.64v-.52c-1.34,0-1.46-2.63-1.46-4.16V27.1c.27-.55.83-1.41,1.64-1.41.6,0,1.5.83,1.5,2.85v2.4c0,2.5-.63,3.69-1.32,3.69v.56h5.41v-.52c-1.15,0-1.46-2.24-1.46-3.54"
    />
    <path
      d="M59.62,30.51V17.69H59l-4.11,2c1.26.65,1.65,1.49,1.65,3.69,0,2.73,0,6.24,0,7.34,0,1.52-.39,4-1.5,4v.52h6.2v-.52c-1.34,0-1.64-2.63-1.64-4.16"
    />
    <path
      d="M73.1,35.9c-1,0-1.13,1.06-1.13,1.3a1.32,1.32,0,0,1-1.46,1.24c-1.9,0-5-5.85-5.35-6.54s-2.54-5.12-2.54-5.12,3.23-2.93,4.6-2.93v-.53H63.64v.61s.11,0,.11.22-1,1.46-1.29,1.84-2.55,2.65-2.55,2.65,3.07,5.7,3.79,6.75c1.57,2.27,2.69,4.32,6.28,4.32,2.9,0,4.07-1.73,4.07-2.74a.92.92,0,0,0-1-1.08m24.21-7.38V17.09h-.54l-3.94,1.83c1.26.66,1.66,1.31,1.66,3.73,0,2.74,0,4.94,0,6,0,1.81-.38,3.89-1.5,3.89v.61h5.81v-.61c-1.17,0-1.46-2.31-1.46-4.07M141,22.2c-2.9,0-5.25,1.7-5.25,5.49s2.35,5.5,5.25,5.5,5.25-1.58,5.25-5.5S143.89,22.2,141,22.2m0,10.4c-1.21,0-2.18-1.62-2.18-4.91s1-4.91,2.18-4.91,2.18,1.74,2.18,4.91-1,4.91-2.18,4.91M154.3,23v2.65h-.36c-.13-.63-1-3.07-2.55-3.07a1.87,1.87,0,0,0-2,1.73c0,.67,1,1.49,2.58,2.07a3.59,3.59,0,0,1,2.55,3.34c0,1.18-.61,3.7-4.4,3.7a4.86,4.86,0,0,1-3.13-1l-.08-2.79h.19c.34,1,1.31,3.17,3.16,3.17A1.64,1.64,0,0,0,152,31c0-1.34-1.42-1.73-2.91-2.63a3.67,3.67,0,0,1-2-3c0-1.25.8-3.54,4.32-3.54A4.09,4.09,0,0,1,154.3,23m-33.82,8.45a1,1,0,0,1-.84.47c-.58,0-1-.44-1-1.82V25.71a3.24,3.24,0,0,0-3.45-3.52c-2.82,0-4.65,1.45-4.57,3.66a3.86,3.86,0,0,0,2.88,0c0-1.39.12-2.85,1.27-2.85.85,0,.84,1.61.84,2.13v1.93c-1.17,0-5.34.41-5.34,3.35,0,3.29,3.74,3.65,5.55,1.62.23.88.67,1.38,1.56,1.38,2.9,0,3.33-1.68,3.33-1.68Zm-7.35-1.57c0-1.85,1.78-2.23,2.51-2.31V29a22.55,22.55,0,0,0,.09,2.32c-.86.81-2.6.4-2.6-1.51m-7.44-7.7c-1.59,0-2.42.35-3,1.15V17.09h-.53l-4,1.83c1.27.66,1.66,1.31,1.66,3.73V33.19H100a1.89,1.89,0,0,1,1.36-.84c.82,0,1.89.95,3.18.95,1.66,0,5.15-.79,5.15-5.58,0-4.51-2.37-5.57-4-5.57m-1.22,10.28c-1.16,0-1.8-1.34-1.8-3.92V23.85a1.29,1.29,0,0,1,.86-.28c1,0,3,.87,3,5.13,0,3.05-1.27,3.73-2,3.73M124,19.12h-.52c-.47,1.35-1.92,4.77-3.46,4.34v.8a1.47,1.47,0,0,0,1.09.16v5.77c0,.93-.09,3.07,2.35,3.07a4.25,4.25,0,0,0,3.7-2.56l-.19-.15s-.81,1.29-1.82,1.29-1.15-.39-1.15-2.9v-5.6s4.9-3.74,8.28-3.74c2.73,0,2.47,1.75,3.67,1.75s1.5-1.14,1.5-1.77-.63-2.36-3.18-2.36c-3.84,0-9.61,4.52-10.27,5.17Z"
    />
    <path
      d="M134.24,22.31c-1.59,0-2.55,2.34-3,3.87V22.31h-.58l-3.86,1.8c1.51.6,1.59,1.77,1.59,4,0,1.65-.12,4.5-1.46,4.5v.57h5.77v-.57c-1.34,0-1.46-2.84-1.46-4.5V26.71c.34-.78,1.19-1.73,1.82-1.73,1,0,1.31.7,1.84.7s.87-.93.87-1.41-.2-2-1.5-2m-32,15.37c-2.29,0-.92,4.21-3.62,4.21-2.34,0-5.35-5-9.17-14.73l-4.29-10.9H84.3L79.53,29.84c-.4,1.25-1.68,4.61-3.3,4.61v.74h5.24v-.74c-2.07,0-1.1-3.55-.71-5l.11-.32h5.67c.82,2,1.52,3.66,1.66,4,.37.94,3.7,9.62,9.54,9.62s6.14-2.88,6.14-3.31-.24-1.82-1.61-1.82m-21-9.55,2.17-6.46s1.41,3.36,2.7,6.46Zm84.14-5.66-.16-3.77a8.18,8.18,0,0,0-4.09-1.57,5.26,5.26,0,0,0-5.52,5.36,3.26,3.26,0,0,0,1.66,3.13,4.09,4.09,0,0,1,1.79-.37c1.64,0,4.89,1,4.89,4.23s-2.3,3.6-3,3.6-3.13-.27-3.13-3.45a2.34,2.34,0,0,1,1.79-2.5c1.32,0,.27,2.56,1.67,2.56,1.06,0,1.18-1.16,1.18-1.47,0-.64-.85-2.27-3.21-2.27s-4.06,1.63-4.06,4.56,2.92,4.53,5.8,4.53a5.36,5.36,0,0,0,5.57-5.42c0-3.07-2-5.18-4.77-6.21s-3.16-1.84-3.16-2.75A2.15,2.15,0,0,1,161,18.61c2.38,0,4.17,3.86,4.17,3.86Z"
    />
  </svg>`,
})
export class PinkAlbatrossIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
