import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { first, share } from "rxjs";
import { BrowserLocaleService } from "./browser-locale.service";
import {
  FALLBACK_LOCALE,
  LOCALE,
  LOCALE_LANG_ID,
  LOCALES_LANG_IDS,
  TranslationLanguage,
} from "./locale.config";
import { Nullable } from "@eqn/data-types";
import { DOCUMENT } from "@angular/common";

export interface LocaleServiceOptions {
  reloadOnChange?: boolean;
  cacheLocale?: boolean;
  availableLanguages?: TranslationLanguage[];
  automaticTranslations?: TranslationLanguage[];
}

export const LOCALE_SERVICE_CFG = new InjectionToken<LocaleServiceOptions>("LocaleService config");

@Injectable({ providedIn: "root" })
export class LocaleService {
  private readonly _userLocaleCachedKey = this._document.location.host.concat(":user:locale");

  private _config: LocaleServiceOptions = {
    reloadOnChange: false,
    cacheLocale: false,
    availableLanguages: [],
    automaticTranslations: [],
  };

  public get availableLanguages() {
    return this._config.availableLanguages;
  }

  public get automaticTranslations() {
    return this._config.automaticTranslations;
  }

  constructor(
    private readonly _translate: TranslateService,
    private readonly _browserLocale: BrowserLocaleService,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Optional() @Inject(LOCALE_SERVICE_CFG) config?: LocaleServiceOptions
  ) {
    Object.assign(this._config, config || {});

    this._initLocalization();
  }

  private setCachedLocale(langId: LOCALE_LANG_ID | string): void {
    if (!this._config?.cacheLocale) {
      return;
    }

    const locale = LOCALE[langId as LOCALE_LANG_ID] || FALLBACK_LOCALE;

    window.localStorage.setItem(this._userLocaleCachedKey, JSON.stringify(locale));
  }

  private getCachedLocale(): Nullable<TranslationLanguage> {
    if (!this._config?.cacheLocale) {
      return;
    }

    // read from cache
    const cachedLocale = window.localStorage.getItem(this._userLocaleCachedKey);

    return cachedLocale ? JSON.parse(cachedLocale) : null;
  }

  public get currentLocale(): TranslationLanguage {
    return (
      this.getCachedLocale() ||
      LOCALE[this._translate.currentLang as LOCALE_LANG_ID] ||
      FALLBACK_LOCALE
    );
  }

  public readonly i18Initialized$ = this._translate.onLangChange.pipe(share(), first());

  public getDefaultLocale(): TranslationLanguage {
    return this.getCachedLocale() || this._browserLocale.getDetectedLocale().locale;
  }

  public setLocale(langId: LOCALE_LANG_ID | string) {
    this.setCachedLocale(langId);

    this._translate.use(langId);

    if (this._config?.reloadOnChange) {
      this._document.location.reload();
    }
  }

  private _initLocalization() {
    this._translate.addLangs(LOCALES_LANG_IDS);
    this.setCachedLocale(this.getDefaultLocale().langId);
    this._translate.setDefaultLang(this.getDefaultLocale().langId);
    this._translate.use(this.getDefaultLocale().langId);
  }
}
