import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "flexitarian-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a2bbcbbe-c178-41c0-94ed-86551bee5072"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.53l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.68,44.68,0,0,1,0,63.1Z"
      transform="translate(-3.41 -16.45)"
      fill="#7d695e"
    />
    <path
      d="M16.46,79.53a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84Z"
      transform="translate(-3.41 -16.45)"
      fill="#7d695e"
    />
    <path
      d="M61.79,26.55a8.64,8.64,0,0,1-.08,1.23,2.14,2.14,0,0,1-.26.83,1.22,1.22,0,0,1-.42.47.94.94,0,0,1-.5.15H42.94V45.56H59.56a1,1,0,0,1,.5.13,1.14,1.14,0,0,1,.43.42,2.21,2.21,0,0,1,.26.8,9,9,0,0,1,.07,1.28,8.49,8.49,0,0,1-.07,1.21,2.34,2.34,0,0,1-.26.81,1.56,1.56,0,0,1-.43.48.82.82,0,0,1-.5.17H42.94v20a.91.91,0,0,1-.15.5.93.93,0,0,1-.52.38,6.2,6.2,0,0,1-1,.22,10.72,10.72,0,0,1-1.56.09A11,11,0,0,1,38.22,72a6.16,6.16,0,0,1-1-.22.93.93,0,0,1-.52-.38.91.91,0,0,1-.15-.5V26.41a2.42,2.42,0,0,1,.76-2,2.67,2.67,0,0,1,1.62-.57h21.6A.94.94,0,0,1,61,24a1.26,1.26,0,0,1,.42.48,2.36,2.36,0,0,1,.26.87A9,9,0,0,1,61.79,26.55Z"
      transform="translate(-3.41 -16.45)"
      fill="#7d695e"
    />
  </svg>`,
})
export class FlexitarianOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
