<section class="translate-popup-container pb-4">
  <div class="container px-3">
    <div class="row title-container-shadow" swipeDownToClose>
      <div class="col-12 col-md-12 text-center mt-4">
        <h3 [innerHTML]="'select_language_title' | translate"></h3>
      </div>
    </div>

    <!-- Available Languages -->
    <div class="row" style="max-height: 80vh; overflow: auto">
      <div class="col-12 col-md-12 mt-2 mb-1">
        <h2 [innerHTML]="'available_languages_title' | translate" class="m-0"></h2>

        <menufy-web-lang-list
          [languages]="availableLanguages"
          [currentLangId]="currentLangId"
          (languageSelected)="changeLanguage($event)"
        />
      </div>

      <!-- Automatic Translation -->
      <div class="col-12 col-md-12 mt-4">
        <h2 [innerHTML]="'auto_translate_title' | translate" class="m-0"></h2>

        <menufy-web-lang-list
          [languages]="automaticTranslations"
          [currentLangId]="currentLangId"
          (languageSelected)="changeLanguage($event)"
        />
      </div>
    </div>
  </div>

  <!-- Close icon -->
  <close-icon class="close-icon" (click)="close()" swipeDownToClose />

  <!-- Border -->
  <div class="popup-top-border-wrapper" swipeDownToClose>
    <span></span>
  </div>
</section>
