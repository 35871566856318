import { DateType, Nullable } from "@eqn/data-types";

export enum SurveyQuestionType {
  RATING = "RATING", // kind of radio
  YES_NO = "YES_NO", // kind of radio but with other
  MULTI_SELECT = "MULTI_SELECT", // kind of checkbox
  TEXT = "TEXT", // similar to textarea
}

export enum SurveyAnswerOptionKind {
  OPTION = "OPTION",
  YES = "YES",
  NO = "NO",
  OTHER = "OTHER",
  TEXT = "TEXT",
}

export interface SurveyAnswer<T extends SurveyAnswerOptionKind, V = unknown> {
  kind: T;
  value: V;
}

export interface SurveyYesOption {
  readonly label: string;
  readonly value: string;
  readonly kind: SurveyAnswerOptionKind.YES;
}

export type SurveyYesNoKind =
  | SurveyAnswerOptionKind.YES
  | SurveyAnswerOptionKind.NO
  | SurveyAnswerOptionKind.OTHER;

export interface SurveyYesNoOption<K extends SurveyYesNoKind> {
  readonly label: string;
  readonly value: string;
  readonly kind: K;
}

export interface SurveyNoOption {
  readonly label: string;
  readonly value: Nullable<string>; // if `kind` is `NO` then value must be text value specify by user
  readonly kind: SurveyAnswerOptionKind.NO;
}

export interface SurveyYesNoQuestion extends SurveyQuestion {
  readonly type: SurveyQuestionType.YES_NO;
  readonly yesOption: SurveyYesNoOption<SurveyAnswerOptionKind.YES>;
  readonly noOption: SurveyYesNoOption<SurveyAnswerOptionKind.NO>;
  readonly limits: SurveyTextQuestionLimit;
  answer?: SurveyAnswer<SurveyYesNoKind, string>[];
  readonly default?: SurveyAnswer<SurveyAnswerOptionKind.YES, string>;
}

export interface SurveyRatingOption {
  readonly label?: string;
  readonly value: number;
}

export interface SurveyRatingQuestion extends SurveyQuestion {
  readonly type: SurveyQuestionType.RATING;
  readonly options: SurveyRatingOption[];
  answer?: SurveyAnswer<SurveyAnswerOptionKind.OPTION, number>;
  readonly default?: SurveyAnswer<SurveyAnswerOptionKind.OPTION, number>;
}

export interface SurveyMultiSelectOption {
  readonly label: string;
  readonly value: Nullable<string>; // if `kind` is `OTHER` then value must not be empty
  readonly kind: SurveyAnswerOptionKind.OPTION | SurveyAnswerOptionKind.OTHER; // if `OTHER` then handle differently
}

export interface SurveyMultiSelectQuestion extends SurveyQuestion {
  readonly type: SurveyQuestionType.MULTI_SELECT;
  readonly options: SurveyMultiSelectOption[];
  answer?: SurveyAnswer<SurveyAnswerOptionKind.OPTION | SurveyAnswerOptionKind.OTHER, string>[];
  readonly default: SurveyAnswer<
    SurveyAnswerOptionKind.OPTION | SurveyAnswerOptionKind.OTHER,
    string
  >[];
}

export interface SurveyTextQuestionLimit {
  min: number;
  max: number;
}

export interface SurveyTextQuestion extends SurveyQuestion {
  readonly type: SurveyQuestionType.TEXT;
  readonly limits: SurveyTextQuestionLimit;
  answer?: SurveyAnswer<SurveyAnswerOptionKind.TEXT, string>;
  readonly default?: SurveyAnswer<SurveyAnswerOptionKind.TEXT, string>;
}

export interface SurveyQuestion {
  readonly id: string;
  readonly position: number;
  readonly description: string;
  readonly type: SurveyQuestionType;
  answer?: SurveyAnswer<SurveyAnswerOptionKind> | SurveyAnswer<SurveyAnswerOptionKind>[];
  startedAt?: DateType;
  endedAt?: DateType;
}

export interface SurveyStats {
  readonly participants: number;
  readonly completed: number;
}

export type SurveyQuestions = Array<
  SurveyTextQuestion | SurveyMultiSelectQuestion | SurveyRatingQuestion | SurveyYesNoQuestion
>;

export interface Survey {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly questions: SurveyQuestions;
  readonly createdAt: DateType; // from BE
  readonly stats: SurveyStats; // from BE
}

export type SurveyId = Pick<Survey, "id">;
