import { formatCurrency, getCurrencySymbol, getLocaleCurrencyCode } from "@angular/common";
import { inject, InjectionToken, Pipe, PipeTransform, Provider } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export type LocaleCurrencyFormat = "withSymbol" | "noSymbol";

export const CURRENCY_LOCALE_ID = new InjectionToken("locale currency lang id");

export const provideCurrencyLocale = (langId: string): Provider => {
  return {
    provide: CURRENCY_LOCALE_ID,
    useValue: langId,
  };
};

@Pipe({
  name: "localeCurrency",
  standalone: true,
  pure: false,
})
export class LocaleCurrencyPipe implements PipeTransform {
  private _memoizedCurrency = "";

  private _memoizedAmount!: number;

  private readonly _digitsFormat = "1.0-2";

  private _symbolFormat: LocaleCurrencyFormat = "withSymbol";

  private readonly _defaultLocaleId = inject<string>(CURRENCY_LOCALE_ID);

  constructor(private readonly _translate: TranslateService) {
    this._translate.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe((evt) => this._setCurrency(evt.lang));
  }

  transform(
    amount: string | number | unknown,
    symbolFormat: LocaleCurrencyFormat = "withSymbol"
  ): string | null {
    if (this._memoizedAmount || !amount) {
      return this._memoizedCurrency;
    }

    this._memoizedAmount = amount as number;
    this._symbolFormat = symbolFormat;

    this._setCurrency(this._translate.currentLang);

    return this._memoizedCurrency;
  }

  private _setCurrency(locale: string) {
    if (!this._memoizedAmount) {
      return;
    }

    const currencyCode = getLocaleCurrencyCode(this._defaultLocaleId) as string;
    const currencySymbol =
      this._symbolFormat === "withSymbol" ? getCurrencySymbol(currencyCode, "narrow") : "";

    const currencyFormat = formatCurrency(
      this._memoizedAmount,
      locale,
      // NOTE: important to set to empty string otherwise Angular output the currency symbol based on locale
      "",
      currencyCode,
      this._digitsFormat
    );

    this._memoizedCurrency = `${currencySymbol} ${currencyFormat}`;
    return this._memoizedAmount;
  }
}
