import { Directive, HostListener, Input, ElementRef } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";

@Directive({
  selector: "[closeMenuOnScroll]",
  standalone: true,
})
export class CloseMenuOnScrollDirective {
  @Input() menuTrigger!: MatMenuTrigger;

  constructor(private readonly _el: ElementRef) {}

  @HostListener("window:scroll", ["$event"])
  onScroll(event: Event): void {
    if (this.menuTrigger && this.menuTrigger.menuOpen) {
      const target = event.target as HTMLElement;

      if (!this._isDescendant(this._el?.nativeElement, target)) {
        this.menuTrigger.closeMenu();
      }
    }
  }

  private _isDescendant(parent: HTMLElement, child: HTMLElement): boolean {
    let node = child.parentNode;

    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }
}
