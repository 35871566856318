import { businessFeature } from "./business.slice";

export const {
  selectBusinessId,
  selectBusinessHandle,
  selectBusiness,
  selectSocialNetwork,
  selectRatingPlatforms,
  selectBusinessState,
  selectBusinessConfig,
} = businessFeature;
