import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "yosoy-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="acda70e2-2495-4912-bd51-82876736fb6e"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 120 50"
  >
    <path d="M95,50H25A25,25,0,0,1,25,0H95a25,25,0,0,1,0,50" fill="#e5decc" />
    <path
      d="M37.22,22.42l-1.75-9.56h-4L35,27.54v9.54h4V27.54l3.75-14.68H39Zm47.52-9.56L83,22.42l-1.75-9.56h-4l3.54,14.68v9.54h4V27.54l3.74-14.68ZM48.38,12.5c-2.28,0-5.14,1.35-5.14,5.39V32.11c0,4,2.86,5.39,5.14,5.39s5.13-1.35,5.13-5.39V17.89c0-4-2.85-5.39-5.13-5.39m1,19.61c0,1.55-.5,1.83-1,1.83s-1-.28-1-1.83V17.89c0-1.55.51-1.83,1-1.83s1,.28,1,1.83ZM71.54,12.5c-2.28,0-5.14,1.35-5.14,5.39V32.11c0,4,2.86,5.39,5.14,5.39s5.13-1.35,5.13-5.39V17.89c0-4-2.86-5.39-5.13-5.39m1,19.61c0,1.55-.51,1.83-1,1.83s-1-.28-1-1.83V17.89c0-1.55.51-1.83,1-1.83s1,.28,1,1.83ZM59,17.89c0-1.66.72-1.94,1.22-1.94s1.16.24,1.16,1.94v2.24h3.49V17.89c0-4.59-2.64-5.39-4.86-5.39s-5,1.35-5,5.39c0,5.73,6.19,10.07,6.19,14.22,0,1.79-.38,2.11-1.24,2.11s-1.46-.41-1.46-2.11V29.86H55v2.25c0,4.59,2.67,5.39,4.93,5.39S65,36.15,65,32.11c0-5.9-6-10.11-6-14.22"
    />
  </svg>`,
})
export class YosoyIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
