import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "pescetarian-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="fd6651c2-44ec-4cc4-8ee7-91e3a8abd3d4"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#1e3e64" />
    <path
      d="M65.05,38.38a16.79,16.79,0,0,1-1.19,6.49,13.71,13.71,0,0,1-3.39,4.94A15.16,15.16,0,0,1,55.05,53a23.5,23.5,0,0,1-7.66,1.11H41.93v17.3a.85.85,0,0,1-.16.52,1.19,1.19,0,0,1-.52.35,4.87,4.87,0,0,1-1,.22,11.91,11.91,0,0,1-3.08,0,5,5,0,0,1-1-.22.94.94,0,0,1-.52-.35.91.91,0,0,1-.15-.52V27.06A2.56,2.56,0,0,1,36.33,25a2.71,2.71,0,0,1,1.74-.64H48.35c1,0,2,.05,3,.13a29.37,29.37,0,0,1,3.37.56,14.13,14.13,0,0,1,4,1.6,12.79,12.79,0,0,1,3.43,2.87,12.45,12.45,0,0,1,2.17,4A15.21,15.21,0,0,1,65.05,38.38Zm-6.72.52A10,10,0,0,0,57.24,34a7.58,7.58,0,0,0-2.71-2.93,9.16,9.16,0,0,0-3.34-1.23,23,23,0,0,0-3.36-.26h-5.9V48.84h5.75a13.32,13.32,0,0,0,4.81-.74A9,9,0,0,0,55.7,46a8.81,8.81,0,0,0,2-3.15A11.76,11.76,0,0,0,58.33,38.9Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class PescetarianIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
