import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { selectIsCompleted, selectSurvey, SurveyPageActions } from "./state";
import { SurveyState } from "./state/survey.state";
import { SurveyHeaderComponent } from "./survey-header/survey-header";
import { SurveyStepperComponent } from "./survey-stepper/survey-stepper";
import { MatButtonModule } from "@angular/material/button";
import { CloseSurveyComponent } from "./close-survey.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "menufy-web-survey",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `@if (survey() && !isSurveyCompleted()) {
    <section>
      <menufy-web-survey-header />
      <menufy-web-survey-stepper />
    </section>
    } @if (isSurveyCompleted()) {
    <section class="survey-header-wrapper" style="align-items: center; justify-content: center;">
      <menufy-web-close-survey />
      <img
        src="/assets/icons/eateasy-text.png"
        alt="thank-you-image"
        style="width: auto; height: 7rem; object-fit: contain;"
      />
    </section>
    <section
      class="survey-stepper-wrapper text-center d-flex align-items-center flex-column justify-content-center"
    >
      <p class="question-title">
        {{ "survey.completed.message.title" | translate }}
        <br />
        {{ "survey.completed.message.sub_title" | translate }}
      </p>

      <img
        src="/assets/icons/FlowerThankYou.svg"
        alt="thank-you-image"
        style="width: auto; height: 12rem; margin-top: 2rem;"
      />

      <button
        mat-flat-button
        type="button"
        class="next-btn fs-1 p-4 fw-bold mt-5"
        style="background-color: #00b992 !important; color: #fff !important;"
        (click)="closeSurvey()"
      >
        {{ "survey.completed.done_btn" | translate }}
      </button>
    </section>
    } `,
  styles: `:host {
    width: 100%;
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5001;
  }`,
  imports: [
    SurveyHeaderComponent,
    SurveyStepperComponent,
    MatButtonModule,
    CloseSurveyComponent,
    TranslateModule,
  ],
})
export class SurveyComponent {
  public readonly isSurveyCompleted = this._store.selectSignal(selectIsCompleted);

  public readonly survey = this._store.selectSignal(selectSurvey);

  constructor(private readonly _store: Store<SurveyState>) {}

  public closeSurvey() {
    this._store.dispatch(SurveyPageActions.closeSurvey());
  }
}
