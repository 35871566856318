import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BusinessApiActions } from "../actions/business-api.actions";
import { tap } from "rxjs";
import { Branding } from "@menufy/models";
import { DOCUMENT } from "@angular/common";
import { StringUtil, ValidationUtil } from "@eqn/data-types";

@Injectable()
export class BrandingEffects {
  private readonly _applyBranding$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BusinessApiActions.getBusinessSuccess),
        tap((action) => this._setupBranding(action.business?.branding))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  private _setupBranding(branding?: Branding) {
    if (!branding) return;

    const _setDocumentStyleProperty = (property: string, value: string) =>
      this._document.documentElement.style.setProperty(property, value, "important");

    // set primary color
    if (branding.primaryColor && ValidationUtil.isHexColor(branding.primaryColor)) {
      _setDocumentStyleProperty("--primary-color", branding.primaryColor);

      _setDocumentStyleProperty(
        "--primary-color-hex",
        StringUtil.hexToRGB(branding.primaryColor) as string
      );
    }

    // set secondary color
    if (branding?.secondaryColor && ValidationUtil.isHexColor(branding.secondaryColor)) {
      _setDocumentStyleProperty("--secondary-color", branding.secondaryColor);

      _setDocumentStyleProperty(
        "--secondary-color-hex",
        StringUtil.hexToRGB(branding.secondaryColor) as string
      );
    }

    // set accent/supplementary color
    if (branding?.accentColor && ValidationUtil.isHexColor(branding.accentColor)) {
      _setDocumentStyleProperty("--accent-color", branding.accentColor);

      _setDocumentStyleProperty(
        "--accent-color-hex",
        StringUtil.hexToRGB(branding.accentColor) as string
      );
    }

    // set font
    if (branding?.font) {
      const font = `${branding.font}, sans-serif`; // set branding color and a fallback font if not present on the user device

      _setDocumentStyleProperty("--primary-font", font);
    }
  }
}
