export class ValidationUtil {
  static isNotEmpty<T = unknown>(value: T) {
    return value !== "" && value !== null && value !== undefined;
  }

  static isEmpty<T = unknown>(value: T) {
    return !ValidationUtil.isNotEmpty(value);
  }

  static isNullOrUndefined<T = unknown>(value: T) {
    return !ValidationUtil.isNotEmpty(value);
  }

  static isObject(value: unknown) {
    return (
      value != null &&
      (typeof value === "object" || typeof value === "function") &&
      !Array.isArray(value)
    );
  }

  static isNotEmptyObject(value: unknown): value is boolean {
    if (!ValidationUtil.isObject(value)) {
      return false;
    }

    for (const key in value as object) {
      // eslint-disable-next-line no-prototype-builtins
      if (value?.hasOwnProperty(key)) {
        return true;
      }
    }

    return false;
  }

  static isNumber(value: unknown) {
    return !isNaN(parseFloat(value as string)) && !isNaN(Number(value));
  }

  static isHexColor(value: unknown): value is string {
    const hexPattern = /^#?([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;

    return typeof value === "string" && hexPattern.test(value || "");
  }

  static isHexadecimal(value: unknown) {
    return /^(0x|0h)?[0-9A-F]+$/i.test((value as string) || "");
  }
}
