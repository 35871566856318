import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PopupEvent } from "@diners/common";
import { Nullable } from "@eqn/data-types";

export interface OpenPopupProps {
  data?: Nullable<unknown>;
  current?: Nullable<PopupEvent>;
  next?: Nullable<PopupEvent>;
}

export const AppPopupActions = createActionGroup({
  source: "App/Popup",
  events: {
    openPopup: props<OpenPopupProps>(),
    openRestrictionPopup: emptyProps(),
    openPreferencesPopup: emptyProps(),
    openTranslatePopup: emptyProps(),
    openIconsGuidePopup: emptyProps,
    openAllergensAlertPopup: emptyProps,
    clearPopups: emptyProps(),
  },
});
