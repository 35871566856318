import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "i18nKeyExists",
  standalone: true,
})
export class I18nKeyExistsPipe implements PipeTransform {
  constructor(private readonly _translateService: TranslateService) {}

  transform(key: string, prefix?: string, delimiter = "."): string | undefined {
    const i18Key = prefix ? prefix.concat(`${delimiter}${key}`) : key;

    const translation = this._translateService.instant(i18Key);

    return translation === i18Key ? undefined : translation;
  }
}
