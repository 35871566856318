import { FileRecord } from "../file-record";
import { AccountStatus } from "../constants/account.constants";
import { EntityIdField } from "../entity";

export interface User extends EntityIdField {
  name: string;
  firstName: string;
  lastName?: string;
  email: string;
  picture?: FileRecord;
  isEmailVerified: boolean;
  authId?: string;
  accountStatus: AccountStatus;
  createdAt: Date | string;
}
