import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "popular-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="fef8dca2-460f-4323-b73e-e16ecce33ed3"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 497.99 411.85"
  >
    <defs>
      <linearGradient
        id="f715451d-f484-4600-84e2-f64207fb3635"
        x1="99.47"
        y1="469.41"
        x2="403.07"
        y2="180.51"
        gradientTransform="matrix(1, 0, 0, -1, 0, 514)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#f44f5a" />
        <stop offset="1" stop-color="#e62536" />
      </linearGradient>
    </defs>
    <path
      d="M249.44,464.36a13.52,13.52,0,0,0,13.11,0C386.42,395.62,469.91,288.58,498.3,215.22,538.2,112.12,392.2-29.64,256,117.56c-136.2-147.2-282.2-5.45-242.3,97.65,28.39,73.36,111.88,180.41,235.75,249.14Z"
      transform="translate(-7 -54.21)"
      fill="url(#f715451d-f484-4600-84e2-f64207fb3635)"
    />
  </svg>`,
})
export class PopularIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
