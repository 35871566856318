import { Component, inject, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { DinersPlausibleEvent, plausibleTrackEvent } from "@menufy/plausible";
import { LocaleService } from "@eqn/angular-i18n";

@Component({
  standalone: true,
  imports: [RouterOutlet],
  selector: "menufy-web-root",
  template: ` <router-outlet /> `,
})
export class AppComponent implements OnInit {
  private readonly _locale = inject(LocaleService);

  ngOnInit() {
    // send event to plausible for detected lang
    plausibleTrackEvent(DinersPlausibleEvent.DETECTED_LANGUAGE, {
      props: { detected_lang: this._locale.getDefaultLocale().name },
    });
  }
}
