import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, of } from "rxjs";
import { catchError, exhaustMap } from "rxjs/operators";
import { BusinessApiActions } from "../../../business/state";
import { MenusApiService } from "../../services/menu-api.service";
import { MenuApiActions } from "../actions/menu-api.actions";
import { MenuPageActions } from "../actions";

@Injectable()
export class MenuEffects {
  private readonly _getMenusOnBusinessChange$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BusinessApiActions.getBusinessSuccess),
      exhaustMap(({ business }) =>
        this._menusApi.getMenusByBusiness({ business: business.id }).pipe(
          map((paginationResult) =>
            MenuApiActions.getMenusSuccess({
              result: paginationResult,
              reset: true,
            })
          ),
          catchError((error: unknown) => of(MenuApiActions.getMenusFailed({ error })))
        )
      )
    )
  );

  private readonly _getMenus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MenuPageActions.getMenus),
      exhaustMap((listMenusParams) =>
        this._menusApi.getMenusByBusiness(listMenusParams).pipe(
          map((paginationResult) =>
            MenuApiActions.getMenusSuccess({
              result: paginationResult,
            })
          ),
          catchError((error: unknown) => of(MenuApiActions.getMenusFailed({ error })))
        )
      )
    )
  );

  constructor(private readonly _actions$: Actions, private readonly _menusApi: MenusApiService) {}
}
