import { PreferencesState } from "../prefs.state";
import { SetPreferencesActionProps } from "../actions";

const setPreferencesReducer = (state: PreferencesState, action: SetPreferencesActionProps) => {
  state.userHasPreferences =
    (action.selectedAllergens?.length ?? 0) + (action.selectedDiets?.length ?? 0);
  Object.assign(state, action);
};

export default {
  setPreferencesReducer,
};
