import { CloseGreenIconComponent } from "@menufy/icons";
import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "menufy-web-allergens-alert",
  standalone: true,
  templateUrl: "./allergens-alert.html",
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    TranslateModule,
    CloseGreenIconComponent,
  ],
})
export class AllergensAlertComponent {
  constructor(private readonly _dialogRef: MatDialogRef<AllergensAlertComponent>) {}

  public close() {
    this._dialogRef.close();
  }
}
