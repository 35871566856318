import { Injectable } from "@angular/core";
import { FALLBACK_LOCALE, LOCALE, LOCALE_LANG_ID, LOCALES_LANG_IDS } from "./locale.config";
import { I18nDetectionPlatform, I18nUtil } from "@eqn/data-types";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class BrowserLocaleService {
  constructor(private readonly _translate: TranslateService) {
    this._setAutoTranslationModeOnHTML();
  }

  public getDetectedLocale() {
    const detectedLocale = {
      isSupported: true,
      isFallback: true,
      locale: FALLBACK_LOCALE,
    };

    const preferredLocales = I18nUtil.detectLocales({
      platform: I18nDetectionPlatform.BROWSER,
      langIdOnly: true,
    });

    if (!preferredLocales) {
      return detectedLocale;
    }

    let detectedLocaleLangId = preferredLocales?.[0];

    if (!detectedLocaleLangId) {
      return detectedLocale;
    }

    // if `Catalan`, `Basque` or `Galician` detected, map to `Spanish`
    if (
      detectedLocaleLangId === "ca" ||
      detectedLocaleLangId === "eu" ||
      detectedLocaleLangId === "gl"
    ) {
      detectedLocaleLangId = LOCALE.es.langId;
    }

    const isSupportedLocale = LOCALES_LANG_IDS.some((langId) => detectedLocaleLangId === langId);

    if (!isSupportedLocale) {
      detectedLocale.isSupported = false;
      return detectedLocale;
    }

    detectedLocale.isFallback = false;
    detectedLocale.locale = LOCALE[detectedLocaleLangId as LOCALE_LANG_ID];

    return detectedLocale;
  }

  private _setAutoTranslationModeOnHTML() {
    const detectedLocale = this.getDetectedLocale();

    if (detectedLocale.isSupported && !detectedLocale.isFallback) {
      this._disableAutoTranslationModeOnHTML();
      return;
    }

    const htmlElement = document.documentElement;

    htmlElement.setAttribute("lang", "auto");
  }

  private _disableAutoTranslationModeOnHTML() {
    const htmlElement = document.documentElement;
    const bodyElement = document.body;
    const userAgent = navigator.userAgent;

    // if not visible on the platform apart from runtime in browser
    if (!htmlElement) {
      return;
    }

    const chrome = userAgent.indexOf("Chrome") > -1;

    const firefox = userAgent.indexOf("Firefox") > -1;

    let safari = userAgent.indexOf("Safari") > -1;

    if (chrome && safari) {
      safari = false;
    }

    this._translate.onLangChange.subscribe((evt) => {
      const { lang } = evt;

      // set browser auto language
      bodyElement.dataset["language"] = lang;
      bodyElement.dataset["translateClass"] = "disabled";
      bodyElement.dataset["navigatorLanguage"] = this._translate.getBrowserCultureLang();

      // set meta tag for translators
      const metaTags = htmlElement.querySelectorAll("meta");

      // translation attribute context
      let translCtx: string | null = "deny";

      metaTags.forEach((tag) => {
        if (tag.name.match(/google/) || chrome || safari) {
          translCtx = tag.getAttribute("content");
        } else if (firefox) {
          translCtx = "metaAttribute";
        }
      });

      htmlElement.setAttributeNS(translCtx, "notranslate", lang);
    });
  }
}
