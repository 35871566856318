import { createFeature, createReducer } from "@ngrx/store";
import { immerOn as on } from "ngrx-immer/store";

import { prefsInitialState } from "./prefs.state";
import { PrefsActions } from "./actions";
import prefsReducers from "./reducers/prefs.reducers";

const PREFS_FEATURE_NAME = "preferences";

export const prefsFeature = createFeature({
  name: PREFS_FEATURE_NAME,
  reducer: createReducer(
    prefsInitialState,
    on(PrefsActions.setPreferences, prefsReducers.setPreferencesReducer)
  ),
});

export const preferencesSlice = {
  name: prefsFeature.name,
  reducer: prefsFeature.reducer,
} as const;
