import { createActionGroup, props } from "@ngrx/store";
import { FindBusinessParams } from "../../interfaces/find-business";

export type GetBusinessActionProps = FindBusinessParams;

export const BusinessPageActions = createActionGroup({
  source: "Business/Page",
  events: {
    getBusiness: props<GetBusinessActionProps>(),
  },
});
