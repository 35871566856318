import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { PopupEvent, SwipeDownToCloseDirective } from "@diners/common";
import { LOCALE, LOCALE_LANG_ID, LocaleService, TranslationLanguage } from "@eqn/angular-i18n";
import { CloseIconComponent, VCheckIconComponent } from "@menufy/icons";
import { DinersPlausibleEvent, plausibleTrackEvent } from "@menufy/plausible";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { AppPopupActions } from "../../store/actions/app-popup.actions";
import { selectPrevPopup } from "../../store/app-popup.selectors";
import { AppPopupState } from "../../store/app-popup.state";

@Component({
  selector: "menufy-web-lang-list",
  standalone: true,
  imports: [MatIconModule, MatDividerModule, MatListModule, VCheckIconComponent],
  template: ` <mat-list>
    @for (lang of languages; track $index) {
    <mat-list-item>
      <div (click)="languageSelected.emit(lang)" class="d-flex flex-row align-items-center">
        <span class="flex-grow-1">{{ lang.name }}</span>

        @if (lang.langId === currentLangId) {
        <v-check-icon width="21px" height="21px"></v-check-icon>
        }
      </div>
    </mat-list-item>
    <mat-divider />
    }
  </mat-list>`,
  styles: `
    mat-list-item {
      cursor: pointer;
    }`,
})
export class TranslateLangListComponent {
  @Input({ required: true }) languages!: TranslationLanguage[];

  @Input({ required: true }) currentLangId!: string;

  @Output() languageSelected = new EventEmitter<TranslationLanguage>();
}

@Component({
  selector: "menufy-web-translate",
  standalone: true,
  imports: [
    MatDividerModule,
    SwipeDownToCloseDirective,
    TranslateModule,
    CloseIconComponent,
    TranslateLangListComponent,
  ],
  templateUrl: "./translate.component.html",
  styleUrls: ["./translate.component.scss"],
})
export class TranslateComponent {
  public readonly availableLanguages = this._localeService
    .availableLanguages as TranslationLanguage[];

  public readonly automaticTranslations = this._localeService
    .automaticTranslations as TranslationLanguage[];

  public currentLangId = this._localeService.currentLocale.langId;

  public readonly _prevPopup = this._store.selectSignal(selectPrevPopup);

  constructor(
    private readonly _bottomSheetRef: MatBottomSheetRef<TranslateComponent>,
    private readonly _localeService: LocaleService,
    private readonly _store: Store<AppPopupState>
  ) {}

  public close() {
    const prevPopup = this._prevPopup();

    if (prevPopup) {
      this._store.dispatch(
        AppPopupActions.openPopup({
          current: PopupEvent.TRANSLATE,
          next: prevPopup,
        })
      );
    }

    this._bottomSheetRef.dismiss();
  }

  public changeLanguage(language: TranslationLanguage) {
    this.currentLangId = language.langId;

    this._localeService.setLocale(language.langId);

    // send event to plausible for translation lang
    plausibleTrackEvent(DinersPlausibleEvent.TRANSLATION, {
      props: { translated_lang: LOCALE[language.langId as LOCALE_LANG_ID].name },
    });

    this.close();
  }
}
