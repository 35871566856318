import { ApplicationRef, ComponentRef, createComponent, Injectable } from "@angular/core";
import { SurveyComponent } from "../survey.component";
import { SurveyBannerComponent } from "../banner/survey-banner";

@Injectable({
  providedIn: "root",
})
export class SurveyPageService {
  private _surveyCmpRef?: ComponentRef<SurveyComponent>;

  private _bannerRef?: ComponentRef<SurveyBannerComponent>;

  constructor(private readonly _appRef: ApplicationRef) {}

  public showSurveyBanner() {
    this.closeSurveyPage();

    this._bannerRef = createComponent(SurveyBannerComponent, {
      environmentInjector: this._appRef.injector,
    });

    document.body.appendChild(this._bannerRef.location.nativeElement);

    this._appRef.attachView(this._bannerRef.hostView);

    this._bannerRef.changeDetectorRef.detectChanges();
  }

  public showSurveyPage() {
    this._surveyCmpRef = createComponent(SurveyComponent, {
      environmentInjector: this._appRef.injector,
    });

    this._bannerRef?.destroy();

    document.body.appendChild(this._surveyCmpRef.location.nativeElement);

    this._appRef.attachView(this._surveyCmpRef.hostView);

    this._surveyCmpRef.changeDetectorRef.detectChanges();
  }

  public closeSurveyPage() {
    this._bannerRef?.destroy();
    this._surveyCmpRef?.destroy();
  }
}
