import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "eggs-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f6502179-f933-4a1b-b760-1a2b5b34fb5e"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84Zm19.35-40.3-7.16,4.61C31.06,32.23,38.81,22.05,48,22.05c8.68,0,16.06,9.05,18.89,19.84L55.22,34.74a3.15,3.15,0,0,0-2.63-.3,3.07,3.07,0,0,0-1.91,1.8l-3.92,9.82-7.15-6.52a3.13,3.13,0,0,0-3.8-.31Z"
      transform="translate(-3.41 -16.46)"
      fill="#ffcc02"
    />
    <path
      d="M68.12,51.26C68.12,65.6,59.11,74,48,74S28.1,65.8,27.89,51.78l9.29-6,8.72,7.93a3.12,3.12,0,0,0,5-1.16L55.11,42l13,7.94c0,.44,0,.86,0,1.3Z"
      transform="translate(-3.41 -16.46)"
      fill="#ffcc02"
    />
  </svg>`,
})
export class EggsOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
