import { createActionGroup, props } from "@ngrx/store";
import { GetSurveyResponse } from "../../interfaces/get-survey-response";
import { SubmittedSurveyApiResponse } from "../../interfaces/submit-survey";

export type GetSurveySuccessActionProps = GetSurveyResponse;

export interface GetSurveyFailedActionProps {
  error: unknown;
}

export type SubmitSurveySuccessActionProps = SubmittedSurveyApiResponse;

export type SubmitSurveyFailedActionProps = GetSurveyFailedActionProps;

export const SurveyApiActions = createActionGroup({
  source: "Survey/Api",
  events: {
    getSurveySuccess: props<GetSurveySuccessActionProps>(),
    getSurveyFailed: props<GetSurveyFailedActionProps>(),
    submitSurveySuccess: props<SubmitSurveySuccessActionProps>(),
    submitSurveyFailed: props<GetSurveyFailedActionProps>(),
  },
});
