import { surveyFeature } from "./survey.slice";

export const {
  selectSurvey,
  selectStepper,
  selectIsStarted,
  selectIsCompleted,
  selectSurveyState,
  selectCanParticipate,
  selectIsInitialized,
  selectError,
} = surveyFeature;
