import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "lotra-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="e64e0d11-47c4-4fd9-a79a-028211b7785f"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 119.28 50"
  >
    <path d="M0,25A25,25,0,0,1,25,0H94.28a25,25,0,0,1,0,50H25A25,25,0,0,1,0,25" fill="#ef826d" />
    <path
      d="M62.11,14.58H52.3v3.58h3.12v17H59v-17h3.11Zm9.38,10.11c1.83-.5,3.11-2.09,3.11-5.05a5,5,0,0,0-5.1-5.06H63.39V35.14h3.77V26.52h1.58c1.18,0,1.85.95,2,2.23v6.39H74.6v-5.6c0-3.89-2-4.7-3.11-4.85m-4.33-6.88h1.67a2.17,2.17,0,0,1,2,2.41c0,1.89-1,2.77-2.17,2.77H67.17ZM85.51,35.15h3.77L85,14.58H79.79L75.53,35.15H79.3l.57-3.06h5.07Zm-5.07-6.08,2-10.51,2,10.51ZM43.73,14.42c-4.42,0-8,3-8,8.26s3.59,8.25,8,8.25,8-3,8-8.25-3.58-8.26-8-8.26m0,13c-2.37,0-4.3-1.56-4.3-4.77s1.93-4.77,4.3-4.77S48,19.46,48,22.68s-1.93,4.77-4.3,4.77M34.78,14.58v3.33h.76a6.69,6.69,0,0,1,1.81-2.64v-.69Z"
      fill="#111056"
    />
    <path
      d="M45.71,33.65c-4.55,0-6.24-2.14-11.86-2.25V14.58H30V35.71a8,8,0,0,1,3.39-.65c4.89,0,7,2.36,12.51,2.36A11.84,11.84,0,0,0,51.73,36V31.4s-1.85,2.25-6,2.25"
      fill="#111056"
    />
  </svg>`,
})
export class LotraIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
