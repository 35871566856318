import { createActionGroup, props } from "@ngrx/store";
import { Menu, PaginationResult } from "@menufy/models";

export interface GetMenusSuccessActionProps {
  result: PaginationResult<Menu>;
  reset?: boolean;
}

export interface GetMenusFailedActionProps {
  error: unknown;
}

export const MenuApiActions = createActionGroup({
  source: "Menu/Api",
  events: {
    getMenusSuccess: props<GetMenusSuccessActionProps>(),
    getMenusFailed: props<GetMenusFailedActionProps>(),
  },
});
