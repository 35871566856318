import { dishFeature } from "./dish.slice";
import { createSelector } from "@ngrx/store";
import { selectPreferencesState } from "../../personalized-dining/state";
import { applyDishPreferencesFilters, findDishFromMenu } from "@diners/common";
import { selectMenus } from "../../menus/state";

// selector for preferences applied dish
export const selectPreferredDish = createSelector(
  selectMenus,
  dishFeature.selectDish,
  selectPreferencesState,
  (menu, dish, prefs) => {
    if (!dish) {
      return;
    }

    const unfilteredDish = findDishFromMenu(menu, dish.menu, dish.id);

    if (!unfilteredDish) {
      return;
    }

    return applyDishPreferencesFilters(unfilteredDish, prefs, {
      filterAllergensAndDiets: false,
      filterOptionsItems: true,
    });
  }
);

export const { selectDish, selectDishId, selectDishState } = dishFeature;
