import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "translate-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a197e2e4-3f98-4cf3-8532-cdfd8503c91c"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 481.52 481.53"
  >
    <path
      d="M377.72,372.28,358.07,333l-19.65,39.29h39.3Z"
      transform="translate(-15.23 -15.23)"
      fill="#1d1c1b"
    />
    <path
      d="M341.33,219.38h-20.5v30.93a70.61,70.61,0,0,1-70.52,70.52H219.38V454.46a42.35,42.35,0,0,0,42.3,42.3H454.45a42.35,42.35,0,0,0,42.3-42.3V261.69a42.36,42.36,0,0,0-42.3-42.31H341.33Zm-52.51,189,56.63-113.26a14.11,14.11,0,0,1,25.24,0l56.63,113.26A14.11,14.11,0,0,1,402.08,421l-10.26-20.52H324.31L314.05,421a14.11,14.11,0,0,1-25.24-12.62ZM167.29,134.06H140.55q2.63,21,13.37,35.2,10.72-14.2,13.37-35.2"
      transform="translate(-15.23 -15.23)"
      fill="#1d1c1b"
    />
    <path
      d="M250.31,292.62a42.35,42.35,0,0,0,42.3-42.3V57.54a42.36,42.36,0,0,0-42.3-42.31H57.54A42.36,42.36,0,0,0,15.23,57.54V250.31a42.35,42.35,0,0,0,42.3,42.3h20V334.9A14.11,14.11,0,0,0,91.63,349a13.37,13.37,0,0,0,1.42-.07c2.25-.23,52.56-5.92,77.61-56.32Zm-88.87-80.94q-3.85-2.06-7.52-4.46c-2.44,1.6-4.95,3.08-7.52,4.46a114.8,114.8,0,0,1-49.11,13,14.11,14.11,0,0,1,0-28.22,87.35,87.35,0,0,0,34.85-9.15c-9.91-12.69-17.72-29.91-20-53.24H97.29a14.11,14.11,0,0,1,0-28.22h42.52V97.3a14.11,14.11,0,0,1,28.22,0v8.54h42.52a14.11,14.11,0,0,1,0,28.22H195.68c-2.26,23.33-10.07,40.55-20,53.24a87.31,87.31,0,0,0,34.91,9.15,14.11,14.11,0,0,1-.06,28.22,114.8,114.8,0,0,1-49.11-13M359.14,96a14.1,14.1,0,1,0,19.94-20L375,72a99.29,99.29,0,0,1,93.23,99,14.11,14.11,0,0,0,28.22,0A127.51,127.51,0,0,0,374.73,43.69l4.36-4.36a14.11,14.11,0,1,0-19.95-19.95L330.83,47.7a14.11,14.11,0,0,0,0,20L359.14,96ZM152.58,415.77a14.11,14.11,0,0,0-20,20l4.05,4a99.29,99.29,0,0,1-93.23-99,14.11,14.11,0,0,0-28.22,0A127.51,127.51,0,0,0,137,468l-4.36,4.36a14.1,14.1,0,1,0,19.94,19.95L180.87,464a14.11,14.11,0,0,0,0-19.95l-28.31-28.31Z"
      transform="translate(-15.23 -15.23)"
      fill="#1d1c1b"
    />
  </svg>`,
})
export class TranslateIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
