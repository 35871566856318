<section swipeDownToClose (popupClosed)="sendPlausibleEvent()">
  <!-- Translate Button -->
  <div class="translate-button-wrapper">
    <button (click)="openTranslatePopup()" color="light" mat-flat-button>
      {{ "translate" | translate }}
    </button>
  </div>

  <section class="restriction-popup-container pb-4">
    <!-- Dialog Content -->
    <section class="container px-5">
      <section class="row mx-auto" style="width: inherit">
        <div
          class="col-12 col-md-12 text-center d-flex flex-row justify-content-center mt-5 restriction-icons-container"
        >
          <div>
            <gluten-icon height="45" width="45" />
          </div>

          <div>
            <vegetarian-icon height="45" width="45" />
          </div>

          <div>
            <peanuts-icon height="45" width="45" />
          </div>
        </div>
        <div class="col-12 col-md-12 text-center mt-3">
          <h2>
            <b>{{ "restrictions_title" | translate }}</b>
          </h2>
          <p>{{ "restrictions_subtitle" | translate }}</p>
        </div>
        <div class="col-12 col-md-12 px-3" style="margin-top: 2rem">
          <button
            (click)="skip()"
            class="fs-1 p-4 fw-bold w-100 mb-3 secondary-btn plausible-event-name=Preferences+Skip+Button"
            mat-flat-button
            type="button"
          >
            {{ "skip_btn" | translate }}
          </button>
          <button
            (click)="submit()"
            class="fs-1 p-4 fw-bold w-100 mb-2 primary-btn plausible-event-name=Preferences+Not+Skipped"
            mat-flat-button
            type="submit"
          >
            {{ "do_btn" | translate }}
          </button>
        </div>
      </section>
    </section>

    <!-- Close icon -->

    <div class="close-icon plausible-event-name=Preferences+Skip+Drop+Menu">
      <close-icon (click)="skip()" />
    </div>

    <!-- border -->
    <div class="popup-top-border-wrapper">
      <span></span>
    </div>
  </section>
</section>
