import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "sesame-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="b2524cee-eddc-49f0-b868-2372a474323c"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84Z"
      transform="translate(-3.41 -16.46)"
      fill="#d0b6a1"
    />
    <path
      d="M23.28,36.07s-5.5,7.22-5.59,14,4.51,12.72,9.84,12.27S41.88,50,27.25,40.17a5.52,5.52,0,0,1-1.43-1.38,10.85,10.85,0,0,0-2.53-2.71Zm30.13-5.86A22.5,22.5,0,0,0,47.91,33c-.75.53-1.52,1-2.32,1.5a16.41,16.41,0,0,0-7.88,10.78C36,53.58,42,57.91,47.09,56.92s12.55-9.86,7.42-21.3a6.62,6.62,0,0,1-.57-2,22.74,22.74,0,0,0-.53-3.38M78.32,45.32a12.64,12.64,0,0,0-4.38-.08,21.46,21.46,0,0,1-3.4.17c-2.64-.09-8,.29-11.71,4.29-5.14,5.5-2.35,13.81,2.62,15.61s16.23-.86,15.17-14.51A3.13,3.13,0,0,1,77,49a9.83,9.83,0,0,0,1.33-3.67Z"
      transform="translate(-3.41 -16.46)"
      fill="#d0b6a1"
    />
  </svg>`,
})
export class SesameOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
