import { AdjustIconComponent } from "./adjust";
import { AlproIconComponent } from "./alpro";
import { BeyondMeatIconComponent } from "./beyond-meat";
import { CeleryOptionalIconComponent } from "./celery-optional";
import { CeleryIconComponent } from "./celery";
import { CrustaceansOptionalIconComponent } from "./crustaceans-optional";
import { CrustaceansIconComponent } from "./crustaceans";
import { CupperIconComponent } from "./cupper";
import { EggsOptionalIconComponent } from "./eggs-optional";
import { EggsIconComponent } from "./eggs";
import { FacebookIconComponent } from "./facebook";
import { FishOptionalIconComponent } from "./fish-optional";
import { FishIconComponent } from "./fish";
import { FollowHeartIconComponent } from "./follow-heart";
import { GlutenOptionalIconComponent } from "./gluten-optional";
import { GlutenIconComponent } from "./gluten";
import { GoogleIconComponent } from "./google";
import { HeuraIconComponent } from "./heura";
import { InfoIconIconComponent } from "./info-icon";
import { InstagramIconComponent } from "./instagram";
import { KomvidaIconComponent } from "./komvida";
import { AlhambraIconComponent } from "./alhambra";
import { FritzKolaIconComponent } from "./fritz-kola";
import { LotraIconComponent } from "./lotra";
import { LupinsOptionalIconComponent } from "./lupins-optional";
import { LupinsIconComponent } from "./lupins";
import { MilkOptionalIconComponent } from "./milk-optional";
import { MilkIconComponent } from "./milk";
import { MolluscsOptionalIconComponent } from "./molluscs-optional";
import { MolluscsIconComponent } from "./molluscs";
import { MustardOptionalIconComponent } from "./mustard-optional";
import { MustardIconComponent } from "./mustard";
import { NewIconComponent } from "./new";
import { NutsOptionalIconComponent } from "./nuts-optional";
import { NutsIconComponent } from "./nuts";
import { OatlyIconComponent } from "./oatly";
import { PeanutsOptionalIconComponent } from "./peanuts-optional";
import { PeanutsIconComponent } from "./peanuts";
import { PinkAlbatrossIconComponent } from "./pink-albatross";
import { PopularIconComponent } from "./popular";
import { RateUsIconComponent } from "./rate-us";
import { RecommendedIconComponent } from "./recommended";
import { SesameOptionalIconComponent } from "./sesame-optional";
import { SesameIconComponent } from "./sesame";
import { SoyOptionalIconComponent } from "./soy-optional";
import { SoyIconComponent } from "./soy";
import { SpicyIconComponent } from "./spicy";
import { SpotifyIconComponent } from "./spotify";
import { SulphitesOptionalIconComponent } from "./sulphites-optional";
import { SulphitesIconComponent } from "./sulphites";
import { SuperSpicyIconComponent } from "./super-spicy";
import { TiktokIconComponent } from "./tiktok";
import { TranslateIconComponent } from "./translate";
import { TripadvisorIconComponent } from "./tripadvisor";
import { VackaIconComponent } from "./vacka";
import { VeganOptionalIconComponent } from "./vegan-optional";
import { VeganIconComponent } from "./vegan";
import { VegetarianOptionalIconComponent } from "./vegetarian-optional";
import { VegetarianIconComponent } from "./vegetarian";
import { VerySpicyIconComponent } from "./very-spicy";
import { VivesoyIconComponent } from "./vivesoy";
import { YosoyIconComponent } from "./yosoy";
import { CloseIconComponent } from "./close";
import { DotsIconIconComponent } from "./dots-icon";
import { KetoIconComponent } from "./keto";
import { PaleoIconComponent } from "./paleo";
import { FlexitarianIconComponent } from "./flexitarian";
import { PescetarianIconComponent } from "./pescetarian";
import { CloseGreenIconComponent } from "./close-green";
import { FlexitarianOptionalIconComponent } from "./flexitarian-optional";
import { PescetarianOptionalIconComponent } from "./pescetarian-optional";
import { VCheckIconComponent } from "./v-check";
export const ICON_COMPONENT_MAP = {
  adjust: AdjustIconComponent,
  alpro: AlproIconComponent,
  beyondMeat: BeyondMeatIconComponent,
  celeryOptional: CeleryOptionalIconComponent,
  celery: CeleryIconComponent,
  crustaceansOptional: CrustaceansOptionalIconComponent,
  crustaceans: CrustaceansIconComponent,
  cupper: CupperIconComponent,
  eggsOptional: EggsOptionalIconComponent,
  eggs: EggsIconComponent,
  facebook: FacebookIconComponent,
  fishOptional: FishOptionalIconComponent,
  fish: FishIconComponent,
  followHeart: FollowHeartIconComponent,
  glutenOptional: GlutenOptionalIconComponent,
  gluten: GlutenIconComponent,
  google: GoogleIconComponent,
  heura: HeuraIconComponent,
  infoIcon: InfoIconIconComponent,
  instagram: InstagramIconComponent,
  komvida: KomvidaIconComponent,
  alhambra: AlhambraIconComponent,
  fritzKola: FritzKolaIconComponent,
  lotra: LotraIconComponent,
  lupinsOptional: LupinsOptionalIconComponent,
  lupins: LupinsIconComponent,
  milkOptional: MilkOptionalIconComponent,
  milk: MilkIconComponent,
  molluscsOptional: MolluscsOptionalIconComponent,
  molluscs: MolluscsIconComponent,
  mustardOptional: MustardOptionalIconComponent,
  mustard: MustardIconComponent,
  new: NewIconComponent,
  nutsOptional: NutsOptionalIconComponent,
  nuts: NutsIconComponent,
  oatly: OatlyIconComponent,
  peanutsOptional: PeanutsOptionalIconComponent,
  peanuts: PeanutsIconComponent,
  pinkAlbatross: PinkAlbatrossIconComponent,
  popular: PopularIconComponent,
  rateUs: RateUsIconComponent,
  recommended: RecommendedIconComponent,
  sesameOptional: SesameOptionalIconComponent,
  sesame: SesameIconComponent,
  soyOptional: SoyOptionalIconComponent,
  soy: SoyIconComponent,
  spicy: SpicyIconComponent,
  spotify: SpotifyIconComponent,
  sulphitesOptional: SulphitesOptionalIconComponent,
  sulphites: SulphitesIconComponent,
  superSpicy: SuperSpicyIconComponent,
  tiktok: TiktokIconComponent,
  translate: TranslateIconComponent,
  tripadvisor: TripadvisorIconComponent,
  vacka: VackaIconComponent,
  veganOptional: VeganOptionalIconComponent,
  vegan: VeganIconComponent,
  vegetarianOptional: VegetarianOptionalIconComponent,
  vegetarian: VegetarianIconComponent,
  verySpicy: VerySpicyIconComponent,
  vivesoy: VivesoyIconComponent,
  yosoy: YosoyIconComponent,
  close: CloseIconComponent,
  dotsIcon: DotsIconIconComponent,
  keto: KetoIconComponent,
  paleo: PaleoIconComponent,
  flexitarian: FlexitarianIconComponent,
  pescetarian: PescetarianIconComponent,
  closeGreen: CloseGreenIconComponent,
  flexitarianOptional: FlexitarianOptionalIconComponent,
  pescetarianOptional: PescetarianOptionalIconComponent,
  vCheck: VCheckIconComponent,
};
