import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "pescetarian-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a6a5106f-9b81-4111-9bb2-fc0ef7ccd6ac"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.13a44.68,44.68,0,0,1,0,63.1Z"
      transform="translate(-3.41 -16.45)"
      fill="#1e3e64"
    />
    <path
      d="M16.46,79.54a44.65,44.65,0,0,1,0-63.09l2.12,2.13a41.66,41.66,0,0,0,0,58.84Z"
      transform="translate(-3.41 -16.45)"
      fill="#1e3e64"
    />
    <path
      d="M65.05,38.37a16.84,16.84,0,0,1-1.19,6.5,13.67,13.67,0,0,1-3.39,4.93A15.26,15.26,0,0,1,55.05,53a23.5,23.5,0,0,1-7.66,1.11H41.93V71.36a.79.79,0,0,1-.17.52,1.1,1.1,0,0,1-.52.36,5.34,5.34,0,0,1-1,.22,13.22,13.22,0,0,1-3.08,0,5.85,5.85,0,0,1-1-.22,1,1,0,0,1-.52-.36.91.91,0,0,1-.15-.52V27.05a2.57,2.57,0,0,1,.78-2.11,2.7,2.7,0,0,1,1.74-.63H48.35c1,0,2,0,3,.13a27.76,27.76,0,0,1,3.38.56,13.86,13.86,0,0,1,4,1.59,12.64,12.64,0,0,1,3.43,2.88,12.4,12.4,0,0,1,2.17,4A15.3,15.3,0,0,1,65.05,38.37Zm-6.71.52a10,10,0,0,0-1.1-4.9,7.65,7.65,0,0,0-2.71-2.93,9.14,9.14,0,0,0-3.34-1.22,21.68,21.68,0,0,0-3.36-.26h-5.9V48.84h5.75a13.51,13.51,0,0,0,4.81-.74A9.29,9.29,0,0,0,55.7,46a8.77,8.77,0,0,0,2-3.16A11.68,11.68,0,0,0,58.34,38.89Z"
      transform="translate(-3.41 -16.45)"
      fill="#1e3e64"
    />
  </svg>`,
})
export class PescetarianOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
