import { createActionGroup, props } from "@ngrx/store";
import { ListMenusParams } from "../../interfaces/find-menus";

export type GetMenusActionProps = ListMenusParams;

export const MenuPageActions = createActionGroup({
  source: "Menu/Page",
  events: {
    getMenus: props<GetMenusActionProps>(),
  },
});
