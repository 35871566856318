import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "milk-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f4dbf47a-2219-4b67-991d-5152c068d8a7"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#00b5ff" />
    <path
      d="M30.46,74h16V38.29h-16Zm2.82-24.66v-1.5H43.69v3H33.28Zm0,8v-1.5H43.69v3H33.28ZM49.5,37.41V74h16V37.39L57.7,29.21Zm-2.12-2.12,6.72-6.72H39.3l-6.72,6.72Zm-7.2-13.24h16v3.52h-16Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class MilkIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
