import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "alpro-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="edaefc72-8ebc-4b59-897e-c5c5d6d5e65f"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 134 50"
  >
    <path d="M109,50H25A25,25,0,0,1,25,0h84a25,25,0,0,1,0,50" fill="#f6f6f6" />
    <path
      d="M45.35,16.81H42.76v1.43a8,8,0,0,0-4.92-1.7,8.6,8.6,0,0,0-8.35,8.83,8.6,8.6,0,0,0,8.35,8.83,8,8,0,0,0,5.08-1.83,2.75,2.75,0,0,0,2.59,1.83H48.1V19.56a2.75,2.75,0,0,0-2.75-2.75M39,29.52a4.15,4.15,0,0,1-.13-8.29H39a4.15,4.15,0,0,1,0,8.29m56.35-13a9.12,9.12,0,0,0-9.13,9.12h0a9.12,9.12,0,0,0,9.12,9.13h0a9.13,9.13,0,0,0,0-18.25m0,13.25a4.13,4.13,0,1,1,3.93-4.13,4,4,0,0,1-3.93,4.13M67.44,16.54a8,8,0,0,0-5.17,1.9,2.74,2.74,0,0,0-2.5-1.63H57.18V39.48a2.75,2.75,0,0,0,2.75,2.75h2.59V32.5a8,8,0,0,0,4.92,1.7,8.59,8.59,0,0,0,8.34-8.83,8.59,8.59,0,0,0-8.34-8.83m-1.11,13a4.15,4.15,0,0,1-.13-8.29h.13a4.15,4.15,0,0,1,0,8.29m-13.86-22H49.89V31.45a2.75,2.75,0,0,0,2.75,2.75h2.58V10.29a2.75,2.75,0,0,0-2.75-2.75"
      fill="#232878"
    />
    <path
      d="M89.76,16.86c-4.4-1.41-6.71.49-7.78,1.67a2.75,2.75,0,0,0-2.55-1.72H76.85V31.45A2.75,2.75,0,0,0,79.6,34.2h2.58V25.14c.13-3.48,1.92-4.07,3.67-3.8a10.42,10.42,0,0,1,3.91-4.48"
      fill="#232878"
    />
    <path
      d="M65.47,14.64a13.9,13.9,0,0,0,10-5.83c-4.71.46-7.41-2.58-11.5-2.58s-6.79,4.08-6.79,4.08,3.13,4.33,8.29,4.33"
      fill="#8ce61e"
    />
    <path
      d="M103.21,17.5h.22l.2.3h.26l-.23-.34a.36.36,0,0,0,.22-.33c0-.24-.18-.38-.46-.38H103V17.8h.24Zm0-.55h.2c.14,0,.22.06.22.18s-.08.18-.22.18h-.2Z"
      fill="#232878"
    />
    <path
      d="M103.38,18.4a1.13,1.13,0,1,0-1.13-1.13,1.13,1.13,0,0,0,1.13,1.13m0-2a.88.88,0,0,1,0,1.75.88.88,0,1,1,0-1.75"
      fill="#232878"
    />
  </svg>`,
})
export class AlproIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
