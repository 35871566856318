import { ValidationUtil } from "./validation-util";

export enum I18nDetectionPlatform {
  BROWSER = "browser",
  SERVER = "server",
  HEADER = "header",
  UNKNOWN = "unknown",
}

interface DetectLocaleOptions {
  langIdOnly?: boolean;
  platform?: I18nDetectionPlatform;
}

function detectPlatform() {
  if (globalThis?.window?.navigator) {
    return I18nDetectionPlatform.BROWSER;
  }

  return I18nDetectionPlatform.SERVER;
}

export class I18nUtil {
  static detectLocales(options: DetectLocaleOptions = {}): string[] | undefined {
    const config: DetectLocaleOptions = Object.assign(
      { platform: detectPlatform(), langIdOnly: false },
      options || {}
    );

    let detectedLocales: string[] = [];

    if (ValidationUtil.isNotEmpty(navigator.languages)) {
      detectedLocales = navigator.languages as string[];
    } else if (ValidationUtil.isNotEmpty(navigator.language)) {
      detectedLocales.push(navigator.language);
    } else {
      return;
    }

    return detectedLocales.map((locale) => (config.langIdOnly ? locale.split(/-|_/)[0] : locale));
  }
}
