import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "very-spicy-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="a9c1a2c8-043f-4647-b176-1426f1e35eec"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 470.74 256.55"
  >
    <path
      d="M246.59,343.9A225.46,225.46,0,0,1,205,346.14c-12.65-.56-25.13-2.26-37-7-10.83-4.33-20.16-10.67-25.27-21.58-7.82-16.71-13.33-34.15-14-52.76a104.62,104.62,0,0,0-7.11-35.09c-2.74-7-5.58-13.91-10.78-19.53-6.12,2.47-12.19,1.25-18.23-.33a48.09,48.09,0,0,1-9.29-3.47c-1.12-.56-1.72-.52-2.6.6-6.28,8-13.14,9.19-21.89,4.05A6.68,6.68,0,0,1,57,209.82c-3.48-4.19-6.9-3.2-10.89-.52a37.47,37.47,0,0,1-11.72,5.06,8.47,8.47,0,0,1-5.95-.3l.16-.33h-.19c-3.05,2.21-3.52,5.79-4.46,9-4.12,14-4,28.4-1.64,42.43,5.78,33.74,20.93,62.77,46.94,85.41,19.53,17,42.56,26.93,67.78,32,5.35,1.08,10.8.95,16.17,1.7h23.13a1.31,1.31,0,0,1,.87-.33c10,0,19.74-1.95,29.31-4.76,13.93-4.09,27.25-9.58,39-18.3,4.34-3.22,9-6.29,10.49-12.07V345.6c-2.81-2.37-6.11-2.18-9.41-1.71Zm-128-78.45c-1.47,3.4-4,4.33-7.28,2.62a14.79,14.79,0,0,1-4.63-3.92,47.52,47.52,0,0,1-10.63-29.67c0-3.52.15-7.63,4.1-9s6.62,1.7,8.86,4.41c6.87,8.29,10,18,10.78,28.66a16.73,16.73,0,0,1-1.21,6.94Z"
      transform="translate(-20.63 -127.73)"
      fill="#fd0000"
    />
    <path
      d="M34.37,214.37a37.6,37.6,0,0,0,11.72-5.06c4-2.68,7.41-3.67,10.89.52A6.82,6.82,0,0,0,58.86,211c8.75,5.14,15.61,3.91,21.89-4a1.73,1.73,0,0,1,2.6-.6,48.09,48.09,0,0,0,9.29,3.47c6,1.58,12.11,2.8,18.23.33a26.54,26.54,0,0,0-5.77-6C97.66,198,88.88,194,80.24,189.78a8.38,8.38,0,0,1-5-6.83,62.14,62.14,0,0,1-.44-11.56C75.14,159,71.18,148,63.86,138.17c-2.66-3.57-5.57-7-8.36-10.44H52.93l-1,.73c-4,2.78-4.28,5.15-1,8.76a76.13,76.13,0,0,1,8.55,11.25,45.38,45.38,0,0,1,7,26.63c-.42,9-2.85,12.23-11.28,15.15C43.07,194.43,34,202.08,28.58,213.77l-.16.33a8.47,8.47,0,0,0,5.95.3Z"
      transform="translate(-20.63 -127.73)"
      fill="#098c00"
    />
    <path
      d="M100.17,225.44c-4,1.4-4.14,5.51-4.1,9a47.48,47.48,0,0,0,10.63,29.67,14.57,14.57,0,0,0,4.63,3.92c3.23,1.71,5.81.78,7.28-2.62a17.06,17.06,0,0,0,1.21-6.94c-.77-10.62-3.91-20.37-10.78-28.66-2.25-2.71-4.93-5.8-8.86-4.41Z"
      transform="translate(-20.63 -127.73)"
      fill="#fefefe"
    />
    <path
      d="M482,343.9a225.46,225.46,0,0,1-41.61,2.24c-12.65-.56-25.13-2.26-37-7-10.83-4.33-20.16-10.67-25.27-21.58-7.82-16.71-13.33-34.15-14-52.76A104.62,104.62,0,0,0,357,229.71c-2.74-7-5.58-13.91-10.78-19.53-6.12,2.47-12.19,1.25-18.23-.33a48.09,48.09,0,0,1-9.29-3.47c-1.12-.56-1.72-.52-2.6.6-6.28,8-13.14,9.19-21.89,4.05a6.68,6.68,0,0,1-1.88-1.21c-3.48-4.19-6.9-3.2-10.89-.52a37.47,37.47,0,0,1-11.72,5.06,8.47,8.47,0,0,1-5.95-.3l.16-.33h-.19c-3.05,2.21-3.52,5.79-4.46,9-4.12,14-4,28.4-1.64,42.43,5.78,33.74,20.93,62.77,46.94,85.41,19.53,17,42.56,26.93,67.78,32,5.35,1.08,10.8.95,16.17,1.7h23.13a1.31,1.31,0,0,1,.87-.33c10,0,19.74-1.95,29.31-4.76,13.93-4.09,27.25-9.58,39-18.3,4.34-3.22,9-6.29,10.49-12.07V345.6c-2.81-2.37-6.11-2.18-9.41-1.71ZM354,265.45c-1.47,3.4-4.05,4.33-7.28,2.62a14.79,14.79,0,0,1-4.63-3.92,47.52,47.52,0,0,1-10.63-29.67c0-3.52.15-7.63,4.1-9s6.62,1.7,8.86,4.41c6.87,8.29,10,18,10.78,28.66a16.73,16.73,0,0,1-1.21,6.94Z"
      transform="translate(-20.63 -127.73)"
      fill="#fd0000"
    />
    <path
      d="M269.74,214.37a37.6,37.6,0,0,0,11.72-5.06c4-2.68,7.41-3.67,10.89.52a6.82,6.82,0,0,0,1.88,1.21c8.75,5.14,15.61,3.91,21.89-4a1.73,1.73,0,0,1,2.6-.6,48.09,48.09,0,0,0,9.29,3.47c6,1.58,12.11,2.8,18.23.33a26.54,26.54,0,0,0-5.77-6C333,198,324.25,194,315.61,189.78a8.38,8.38,0,0,1-5-6.83,62.14,62.14,0,0,1-.44-11.56c.37-12.43-3.59-23.38-10.91-33.22-2.66-3.57-5.57-7-8.36-10.44H288.3l-1,.73c-4,2.78-4.28,5.15-1,8.76a76.13,76.13,0,0,1,8.55,11.25,45.38,45.38,0,0,1,6.95,26.63c-.42,9-2.85,12.23-11.28,15.15-12.08,4.18-21.13,11.83-26.57,23.52l-.16.33a8.47,8.47,0,0,0,5.95.3Z"
      transform="translate(-20.63 -127.73)"
      fill="#098c00"
    />
    <path
      d="M335.54,225.44c-4,1.4-4.14,5.51-4.1,9a47.48,47.48,0,0,0,10.63,29.67,14.57,14.57,0,0,0,4.63,3.92c3.23,1.71,5.81.78,7.28-2.62a17.06,17.06,0,0,0,1.21-6.94c-.77-10.62-3.91-20.37-10.78-28.66-2.25-2.71-4.93-5.8-8.86-4.41Z"
      transform="translate(-20.63 -127.73)"
      fill="#fefefe"
    />
  </svg>`,
})
export class VerySpicyIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
