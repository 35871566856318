import { ObjectLiteral } from "@eqn/data-types";

export interface ErrorDetail {
  reason: string;
  domain: string;
  metadata: ObjectLiteral;
}

export interface ErrorInfo {
  code: number;
  message: string;
  status: string;
  details: ErrorDetail[];
}
