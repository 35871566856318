<section class="survey-banner-wrapper">
  <menufy-web-close-survey />

  <p class="flex-grow-1 text-black">
    {{ 'survey.banner.title' | translate }} <br />
    {{ 'survey.banner.sub_title' | translate }}
  </p>

  <button class="survey-go-btn" (click)="startSurvey()">
    {{ 'survey.banner.go_btn' | translate }}
  </button>
</section>
