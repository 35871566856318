import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "fish-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="e191886b-66c5-4ac9-982e-d04ca101bf17"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#00a498" />
    <path d="M39,39.37c-.38-.67-.67-1.13-.8-1.32" transform="translate(-5.09 -5.09)" fill="none" />
    <path
      d="M69.43,52.4a19.89,19.89,0,0,0,3.34-3.77A18.87,18.87,0,0,0,75.93,40a19.13,19.13,0,0,0-12.08,7.76,24.84,24.84,0,0,0-5.16-5.23,25.74,25.74,0,0,0-8-4.07,19,19,0,0,0,2.07-8.26,19.05,19.05,0,0,0-14.49,7.59,5.13,5.13,0,0,0-.66.13,25.46,25.46,0,0,0-14,8.26,24.11,24.11,0,0,0-3.51,5.35A25.32,25.32,0,0,0,37.68,65.2a26.49,26.49,0,0,0,5.39.55h.34a25.73,25.73,0,0,0,16.9-6.51,26.69,26.69,0,0,0,3.09-3.29l.23-.32a1.21,1.21,0,0,0,.16.16,18.9,18.9,0,0,0,4.55,3.63c.39.23.82.45,1.22.66a18.93,18.93,0,0,0,5.79,1.72,18.76,18.76,0,0,0-3.41-6.72,16.75,16.75,0,0,0-2.52-2.67Zm-37.88,0a3.76,3.76,0,1,1,3.76-3.76,3.77,3.77,0,0,1-3.76,3.76"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class FishIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
