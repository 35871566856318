import { Component } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { Router } from "@angular/router";
import { PopupEvent, SwipeDownToCloseDirective } from "@diners/common";
import {
  CloseIconComponent,
  GlutenIconComponent,
  PeanutsIconComponent,
  VegetarianIconComponent,
} from "@menufy/icons";
import { plausibleTrackEvent } from "@menufy/plausible";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { selectBusinessHandle } from "../../business/state";
import { BusinessState } from "../../business/state/business.state";
import { AppPopupActions } from "../../store/actions/app-popup.actions";
import { AppPopupState } from "../../store/app-popup.state";

@Component({
  selector: "menufy-web-restrictions",
  standalone: true,
  imports: [
    MatButtonModule,
    SwipeDownToCloseDirective,
    TranslateModule,
    GlutenIconComponent,
    VegetarianIconComponent,
    PeanutsIconComponent,
    CloseIconComponent,
  ],
  templateUrl: "./restrictions.component.html",
  styleUrls: ["./restrictions.component.scss"],
})
export class RestrictionsComponent {
  private readonly _businessHandle = this._store.selectSignal(selectBusinessHandle);

  constructor(
    private readonly _bottomSheetRef: MatBottomSheetRef<RestrictionsComponent>,
    private readonly _store: Store<BusinessState | AppPopupState>,
    private readonly _router: Router
  ) {}

  public skip() {
    this._bottomSheetRef.dismiss();
  }

  public submit() {
    this._bottomSheetRef.dismiss();

    this._router
      .navigate(["/menu", this._businessHandle()], { fragment: PopupEvent.PREFERENCES })
      .then(() => {
        this._store.dispatch(
          AppPopupActions.openPopup({
            current: PopupEvent.RESTRICTION,
            next: PopupEvent.PREFERENCES,
          })
        );
      });
  }

  public openTranslatePopup() {
    this._bottomSheetRef.dismiss();

    this._router
      .navigate(["/menu", this._businessHandle()], { fragment: PopupEvent.TRANSLATE })
      .then(() => {
        this._store.dispatch(
          AppPopupActions.openPopup({
            current: PopupEvent.RESTRICTION,
            next: PopupEvent.TRANSLATE,
          })
        );
      });
  }

  public sendPlausibleEvent() {
    plausibleTrackEvent("Preferences Skip Drop Menu");
  }
}
