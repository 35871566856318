import { SocialNetwork } from "../social-network";
import { FileRecord } from "../file-record";
import { EntityIdField } from "../entity";

export type Branding = Partial<{
  font: string;
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
}>;

export interface BusinessConfig {
  followUs: boolean;
  rateUs: boolean;
}

export interface Business extends EntityIdField {
  name: string;
  description?: string;
  images: FileRecord[];
  socialNetwork: SocialNetwork[];
  ratingPlatforms: SocialNetwork[];
  branding?: Branding;
  config: BusinessConfig;
  url: string;
  handle: string;
  createdAt: Date | string;
}
