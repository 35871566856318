import { Pipe, PipeTransform } from "@angular/core";
import { FileRecord } from "@menufy/models";

@Pipe({
  name: "contentImage",
  standalone: true,
})
export class ContentImagePipe implements PipeTransform {
  transform(images?: FileRecord[], fallback?: string) {
    const url = images?.[0]?.url;

    return url || fallback;
  }
}
