export enum RestaurantPlausibleEvent {
  ADD_DISH = "ADD_DISH",
  REMOVE_DISH = "REMOVE_DISH",
  ADD_MENU = "ADD_MENU",
  REMOVE_MENU = "REMOVE_MENU",
  HIDE_SHOW_DISH = "HIDE_SHOW_DISH",
  PHOTO_UPLOAD = "PHOTO_UPLOAD",
}

export enum DinersPlausibleEvent {
  ALLERGEN = "ALLERGEN",
  DIET = "DIET",
  COMBINED_PREFERENCES = "COMBINED_PREFERENCES",
  DETECTED_LANGUAGE = "DETECTED_LANGUAGE",
  TRANSLATION = "TRANSLATION",
  DOTS_MENU = "DOTS_MENU",
  FOLLOW_RESTAURANT = "FOLLOW_RESTAURANT",
  RATE_RESTAURANT = "RATE_RESTAURANT",
  DISH_PAGE = "DISH_PAGE",
  SPECIAL_TAG = "SPECIAL_TAG",
  CLOSE_SURVEY = "CLOSE_SURVEY",
  START_SURVEY = "START_SURVEY",
}
