import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "alhambra-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="37f3c5a7-4d6a-4e88-a9f2-7cde0b14fd9b"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 236.22 66.8"
  >
    <defs>
      <style>
        .cls-1 {
          fill: #fff4e2;
        }
        .cls-2,
        .cls-3,
        .cls-4 {
          fill: none;
          stroke: #a09174;
        }
        .cls-2,
        .cls-3 {
          stroke-miterlimit: 2.61;
        }
        .cls-2,
        .cls-4 {
          stroke-width: 1.39px;
        }
        .cls-2,
        .cls-3,
        .cls-4,
        .cls-5 {
          fill-rule: evenodd;
        }
        .cls-3 {
          stroke-width: 1.39px;
        }
        .cls-4 {
          stroke-miterlimit: 22.93;
        }
      </style>
    </defs>
    <rect class="cls-1" width="236.22" height="66.8" rx="33.4" />
    <polygon class="cls-2" points="27.07 31.23 29.08 33.31 27.09 35.34 25.07 33.31 27.07 31.23" />
    <polygon class="cls-2" points="49.48 31.23 51.48 33.31 49.49 35.34 47.47 33.31 49.48 31.23" />
    <path
      class="cls-3"
      d="M147.66,202h-2.5c-.59.84-1.22.8-1.88,0H141V186.27h2.25c.66-.8,1.29-.84,1.88,0h2.5Z"
      transform="translate(-106.18 -160.88)"
    />
    <polyline class="cls-4" points="21.73 27.54 28.3 20.79 48.1 20.79 54.82 27.55" />
    <polyline class="cls-4" points="21.75 20.79 25.61 20.79 50.73 45.89 54.63 45.89" />
    <polyline class="cls-4" points="21.75 39.09 28.45 45.89 48.17 45.89 54.63 39.09" />
    <polyline class="cls-4" points="21.89 45.89 25.24 45.89 50.7 20.79 54.51 20.79" />
    <path
      class="cls-4"
      d="M160.81,179.12H159.7a.71.71,0,0,0-.7.71v10.94h-2c-1,1-1.78,1-2.61,0h-2.06V180.44a4.86,4.86,0,0,0-.07-1.36h-2.12l-1.27,1.21-4.44,4.3-4.44-4.3-1.27-1.21h-2.12a4.86,4.86,0,0,0-.07,1.36v10.33h-2.06c-.83,1-1.63,1-2.61,0h-2V179.83a.7.7,0,0,0-.69-.71h-1.11"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-4"
      d="M160.81,209.45H159.7a.71.71,0,0,1-.7-.71V197.8h-2c-1-1-1.78-1-2.61,0h-2.06v10.33a4.86,4.86,0,0,1-.07,1.36h-2.12l-1.27-1.21-4.44-4.3-4.44,4.3-1.27,1.21h-2.12a4.86,4.86,0,0,1-.07-1.36V197.8h-2.06c-.83-1-1.63-1-2.61,0h-2v10.94a.7.7,0,0,1-.69.71h-1.11"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-5"
      d="M250.31,190.39v-.29c0-.13,0-.25,0-.37-.37-2.12-3-2-4.42-1.45-.57.24-.1,1.34-.31,2.12-.49,1.81-1.52,4.66-1.91,6.16a22.09,22.09,0,0,0-.9,7.49c.15,1.59.72,2.58,2,3,1.51.44,2.53-.24,3.53-.69-.48-3.76-.51-6.64.58-9.61a8.1,8.1,0,0,1,2-3.21c.84-.77,3.06-1.86,3.2.65.1,1.75-1.83,4.82-2.23,8.06-.8,6.51,4.55,5,5.45,4.09a21.68,21.68,0,0,1-.12-5.32c.95-7.94,6.16-10.44,6.26-6.69a13.81,13.81,0,0,1-1.16,4.12c-4,10.89,3.33,9.32,4.49,8a9.62,9.62,0,0,1-.34-4c.46-3.54,2.28-6.32,2.38-9.3s-1.26-4.95-4-5.05a6.85,6.85,0,0,0-3.33.75,10.41,10.41,0,0,0-2.34,1.68c-.4-.56-.46-1.4-1.71-2a4.94,4.94,0,0,0-2.95-.33C252.24,188.45,251.51,189.5,250.31,190.39Z"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-5"
      d="M212.86,206.54c-1.37-2.2-1.36-11.23,1.83-13.75.84-.65,3.35-1.95,3.35.65,0,2.81-2.18,5.12-1.69,11.59.28,3.74,4.91,2.18,5.63,1.41-2-4.39.37-7.54,1.1-12.65.76-5.3-3.06-6.58-6.59-5a8.29,8.29,0,0,0-1.33.78c-.46.31-.79.68-1.17.93a14.69,14.69,0,0,1,.69-1.72c.92-2.25,3.52-8.91.14-9.66a5.1,5.1,0,0,0-3.39.31,15.2,15.2,0,0,1-.38,5c-1.46,6.49-3.4,13.65-3.81,19.48-.13,1.73.16,2.92,1.43,3.41C210.15,207.89,211.78,207,212.86,206.54Z"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-5"
      d="M278.71,191.87c4.23-.9,1.61,10-2.92,10.81s-1.29-9.91,2.92-10.81Zm-5.08-13a18.31,18.31,0,0,1-1.45,9.52c-1.67,4.3-5,11.91-2.69,16.37,1.16,2.27,3.8,3.06,6.8,2.24,8.63-2.35,12.48-16.68,7.82-19.37a5.05,5.05,0,0,0-4-.11,9.09,9.09,0,0,0-3,2,48.24,48.24,0,0,0,2-8.37C279.44,177.1,275.32,177.5,273.63,178.89Z"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-5"
      d="M237,191.67c1.1,1.23-.88,5.52-1.78,7a12,12,0,0,1-2.14,2.68c-.69.67-2.37,1.6-3.28.51-1.72-2.07.43-11,7.2-10.19Zm3.54,14.71c-1.83-2.92.18-10.43,1.43-13.49s-1-4.95-3.94-5.11a13.35,13.35,0,0,0-14.07,12.93c-.38,6.58,5.95,7.74,10.2,4.13a7.41,7.41,0,0,0,.78-.8c.13,1.57.53,2.82,1.86,3.16A4.64,4.64,0,0,0,240.52,206.38Z"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-5"
      d="M202.91,179.44c.85,2.57-.23,6.87-.86,8.95-1.33,4.35-3.9,10.88-3.71,15.59.18,4.41,3.66,4.08,6,2.64-1.71-2.15-.86-5.64,0-9.08,1.18-4.72,3.74-10.83,3.69-15.18C207.93,179,205.6,178.33,202.91,179.44Z"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-5"
      d="M310.06,191.67c1.11,1.23-.87,5.52-1.78,7a11.46,11.46,0,0,1-2.14,2.68c-.69.67-2.36,1.6-3.27.51-1.72-2.07.43-11,7.19-10.19Zm3.54,14.71c-1.82-2.92.19-10.43,1.44-13.49s-1-4.95-3.94-5.11A13.35,13.35,0,0,0,297,200.71c-.38,6.58,6,7.74,10.2,4.13a7.41,7.41,0,0,0,.78-.8c.13,1.57.53,2.82,1.86,3.16A4.61,4.61,0,0,0,313.6,206.38Z"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-5"
      d="M298,188.86c1.42-.26,1.94.87,1.2,2.39-5.63.54-9.26,9.92-6.31,15.2-3.48,1.66-6.55.8-5.83-3.8s3.7-9.71,2.19-14c2.33-.56,4.19-.11,5.13,2.7C295.41,189.87,296.1,189.11,298,188.86Z"
      transform="translate(-106.18 -160.88)"
    />
    <path
      class="cls-5"
      d="M181.12,194.52c-3.2,9.49-6.41,14.17-11.21,16.13,5.28-.34,9.25-3.29,12.08-9.13l1.84-4,4.28,7.58c2.63,4.1,6.67,2.45,7.35-2.13.95-7.83,1-19.19,2.87-23.48-3.79-2.05-8.93-1.25-11,2.84l-4.64,9.18c-4.84-8.89-13.6-2.61-11-.32.43.37,2.47-.69,3.15-.77,4.75-.54,7,2,6.25,4.07Zm9.87,5.91c-.3,2.31-1.79,2.4-3,.32L186,197a4.19,4.19,0,0,1,0-3.35c2.38-6,5.64-13.39,7.52-12.64C191.49,185.31,191.83,194,191,200.43Z"
      transform="translate(-106.18 -160.88)"
    />
  </svg>`,
})
export class AlhambraIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
