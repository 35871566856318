import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "crustaceans-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="bb0bd3f3-af28-42c0-94df-c0c761ca9b21"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84Z"
      transform="translate(-3.41 -16.46)"
      fill="#f67465"
    />
    <path
      d="M77.42,68.55V70.7a1.17,1.17,0,0,1-2,.82L67,63.09c.06-.08.1-.15.16-.22A11.25,11.25,0,0,1,63.27,66l-3.12,1.48-.5.56a14.85,14.85,0,0,1-4.5,3.41l-.82.41a1.53,1.53,0,0,1-.69.17H42.35a1.55,1.55,0,0,1-.68-.16l-.84-.42a15.17,15.17,0,0,1-4.59-3.52l-.41-.45L32.74,66A11,11,0,0,1,29,63.08l-8.41,8.43a1.17,1.17,0,0,1-2-.82V68.54a7.16,7.16,0,0,1,7.17-7.16h2a11.26,11.26,0,0,1-1.39-4l-.14-1a17.82,17.82,0,0,1-6.86-14.18A18.14,18.14,0,0,1,37.59,24c.43,0,1.24,0,1.94.05A.58.58,0,0,1,40,25c-2,2.7-3.84,5.91-4,9.34A18.73,18.73,0,0,1,42.82,33h.39A1.92,1.92,0,0,1,44.7,36a15,15,0,0,1-3.05,3,16,16,0,0,1-9.53,3.17A13.52,13.52,0,0,1,30.21,42a10.49,10.49,0,0,0-2.6,7.55,9.73,9.73,0,0,0,.51,2.52l2.47-2.58a15.14,15.14,0,0,1,10.93-4.67H54.47A15.2,15.2,0,0,1,65.4,49.52l2.47,2.58a10.72,10.72,0,0,0,.5-2.52A10.36,10.36,0,0,0,65.78,42a13.56,13.56,0,0,1-1.92.13,15.42,15.42,0,0,1-4.78-.74A15.12,15.12,0,0,1,54.34,39a15.27,15.27,0,0,1-3.05-3A1.91,1.91,0,0,1,52.78,33h.39a18.64,18.64,0,0,1,6.88,1.32c-.18-3.43-2.17-6.63-4.15-9.33a.59.59,0,0,1,.14-.83.58.58,0,0,1,.31-.11c.75,0,1.61-.05,2-.05A18.11,18.11,0,0,1,69.73,56.35l-.15,1a11.51,11.51,0,0,1-1.38,4h2a7.17,7.17,0,0,1,7.17,7.16Z"
      transform="translate(-3.41 -16.46)"
      fill="#f67465"
    />
  </svg>`,
})
export class CrustaceansOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
