import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "fritz-kola-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f2bdf75e-8c89-465d-8aaf-9e3cb8462dc7"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 245.12 87.6"
  >
    <defs>
      <style>
        .cls-1,
        .cls-2,
        .cls-3 {
          fill: #fff;
        }
        .cls-2,
        .cls-3 {
          fill-rule: evenodd;
        }
        .cls-3 {
          stroke: #000;
          stroke-miterlimit: 22.93;
          stroke-width: 0.22px;
        }
      </style>
    </defs>
    <rect width="245.12" height="87.6" rx="43.8" />
    <rect class="cls-1" x="75.28" y="23.77" width="4.93" height="15.97" />
    <rect class="cls-1" x="75.28" y="40.79" width="4.93" height="23.89" />
    <polygon
      class="cls-2"
      points="81.23 40.79 85.03 40.79 85.03 23.77 89.69 23.77 89.69 40.79 93.38 40.79 93.38 45.54 89.69 45.54 89.69 64.67 85.03 64.67 85.03 45.54 81.23 45.54 81.23 40.79"
    />
    <polygon
      class="cls-2"
      points="94.41 40.79 109.8 40.79 109.8 45.47 99.48 60.06 110.32 60.06 110.32 64.67 93.38 64.67 93.38 60.74 103.89 45.47 94.41 45.47 94.41 40.79"
    />
    <rect class="cls-1" x="110.32" y="50.01" width="11.55" height="4.62" />
    <polygon
      class="cls-2"
      points="124.06 23.77 128.88 23.77 128.88 48.68 134.58 40.79 140.62 40.79 132.24 51.68 142.25 64.67 136.56 64.67 129.22 55.6 128.88 56.04 128.88 64.67 124.06 64.67 124.06 23.77"
    />
    <path
      class="cls-2"
      d="M210.23,187.22a12.43,12.43,0,1,1-12.43,12.42,12.42,12.42,0,0,1,12.43-12.42Zm0,4.65a7.78,7.78,0,1,0,7.78,7.77A7.78,7.78,0,0,0,210.23,191.87Z"
      transform="translate(-59.58 -146.91)"
    />
    <rect class="cls-1" x="164.01" y="23.77" width="4.77" height="40.9" />
    <path
      class="cls-2"
      d="M248.87,187.57h4.58v24h-4.58v-1.71a12.45,12.45,0,1,1,0-20.49v-1.82Zm-7.07,4.7a7.36,7.36,0,1,0,7.36,7.36A7.36,7.36,0,0,0,241.8,192.27Z"
      transform="translate(-59.58 -146.91)"
    />
    <path
      class="cls-2"
      d="M124.16,187.7h4.93v1.4a6.23,6.23,0,0,1,3.08-1.4h1.67v4.65c-2.93.31-4.32,1.56-4.75,3.4v15.84h-4.93Z"
      transform="translate(-59.58 -146.91)"
    />
    <path
      class="cls-2"
      d="M110.83,187.7h3.91V177.17c-.12-5.77,3.19-8,9-7.8v5.28c-2.33,0-4.09.77-4.22,2.57V187.7h3.63v4.62h-3.63v19.27h-4.77V192.32h-3.91Z"
      transform="translate(-59.58 -146.91)"
    />
    <path
      class="cls-2"
      d="M260.12,170.5a4.91,4.91,0,1,1-4.9,4.91,4.91,4.91,0,0,1,4.9-4.91Zm0,.88a4,4,0,1,0,4,4A4,4,0,0,0,260.12,171.38Z"
      transform="translate(-59.58 -146.91)"
    />
    <path
      class="cls-3"
      d="M259.32,175.75V178h-.83v-5.27h2.21a1.5,1.5,0,0,1,1.49,1.5h0a1.49,1.49,0,0,1-1,1.41l1.19,2.36h-.94l-1.15-2.28Zm0-2.2v1.54h1.24a.77.77,0,0,0,.77-.77h0a.78.78,0,0,0-.77-.77Z"
      transform="translate(-59.58 -146.91)"
    />
  </svg>`,
})
export class FritzKolaIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
