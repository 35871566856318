import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Nullable } from "@eqn/data-types";
import { Store } from "@ngrx/store";
import { SurveyAnswer, SurveyAnswerOptionKind, SurveyTextQuestion } from "../../interfaces/survey";
import { SurveyActions } from "../../state";
import { SurveyState } from "../../state/survey.state";
import { TranslateModule } from "@ngx-translate/core";

interface TextInputFormGroup {
  answer: FormControl<Nullable<string>>;
}

@Component({
  selector: "menufy-web-survey-text-input",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./text-value-input.html",
  styles: `
    textarea.form-control {
      outline: none;
      box-shadow: none;
      font-style: italic;
      font-size: 16px;
      height: 10rem;
      border-radius: 6px;

      &:hover {
        border-color: #000;
      }
    }
  `,
})
export class SurveyTextInputComponent implements OnInit {
  @Output() isValidEvent = new EventEmitter<boolean>();

  public question = input.required<SurveyTextQuestion>();

  public form!: FormGroup<TextInputFormGroup>;

  constructor(private readonly _store: Store<SurveyState>) {}

  ngOnInit() {
    const defaultValue = this.question()?.default?.value;

    const limits = this.question().limits;

    const validators = Validators.compose([
      Validators.required,
      Validators.minLength(limits.min),
      Validators.maxLength(limits.max),
    ]);

    this.form = new FormGroup<TextInputFormGroup>({
      answer: new FormControl(defaultValue, validators),
    });

    this.form.statusChanges.subscribe(() => this.isValidEvent.emit(this.form.valid));

    this.form.valueChanges.subscribe((value) => this._updateAnswer(value.answer));

    this.form.updateValueAndValidity();
  }

  private _updateAnswer(value: Nullable<string>) {
    if (!value) {
      return;
    }

    const questionId = this.question().id;

    const answer: SurveyAnswer<SurveyAnswerOptionKind.TEXT, string> = {
      kind: SurveyAnswerOptionKind.TEXT,
      value,
    };

    this._store.dispatch(SurveyActions.updateSurveyAnswer({ questionId, answer }));
  }
}
