import { Dish, DishOptionItem } from "@menufy/models";
import { cloneDeep } from "lodash";
import { MenuPreferencesFilters } from "./interfaces/prefs-filters";
import { sortDishLabels } from "./sorter";
import { findLabel } from "./find-methods";

type FilterableItem = Pick<Dish, "allergens" | "diets">;

interface DishPreferenceOptions {
  filterAllergensAndDiets?: boolean;
  filterOptionsItems?: boolean;
}

interface FilterSelectionOptions {
  onlySelected?: boolean;
  whenBothSelected?: boolean;
  skipFilterWhenBothEmpty?: boolean;
}

export const filterSelectedAllergensAndDiets = (
  item: FilterableItem,
  filters: MenuPreferencesFilters,
  options?: FilterSelectionOptions
) => {
  const { selectedAllergens, selectedDiets } = filters;

  const userHasBothPrefsSelected = selectedAllergens.length && selectedDiets.length;
  const userDoesNotHavePrefs = !selectedAllergens.length && !selectedDiets.length;

  const filteredAllergens = item.allergens?.filter((allergen) =>
    selectedAllergens.includes(allergen.name.toLowerCase())
  );

  const filteredDiets = item.diets?.filter((diet) =>
    selectedDiets.includes(diet.name.toLowerCase())
  );

  const mode = Object.assign(
    {
      onlySelected: false,
      whenBothSelected: false,
      skipFilterWhenBothEmpty: false,
    },
    options || {}
  );

  if (mode.onlySelected) {
    if (selectedAllergens.length) {
      item.allergens = filteredAllergens;
    }

    if (selectedDiets.length) {
      item.diets = filteredDiets;
    }
  } else if (mode.whenBothSelected && userHasBothPrefsSelected) {
    item.allergens = filteredAllergens;
    item.diets = filteredDiets;
  } else if (mode.skipFilterWhenBothEmpty && userDoesNotHavePrefs) {
    return item;
  } else {
    item.allergens = filteredAllergens;
    item.diets = filteredDiets;
  }

  return item;
};

export const isPreferenceMatchedItem = (
  item: FilterableItem,
  filters: MenuPreferencesFilters
): boolean => {
  const modifiedFilters = cloneDeep(filters);

  const { selectedAllergens } = modifiedFilters;

  // remove the dummy diets from selection
  // NOTE: in future we will remove this code
  const DUMMY_DIETS = ["pescetarian", "paleo", "keto", "flexitarian"];
  const selectedDiets = modifiedFilters.selectedDiets.filter((diet) => !DUMMY_DIETS.includes(diet));

  const allergens = item.allergens;
  const diets = item.diets;

  const itemHasAllergens = !!allergens.length;
  const areAllergensSelected = !!selectedAllergens.length;
  const areDietsSelected = !!selectedDiets.length;

  const isSafeToIncludeAllergens = selectedAllergens.every((a) => {
    const allergen = findLabel(allergens, a);
    return !allergen ? true : allergen && allergen.isOptional;
  });

  const hasAllergensIncluded =
    itemHasAllergens && areAllergensSelected ? isSafeToIncludeAllergens : false;

  const hasDietsIncluded = areDietsSelected
    ? selectedDiets.every((d) => findLabel(diets, d))
    : true;

  return hasAllergensIncluded
    ? hasAllergensIncluded && hasDietsIncluded
    : isSafeToIncludeAllergens && hasDietsIncluded;
};

export const filterPreferenceMatchedItems = <FilteredItemType>(
  item: FilterableItem,
  filters: MenuPreferencesFilters,
  filteredItems: FilteredItemType[]
) => {
  if (isPreferenceMatchedItem(item, filters)) {
    filteredItems.push(item as FilteredItemType);
  }

  return filteredItems;
};

const setSelectedAllergensAndDietsInOptions = (dish: Dish, filters: MenuPreferencesFilters) => {
  dish?.options?.forEach((option) => {
    if (!option.isFood) {
      return;
    }

    option.items.forEach((item) => filterSelectedAllergensAndDiets(item, filters));
  });

  return dish;
};

const filterMatchedAllergensAndDietsOptions = (
  dish: Dish,
  filters: MenuPreferencesFilters
): Dish => {
  dish?.options?.forEach((option) => {
    if (!option.isFood) {
      return;
    }

    const filteredDishOptionItems: DishOptionItem[] = [];

    option.items.forEach((item) => {
      const clonedItem = cloneDeep(item);

      if (isPreferenceMatchedItem(clonedItem, filters) && filters.isFilterOn) {
        filteredDishOptionItems.push(clonedItem);
      }
    });

    option.items = filteredDishOptionItems;
  });

  return dish;
};

export function applyDishPreferencesFilters(
  dish: Dish,
  filters: MenuPreferencesFilters,
  options?: DishPreferenceOptions
): Dish {
  const opts = Object.assign(
    { filterAllergensAndDiets: true, filterOptionsItems: true },
    options || {}
  );

  const { isFilterOn } = filters;

  const clonedDish = cloneDeep(dish);

  // set allergen or diet labels
  if (opts.filterAllergensAndDiets) {
    filterSelectedAllergensAndDiets(clonedDish, filters);
  }

  // set allergen or diet labels in `options`
  if (opts.filterOptionsItems) {
    // set allergen or diet labels in `options`
    setSelectedAllergensAndDietsInOptions(clonedDish, filters);

    // if allergens or diet is selected with filter on
    if (isFilterOn) {
      // then filter `options` based on matching labels
      filterMatchedAllergensAndDietsOptions(clonedDish, filters);
    }
  }

  // sort allergen, diets and option items in pre defined order
  return sortDishLabels(clonedDish);
}
