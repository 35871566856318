export interface PreferencesState {
  selectedAllergens: string[];
  selectedDiets: string[];
  isFilterOn: boolean;
  userHasPreferences: number;
}

export const prefsInitialState: Readonly<PreferencesState> = {
  selectedAllergens: [],
  selectedDiets: [],
  isFilterOn: true,
  userHasPreferences: 0,
};
