import { Dish, Menu, PreferenceLabel } from "@menufy/models";
import { Nullable } from "@eqn/data-types";
import { MenuPreferencesFilters } from "./interfaces/prefs-filters";
import { applyDishPreferencesFilters } from "./dish-prefs-filters";

export const findLabel = (labels: PreferenceLabel[], matchLabel: string) =>
  labels.find((label) => label.name.toLowerCase() === matchLabel.toLowerCase());

export const findDishFromMenu = (
  menus: Menu[],
  menuId?: string,
  dishId?: string,
  filters?: MenuPreferencesFilters
): Nullable<Dish> => {
  const dish = menus
    ?.find((menu) => menu.id === menuId)
    ?.items?.find((item) => item.id === dishId) as Dish;

  if (dish && filters) {
    return applyDishPreferencesFilters(dish, filters);
  }

  return dish;
};
