import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import * as Hammer from "hammerjs";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[swipeDownToClose]",
  standalone: true,
})
export class SwipeDownToCloseDirective implements OnInit, OnDestroy {
  @Input() swipeDownTarget!: "document" | "content";
  @Output() popupClosed = new EventEmitter<void>();
  private hammerManager!: HammerManager;

  constructor(
    private readonly _bottomSheetRef: MatBottomSheetRef<unknown>,
    private readonly _el: ElementRef
  ) {}

  ngOnInit(): void {
    this._registerHammer();
  }

  ngOnDestroy(): void {
    this.hammerManager.destroy();
  }

  private _registerHammer() {
    this.hammerManager = new Hammer.Manager(
      this.swipeDownTarget === "document" ? document : this._el.nativeElement
    );
    const swipe = new Hammer.Swipe({ direction: Hammer.DIRECTION_DOWN });
    this.hammerManager.add(swipe);
    this.hammerManager.on("swipe", (event) => {
      if (event.offsetDirection === 16) {
        this._bottomSheetRef.dismiss();
        this.popupClosed.emit();
      }
    });
  }
}
