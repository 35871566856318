import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "celery-optional-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f364f752-022f-47ac-9085-9886dfbc9019"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.17 63.09"
  >
    <path
      d="M79.54,79.54l-2.12-2.12a41.66,41.66,0,0,0,0-58.84l2.12-2.12a44.67,44.67,0,0,1,0,63.09Zm-63.08,0a44.64,44.64,0,0,1,0-63.08l2.12,2.12a41.66,41.66,0,0,0,0,58.84ZM67.82,57.15a5,5,0,0,0,2.43-4.39,12.49,12.49,0,0,1-13-.81,13.63,13.63,0,0,0,12.52-9.46,24.21,24.21,0,0,1-4.43-3S68.16,36,66.63,29.37C60,27.84,56.49,30.65,56.49,30.65a24.21,24.21,0,0,1-3-4.43,13.66,13.66,0,0,0-9.46,12.52,12.49,12.49,0,0,1-.81-13,5,5,0,0,0-4.39,2.43c-1.15-2.68-.94-7.75-.94-7.75C32.5,21.75,26.79,32.49,32.5,39L44.77,51.23,57,63.5c6.47,5.71,17.21,0,18.53-5.41,0,0-5.07.21-7.75-.94m-42.89-.64a9.35,9.35,0,0,0-1.76,14.6l1.72,1.72a9.35,9.35,0,0,0,14.6-1.76l6-9.86L34.78,50.52l-9.86,6Z"
      transform="translate(-3.41 -16.46)"
      fill="#acd089"
    />
  </svg>`,
})
export class CeleryOptionalIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
