import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "molluscs-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="bcbb14e0-504c-4482-b452-19d287055041"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#0092bc" />
    <path
      d="M70.67,35.68s2.77-6.14-2.79-9.69C62,22.2,57.2,28.16,57.2,28.16s-.73-7.24-9.2-7.24-9.2,7.24-9.2,7.24-4.75-6-10.68-2.17c-5.56,3.55-2.79,9.69-2.79,9.69-2.91-4.13-11.94-.87-9.18,7.27C17.63,47.31,25.46,57.47,48,67.36,70.54,57.47,78.37,47.3,79.85,43c2.76-8.14-6.27-11.4-9.18-7.27"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
    <path
      d="M64.42,64.52v6.73a3.84,3.84,0,0,1-3.83,3.83H35.41a3.84,3.84,0,0,1-3.83-3.83V64.52A121.94,121.94,0,0,0,46,71.94l2,.88,2-.88a121.94,121.94,0,0,0,14.41-7.42"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class MolluscsIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
