import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "recommended-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="ac935c26-6e9f-4dee-8e6e-046806f8c522"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 435.42 415.1"
  >
    <defs>
      <linearGradient
        id="a3283bcc-dc5e-47f5-ba02-0656fef71805"
        x1="102.77"
        y1="363.95"
        x2="399.56"
        y2="81.52"
        gradientTransform="matrix(1, 0, 0, -1, 0, 514)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#ffd418" />
        <stop offset="1" stop-color="#fec30d" />
      </linearGradient>
    </defs>
    <path
      d="M264.93,56.17l61.88,125.38,138.36,20.11a10,10,0,0,1,5.52,17L370.57,316.24,394.21,454a10,10,0,0,1-14.45,10.5L256,399.48,132.24,464.54A10,10,0,0,1,117.79,454l23.64-137.8L41.31,218.64a10,10,0,0,1,5.52-17l138.36-20.11L247.07,56.16a10,10,0,0,1,17.86,0Z"
      transform="translate(-38.29 -50.61)"
      fill="url(#a3283bcc-dc5e-47f5-ba02-0656fef71805)"
    />
  </svg>`,
})
export class RecommendedIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
