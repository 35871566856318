import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "adjust-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="ba1eaa24-3f97-4982-b346-13c08b5d26fa"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500.5 500.5"
  >
    <path
      d="M256,5.75C117.79,5.75,5.75,117.79,5.75,256S117.79,506.25,256,506.25,506.25,394.21,506.25,256,394.21,5.75,256,5.75M396.2,188.1,234.6,349.7a20,20,0,0,1-28.29,0L115.8,259.19a20,20,0,0,1,28.28-28.28l76.37,76.37L367.92,159.81A20,20,0,1,1,396.2,188.1"
      transform="translate(-5.75 -5.75)"
      fill="#1d1c1b"
    />
  </svg>`,
})
export class AdjustIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
