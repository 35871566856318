import { TitleCasePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Nullable } from "@eqn/data-types";
import { Store } from "@ngrx/store";
import { tap } from "rxjs";
import { SurveyAnswerOptionKind, SurveyYesNoQuestion } from "../../interfaces/survey";
import { SurveyActions } from "../../state";
import { SurveyState } from "../../state/survey.state";
import { TranslateModule } from "@ngx-translate/core";

interface YesNoInputFormGroup {
  kind: FormControl<SurveyAnswerOptionKind.YES | SurveyAnswerOptionKind.NO | undefined>;
  otherAnswer: FormControl<Nullable<string>>;
}

@Component({
  selector: "menufy-web-survey-yn-input",
  standalone: true,
  imports: [TitleCasePipe, ReactiveFormsModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./yes-no-input.html",
  styles: `
    label {
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
    }

    .form-check-input {
      box-shadow: none;
      width: 1.3rem;
      height: 1.3rem;
      border-color: rgba(0, 0, 0, 0.5);

      &:focus {
        border-color: #000;
      }

      &:checked {
        border-color: #000;
        background-color: #000;
        background-size: 28px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000' stroke='%23fff' stroke-width='0.4px' /%3e%3c/svg%3e");
      }
    }

    input.form-control {
      margin-top: 2rem;
      outline: none;
      box-shadow: none;
      font-style: italic;
      font-size: 16px;
      border-radius: 6px;

      &:hover {
        border-color: #000;
      }
    }
  `,
})
export class SurveyYesNoInputComponent implements OnInit {
  @Output() isValidEvent = new EventEmitter<boolean>();

  public readonly YesNoLabel = SurveyAnswerOptionKind;

  public question = input.required<SurveyYesNoQuestion>();

  public readonly yesOption = computed(() => this.question().yesOption);

  public readonly noOption = computed(() => this.question().noOption);

  public form!: FormGroup<YesNoInputFormGroup>;

  constructor(private readonly _store: Store<SurveyState>) {}

  ngOnInit() {
    const defaultValueKind = this.question()?.default?.kind;

    const limits = this.question().limits;

    const validators = Validators.compose([
      Validators.minLength(limits.min),
      Validators.maxLength(limits.max),
    ]);

    this.form = new FormGroup({
      kind: new FormControl(defaultValueKind, Validators.required),
      otherAnswer: new FormControl(null, validators),
    }) as FormGroup<YesNoInputFormGroup>;

    this.form.valueChanges
      .pipe(tap(() => this.isValidEvent.emit(this.form.valid)))
      .subscribe((value) =>
        this._updateAnswer(value.kind as SurveyAnswerOptionKind, value.otherAnswer)
      );
  }

  private _updateAnswer(kind: SurveyAnswerOptionKind, otherAnswer: Nullable<string>) {
    const questionId = this.question().id;

    const yesNoAnswer = {
      kind,
      value:
        kind === SurveyAnswerOptionKind.YES
          ? this.yesOption().value
          : this.noOption().value || SurveyAnswerOptionKind.NO.toLowerCase(),
    };

    if (kind) {
      this._store.dispatch(SurveyActions.updateSurveyAnswer({ questionId, answer: yesNoAnswer }));
    }

    const otherAns = {
      kind: SurveyAnswerOptionKind.OTHER,
      value: otherAnswer,
    };

    this._store.dispatch(SurveyActions.updateSurveyAnswer({ questionId, answer: otherAns }));
  }
}
