import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "crustaceans-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="f2b1909b-4a7f-464d-a0aa-5c5fdeadbf80"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#f67465" />
    <path
      d="M69.43,62.49a7.32,7.32,0,0,1-.52.8c.18-.26.35-.53.52-.8"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
    <path
      d="M79.39,69.92v2.29a1.25,1.25,0,0,1-2.13.88l-9-9c.06-.08.11-.16.17-.23a12,12,0,0,1-4.16,3.32L61,68.77l-.53.6A16.27,16.27,0,0,1,55.64,73l-.87.44a1.59,1.59,0,0,1-.74.18H42a1.63,1.63,0,0,1-.73-.17l-.9-.45a16.2,16.2,0,0,1-4.9-3.76L35,68.77l-3.3-1.58a11.79,11.79,0,0,1-4-3.09l-9,9a1.25,1.25,0,0,1-2.13-.88V69.92a7.64,7.64,0,0,1,7.65-7.64h2.18A11.86,11.86,0,0,1,25,58l-.15-1.09a19,19,0,0,1-7.32-15.13A19.73,19.73,0,0,1,18.34,36,19.43,19.43,0,0,1,36.9,22.37c.46,0,1.32,0,2.07.05a.62.62,0,0,1,.46,1c-2.08,2.88-4.1,6.3-4.3,10A19.91,19.91,0,0,1,42.48,32h.42a2,2,0,0,1,1.59,3.27A17.16,17.16,0,0,1,36.17,41a16.54,16.54,0,0,1-5.1.79,13.89,13.89,0,0,1-2-.14,11.18,11.18,0,0,0-2.77,8.06,10.62,10.62,0,0,0,.54,2.69l2.64-2.75a16.16,16.16,0,0,1,11.66-5H54.92a16.19,16.19,0,0,1,11.66,5l2.64,2.75A11,11,0,0,0,67,41.64a13.94,13.94,0,0,1-2,.14,16.54,16.54,0,0,1-5.1-.79,16.29,16.29,0,0,1-5.06-2.59,16,16,0,0,1-3.25-3.16A2,2,0,0,1,53.12,32h.42a19.82,19.82,0,0,1,7.34,1.41c-.19-3.66-2.32-7.07-4.43-9.95a.63.63,0,0,1,.48-1c.8,0,1.72-.05,2.19-.05A19.38,19.38,0,0,1,78.53,41.78,19.23,19.23,0,0,1,73.33,55a20.59,20.59,0,0,1-2.13,1.94L71,58a12.29,12.29,0,0,1-1.47,4.28h2.17a7.64,7.64,0,0,1,7.65,7.64"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class CrustaceansIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
