<section [formGroup]="form">
  <div class="d-flex justify-content-between flex-row align-items-center mb-1 w-50 m-auto">
    <label [for]="yesOption().label">{{ yesOption().label | titlecase }}</label>
    <input
      formControlName="kind"
      [value]="yesOption().kind"
      type="radio"
      class="form-check-input"
      [id]="yesOption().label"
    />
  </div>

  <div class="d-flex justify-content-between flex-row align-items-center mb-1 w-50 m-auto">
    <label [for]="noOption().label">{{ noOption().label | titlecase }}</label>
    <input
      formControlName="kind"
      [value]="noOption().kind"
      type="radio"
      class="form-check-input"
      [id]="noOption().label"
      #noTick
    />
  </div>

  <div class="d-flex justify-content-between flex-row align-items-center mb-1 w-50 m-auto">
    <div class="w-100">
      <input
        formControlName="otherAnswer"
        class="form-control w-100 mt-2 m-auto"
        placeholder="{{ 'survey.question.yes_no.inp_placeholder' | translate }}"
        type="text"
      />
    </div>
  </div>
</section>
