import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "google-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="e451ea61-2d69-481f-9f80-c2052a1d08d5"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 483.72 483.72"
  >
    <path
      d="M426.54,84.51l-69,68.65a144.36,144.36,0,0,0-203.5.43L85.2,84.76a241.85,241.85,0,0,1,341.34-.25"
      transform="translate(-14.14 -14.14)"
      fill="#e94335"
    />
    <path
      d="M111.41,256a144.13,144.13,0,0,0,42.42,102.22L85,427.05l0,0A241.87,241.87,0,0,1,85,85l.23-.22L154,153.58A144.18,144.18,0,0,0,111.42,256Z"
      transform="translate(-14.14 -14.14)"
      fill="#fabb05"
    />
    <path
      d="M427,427.08a241.86,241.86,0,0,1-341.95,0l68.83-68.83a144.36,144.36,0,0,0,204.3,0L427,427.08Z"
      transform="translate(-14.14 -14.14)"
      fill="#34a753"
    />
    <path
      d="M497.86,256A243.25,243.25,0,0,1,493,304.64,240.09,240.09,0,0,1,427,427l-.07.06-68.83-68.83a145,145,0,0,0,34.05-53.61H256V207.38H493A243.25,243.25,0,0,1,497.86,256Z"
      transform="translate(-14.14 -14.14)"
      fill="#4284f3"
    />
  </svg>`,
})
export class GoogleIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
