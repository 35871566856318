import { createActionGroup, emptyProps, props } from "@ngrx/store";

export enum StepperStepAction {
  NEXT = "NEXT",
  PREV = "PREV",
}

export interface SetStepperStepActionProps {
  action: StepperStepAction;
}

export interface SubmitSurveyActionProps {
  closeSurveyPage: boolean;
}

export const SurveyPageActions = createActionGroup({
  source: "Survey/Page",
  events: {
    startSurvey: emptyProps(),
    submitSurvey: props<SubmitSurveyActionProps>(),
    closeSurvey: emptyProps(),
    setStepperStep: props<SetStepperStepActionProps>(),
  },
});
