import { ValidationUtil } from "./validation-util";

export class StringUtil {
  static toUpperCaseList(values: string[]) {
    return (values || []).map((v) => v.toUpperCase());
  }

  static toLowerCaseList(values: string[]) {
    return (values || []).map((v) => v.toLowerCase());
  }

  static isUpperCase(value: string): boolean {
    return value?.toString().toUpperCase() === value;
  }

  static isLowerCase(value: string): boolean {
    return value?.toString().toLowerCase() === value;
  }

  static snakeCase(str: string): string {
    return str
      .replace(/([A-Z])([A-Z])([a-z])/g, "$1_$2$3")
      .replace(/([a-z0-9])([A-Z])/g, "$1_$2")
      .toLowerCase();
  }

  static capitalize(str: string): string | undefined {
    if (ValidationUtil.isNullOrUndefined(str)) {
      return;
    }

    return str[0]?.toUpperCase() + str?.substring(1);
  }

  static camelCase(str: string, firstCapital = false): string {
    return str.replace(/^([A-Z])|[\s-_](\w)/g, (match, p1, p2, offset) => {
      if (firstCapital && offset === 0) return p1;
      if (p2) return p2.toUpperCase();
      return p1.toLowerCase();
    });
  }

  static hexToRGB(value: string, fallback?: string): string | undefined {
    if (!ValidationUtil.isHexColor(value)) return fallback;

    // Remove the '#' character if present
    const hexNumber = value.replace(/^#/, "");

    // Parse the hex values for red, green, and blue components
    const r = parseInt(hexNumber.substring(0, 2), 16);
    const g = parseInt(hexNumber.substring(2, 4), 16);
    const b = parseInt(hexNumber.substring(4, 6), 16);

    return `${r}, ${g}, ${b}`;
  }

  /**
   * Hyphenated to UpperCamelCase
   */
  static toClassName(str: string): string {
    return StringUtil.capitalize(StringUtil.toPropertyName(str)) as string;
  }

  /**
   * Hyphenated to lowerCamelCase
   */
  static toPropertyName(str: string): string {
    return str
      .replace(/([^a-zA-Z0-9])+(.)?/g, (_, __, chr) => (chr ? chr.toUpperCase() : ""))
      .replace(/[^a-zA-Z\d]/g, "")
      .replace(/^([A-Z])/, (m) => m.toLowerCase());
  }

  /**
   * Hyphenated to CONSTANT_CASE
   */
  static toConstantName(str: string): string {
    const normalizedS = str.toUpperCase() === str ? str.toLowerCase() : str;
    return StringUtil.toFileName(StringUtil.toPropertyName(normalizedS))
      .replace(/([^a-zA-Z0-9])/g, "_")
      .toUpperCase();
  }

  /**
   * Upper camelCase to lowercase, hyphenated
   */
  static toFileName(str: string): string {
    return str
      .replace(/([a-z\d])([A-Z])/g, "$1_$2")
      .toLowerCase()
      .replace(/(?!^[_])[ _]/g, "-");
  }
}
