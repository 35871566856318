import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, signal } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { Store } from "@ngrx/store";
import { SurveyQuestionType } from "../interfaces/survey";
import {
  selectIsCompleted,
  selectStepper,
  selectSurvey,
  StepperStepAction,
  SurveyPageActions,
} from "../state";
import { SurveyState, SurveyStepper } from "../state/survey.state";
import { SurveyMultiSelectInputComponent } from "../survey-inputs/multi-select/multi-select";
import { SurveyRatingInputComponent } from "../survey-inputs/rating-input/rating-input";
import { SurveyTextInputComponent } from "../survey-inputs/text-value/text-value-input";
import { SurveyYesNoInputComponent } from "../survey-inputs/yes-no-input/yes-no-input";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "menufy-web-survey-stepper",
  standalone: true,
  imports: [
    SurveyTextInputComponent,
    SurveyRatingInputComponent,
    SurveyMultiSelectInputComponent,
    SurveyYesNoInputComponent,
    MatIconModule,
    MatButtonModule,
    NgClass,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./survey-stepper.html",
})
export class SurveyStepperComponent {
  public readonly QuestionType = SurveyQuestionType;

  public readonly survey = this._store.selectSignal(selectSurvey);

  public readonly stepper = this._store.selectSignal<SurveyStepper>(selectStepper);

  public readonly isCompleted = this._store.selectSignal(selectIsCompleted);

  public readonly questions = computed(() => {
    const questions = this.survey()?.questions;

    if (this.isCompleted() || !questions?.length) {
      return [];
    }

    return questions;
  });

  public readonly _currentQuestion = computed(() => {
    const selectedIdx = this.stepper().selectedStepIdx;

    return this.questions().find((question, idx) => idx === selectedIdx);
  });

  public readonly isFirstStep = computed(() => this.stepper().selectedStepIdx === 0);

  public readonly isLastStep = computed(
    () => this.stepper().selectedStepIdx === this.stepper().steps
  );

  private readonly _validationStates = signal(
    this.questions().reduce((validation, question) => {
      validation[question.id] = false;

      return validation;
    }, {} as Record<string, boolean>)
  );

  public get canActivateNext() {
    const question = this._currentQuestion();
    const validations = this._validationStates();

    return question && validations[question.id];
  }

  constructor(private readonly _store: Store<SurveyState>) {}

  public goToNext() {
    this._store.dispatch(SurveyPageActions.setStepperStep({ action: StepperStepAction.NEXT }));

    if (this.isCompleted()) {
      this._store.dispatch(SurveyPageActions.submitSurvey({ closeSurveyPage: false }));
    }
  }

  public goToPrev() {
    this._store.dispatch(SurveyPageActions.setStepperStep({ action: StepperStepAction.PREV }));
  }

  public onInputStatus(isValid: boolean, questionId: string) {
    this._validationStates.update((v) => {
      v[questionId] = isValid;

      return v;
    });
  }
}
