import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "vivesoy-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="bcfe63b3-828b-40b4-a712-2c6154c1c4cf"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 50"
  >
    <path d="M125,50H25A25,25,0,0,1,25,0H125a25,25,0,0,1,0,50" fill="#fcc32c" />
    <path
      d="M43.36,17.65h3.79V32.33H43.36Zm5.37,0h4.42l3.35,9.58L60,17.65h4.41l-6,14.68H54.63Zm-23,0h4.41l3.36,9.58L37,17.65h4.42l-6,14.68H31.61Zm40.29,0h11.9v3.44h-8v2.27h7.15v3.21H69.91v2.32h8v3.44H66Zm15.18,9.77a6.63,6.63,0,0,0,4.68,1.94c1.3,0,2.09-.55,2.09-1.26s-.94-1.16-2.51-1.49c-2.42-.49-5.8-1.1-5.8-4.58,0-2.46,2.09-4.6,5.85-4.6a9,9,0,0,1,6,2.05l-2.07,2.7a6.82,6.82,0,0,0-4.13-1.51c-1.26,0-1.74.5-1.74,1.14s.9,1,2.53,1.34c2.42.51,5.76,1.21,5.76,4.53,0,2.95-2.18,4.91-6.11,4.91a9,9,0,0,1-6.52-2.33Zm20.29-10c4.49,0,7.84,3.12,7.84,7.59s-3.35,7.59-7.84,7.59S93.63,29.47,93.63,25s3.36-7.59,7.85-7.59m0,3.59a3.78,3.78,0,0,0-3.88,4,3.88,3.88,0,1,0,7.75,0,3.78,3.78,0,0,0-3.87-4m13.23,5.41-5.57-8.76h4.39l3.19,5.41,3.15-5.41h4.41l-5.54,8.76v5.92h-4Z"
      fill="#066732"
    />
  </svg>`,
})
export class VivesoyIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
