import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "flexitarian-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="b3f55a9f-c141-4a25-aede-4300bf2d024a"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.82 85.82"
  >
    <circle cx="42.91" cy="42.91" r="42.91" fill="#7d695e" />
    <path
      d="M61.78,26.56a8.64,8.64,0,0,1-.07,1.22,2.38,2.38,0,0,1-.26.84,1.28,1.28,0,0,1-.43.46.94.94,0,0,1-.5.15H42.93V45.56H59.56a1,1,0,0,1,.5.13,1.26,1.26,0,0,1,.43.43,2.16,2.16,0,0,1,.25.8,8.73,8.73,0,0,1,.08,1.27,8.14,8.14,0,0,1-.08,1.21,2.34,2.34,0,0,1-.25.82,1.56,1.56,0,0,1-.43.48.88.88,0,0,1-.5.17H42.93v20a.94.94,0,0,1-.15.5.92.92,0,0,1-.52.37,4.92,4.92,0,0,1-1,.22,11.91,11.91,0,0,1-3.08,0,5.08,5.08,0,0,1-1-.22,1,1,0,0,1-.52-.37.94.94,0,0,1-.15-.5V26.41a2.37,2.37,0,0,1,.76-2,2.67,2.67,0,0,1,1.61-.58h21.6A.94.94,0,0,1,61,24a1.27,1.27,0,0,1,.43.48,2.7,2.7,0,0,1,.26.88A9.13,9.13,0,0,1,61.78,26.56Z"
      transform="translate(-5.09 -5.09)"
      fill="#fff"
    />
  </svg>`,
})
export class FlexitarianIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
