import { createActionGroup, props } from "@ngrx/store";

import { MenuPreferencesFilters } from "../../../menus/interfaces/prefs-filters";

export type SetPreferencesActionProps = MenuPreferencesFilters;

export const PrefsActions = createActionGroup({
  source: "Preferences",
  events: {
    setPreferences: props<SetPreferencesActionProps>(),
  },
});
