import { Dish } from "@menufy/models";

export interface DishState {
  dish: Dish | null; // filtered dish (prefs applied)
  error: unknown;
}

export const dishInitialState: Readonly<DishState> = {
  dish: null,
  error: null,
};
