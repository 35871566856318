import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "heura-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="aff320e2-abde-46eb-b06a-fad04012cf1b"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 50"
  >
    <path d="M0,25A25,25,0,0,1,25,0H125a25,25,0,0,1,0,50H25A25,25,0,0,1,0,25" fill="#f9e021" />
    <path
      d="M65.8,23.65a8.17,8.17,0,0,0-8.43-8.13c-5.74,0-9,4.07-9,9.48s3,9.48,9.1,9.48c4.65,0,7.65-2.78,8.16-5.88h-4.3A3.79,3.79,0,0,1,57.49,31c-3.55,0-4.69-2.09-4.88-4.68h13a12.89,12.89,0,0,0,.19-2.63m-13.18-.51a4.37,4.37,0,0,1,4.64-4.46,4.28,4.28,0,0,1,4.41,4.46ZM100.79,16a4.12,4.12,0,0,0-1.67-.26,6.55,6.55,0,0,0-5.62,3.13V16H89.24V34h4.25V26.24c0-4.49,2-6.54,5.62-6.54a5.94,5.94,0,0,1,1.68.19V16Zm15,2.23a7.15,7.15,0,0,0-5.63-2.74A9.25,9.25,0,0,0,100.79,25a9.24,9.24,0,0,0,9.33,9.48,6.75,6.75,0,0,0,5.63-2.7V34H120V16h-4.25Zm-5.39,12.48c-3.2,0-5.31-2.63-5.31-5.73s2.11-5.73,5.31-5.73A5.41,5.41,0,0,1,115.75,25a5.42,5.42,0,0,1-5.39,5.73m-32.87-.08c-3.08,0-5.1-2.53-5.1-5.5H68.31a8.93,8.93,0,0,0,8.74,9.11h.45a8.94,8.94,0,0,0,9.25-9.1H82.67a5.2,5.2,0,0,1-5.17,5.49M42.57,23.14H34.25V15.52H30V34h4.25V26.83h8.32V34h4.25V15.52H42.57ZM75,18.74a2.86,2.86,0,1,1-2.86-2.85A2.86,2.86,0,0,1,75,18.74m10.89,0A2.86,2.86,0,0,1,83,21.6h0a2.86,2.86,0,1,1,2.86-2.86m38.87-1.57v0a.72.72,0,0,0-.72-.68h-.94v1.9h.57v-.49h.11l.33.49h.66l-.37-.58a.72.72,0,0,0,.36-.62m-.84.27h-.25v-.52h.27a.23.23,0,0,1,.21.25h0v0a.24.24,0,0,1-.23.25"
    />
    <path
      d="M123.82,19.35a1.94,1.94,0,1,1,1.93-1.93,1.94,1.94,0,0,1-1.93,1.93m0-3.36a1.43,1.43,0,1,0,1.43,1.43A1.44,1.44,0,0,0,123.82,16"
    />
  </svg>`,
})
export class HeuraIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
