import {
  Component,
  ElementRef,
  Input,
  inject,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tiktok-icon",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  template: `<svg
    id="aa0294b3-8adf-476b-823f-0a694214df5d"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512.01 512"
  >
    <defs>
      <radialGradient
        id="a2bb5428-ccc0-4b97-8c1f-f81b5a1e982f"
        cx="256.01"
        cy="258"
        r="256"
        gradientTransform="matrix(1, 0, 0, -1, 0, 514)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#3c0119" />
        <stop offset="1" stop-color="#160214" />
      </radialGradient>
    </defs>
    <path
      d="M512,256c0,141.38-114.62,256-256,256h-.48C114.35,511.74,0,397.19,0,256S114.35.25,255.51,0H256c141.4,0,256,114.58,256,256"
      fill="url(#a2bb5428-ccc0-4b97-8c1f-f81b5a1e982f)"
    />
    <path
      d="M216.07,266.09c-3.42-.92-24.39-6.21-44.89,6.7a57.87,57.87,0,0,0-25.11,35.83,51.6,51.6,0,0,0-1.27,22,50.37,50.37,0,0,0,12.38,24.28,58.12,58.12,0,0,0,8.8,8.23l.38.29.45.33a68.63,68.63,0,0,0,7.47,4.67l.12.06.23.11-.13-.17c-13.8-18.41-15.05-50.92,4.39-71.09,21.46-22.28,54.16-14.53,55.52-14.18l.78-66.94a116.93,116.93,0,0,0-18.31-.69v.09l-.8,50.46ZM323.24,73.67c1.23,3,2.57,6,4,9l.91,1.83v.14a.54.54,0,0,0,0,.13l.14.3.14.29a4.63,4.63,0,0,0,.29.54c.1.21.21.41.32.62.73,1.43,1.59,2.91,2.37,4.41.59,1.07,1.21,2.13,1.85,3.18l.68,1.13,1,1.7,1.27,2a156.5,156.5,0,0,0,25.95,30.92c.59.54,1.18,1.06,1.78,1.59-.58-.55-1.17-1.09-1.74-1.65A124.64,124.64,0,0,1,351.72,118,128.47,128.47,0,0,1,329.3,73.47l-6.09.11h-.14l0,.1h.14Zm88.91,86.89H412l-.12,0v.07l.08,0h.13q.11,6,.2,11.92.39,11.82.66,23.63h-.13l-.12,0,.46,18.68A141.88,141.88,0,0,1,322.09,186V326c-.32,5.36-5,64.51-58.44,97.06q-4,2.38-8,4.44c-50.26,25.65-99.38,5.55-104.24,3.47a65.23,65.23,0,0,1-13.78-6.36q-2-1.25-3.89-2.6l.15.16c26.76,31.82,78.62,43.31,121.77,30.23a110,110,0,0,0,20-8.23c22.83-12.2,45.24-35.15,55.52-63.79a124.89,124.89,0,0,0,4.55-17.1c5.35-24.06,3.83-35.83,4.12-96.68.1-9.85.1-19.13.1-35.45,0-12.27-.1-22.36-.1-29.12a161.61,161.61,0,0,0,53.54,25.19,158.85,158.85,0,0,0,38.19,5.12l.4-66.14a145.34,145.34,0,0,1-19.84-5.68"
      fill="#fd2854"
    />
    <path
      d="M98.28,338.12A134.42,134.42,0,0,1,104.64,294c5.41-17.26,21.56-46.37,53.94-64.17a117.83,117.83,0,0,1,58.22-14.32v-.1l.3-15.91a120.44,120.44,0,0,0-77.84,13.17C102.2,233.35,88.9,267.3,85.32,276.85a124,124,0,0,0-7.75,46.48,115.88,115.88,0,0,0,35.18,81.9,140.5,140.5,0,0,0,19.55,15.7c-32.48-25-33.83-75.36-34-82.82ZM411.91,160.59v-.07c-.08-3.84-.17-7.67-.25-11.51-12-1.13-30.77-4.82-47.57-17.46a156.7,156.7,0,0,0,45.6,28.16l2.22.88m.82,35.6.12,0H413q-.24-11.8-.66-23.63.21,11.75.4,23.49v.16Z"
      fill="#24f6fa"
    />
    <path
      d="M174.43,368.53l.1.08a49.94,49.94,0,0,0,53.59,20.91c.26,0,.52-.13.78-.19A60.14,60.14,0,0,0,241.6,385a53.1,53.1,0,0,0,14.11-9.77c16.1-15.48,18.46-36.9,18.57-38.19l-.7-262.37,6.89-.14h.11l42.05-.83h.46l0-.1c-.16-.5-.3-1-.44-1.43a51.22,51.22,0,0,1-2.08-15l-65,.38h-1.46q.41,131.12.78,262.24c.05,14.07-11.5,37.74-32.68,48-15.2,7.38-35.88,6.78-47.59.79l-.23-.11,0,0Zm-.15-.08a67.12,67.12,0,0,1-7.47-4.67,50.21,50.21,0,0,0,7.47,4.67"
      fill="#24f6fa"
    />
    <path
      d="M151.46,431c4.85,2.08,54,22.18,104.24-3.47q4-2.06,8-4.44c53.43-32.56,58.13-91.7,58.44-97.06V186a141.9,141.9,0,0,0,91.09,28.85l-.46-18.68V196q-.19-11.73-.41-23.49-.1-6-.2-11.92H412l-.08,0c-.74-.28-1.48-.58-2.22-.88a156.91,156.91,0,0,1-45.6-28.16l-.12-.11c-.59-.53-1.19-1.05-1.78-1.59a156.21,156.21,0,0,1-25.95-30.92l-1.27-2-1-1.7-.68-1.13c-.64-1-1.26-2.12-1.85-3.18-.78-1.5-1.64-3-2.37-4.41-.11-.21-.22-.41-.32-.62s-.2-.36-.29-.54l-.14-.29-.14-.3a.54.54,0,0,1,0-.13v-.14l-.91-1.83q-2.14-4.42-4-9h-.6l-42,.83h-.11l-6.89.14L274.3,337c-.11,1.29-2.47,22.71-18.57,38.19A53.26,53.26,0,0,1,241.62,385a58.66,58.66,0,0,1-12.7,4.36l-.78.19a49.93,49.93,0,0,1-53.59-20.91l-.1-.08,0,0-.12-.06a50.21,50.21,0,0,1-7.47-4.67l-.45-.33a3.47,3.47,0,0,1-.38-.29,58.12,58.12,0,0,1-8.8-8.23,50.37,50.37,0,0,1-12.38-24.28,51.6,51.6,0,0,1,1.27-22,57.87,57.87,0,0,1,25.11-35.83c20.49-12.9,41.47-7.62,44.89-6.7l.8-50.46v-.11h-.06a117.93,117.93,0,0,0-58.22,14.32c-32.38,17.81-48.53,46.91-53.94,64.17a134.42,134.42,0,0,0-6.36,44.11c.21,7.45,1.55,57.85,34,82.82l.31.24,1.13.84,0,0c1.24.9,2.55,1.76,3.89,2.6A64.74,64.74,0,0,0,151.49,431Z"
      fill="#fff"
    />
  </svg>`,
})
export class TiktokIconComponent implements AfterViewInit {
  @Input() width?: string;

  @Input() height?: string;

  @Input() class?: string;

  private readonly _hostElemRef: ElementRef = inject(ElementRef);

  ngAfterViewInit() {
    const svg = this._hostElemRef.nativeElement.querySelector("svg") as SVGSVGElement;

    if (this.width) {
      svg?.setAttribute("width", this.width);
    }

    if (this.height) {
      svg?.setAttribute("height", this.height);
    }

    if (this.class) {
      svg?.setAttribute("class", this.class);
    }
  }
}
